.item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    .add_wishlist {
        display: none;
    }

    .imgWrapper {
        position: relative;
        width: 100%;
        margin-bottom: 6px;

        .eclat_rond {
            position: absolute;
            z-index: 5;
            top: 0.5rem;
            left: 0.5rem;
        }

        .eclat_rond {
            transform: rotate(-90deg);
            top: 2.2rem;
            left: -1.5rem;
        }

        .eclat_rect {
            display: block !important;
        }

        span.ill_img {
            position: relative;
            display: block;
            width: 100%;
            padding-top: calc(596 / 447 * 100%);

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
            }

            +span.ill_img {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                z-index: -1;
                transition: all 0.2s ease-in-out;
            }
        }

        &:hover span.ill_img+span.ill_img {
            opacity: 1;
            z-index: 10;
        }

        .swiper-slide.video {
            height: 0;
            padding-top: calc(596 / 447 * 100%);

            iframe {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        .doubleCol .swiper-slide.video {
            padding-top: calc(520 / 410 * 50%);

            @media screen and(max-width: 1500px) {
                padding-top: calc(520 / 414 * 50%);
            }

            @media screen and(max-width: 1400px) {
                padding-top: calc(520 / 399 * 50%);
            }

            @media screen and(max-width: 1300px) {
                padding-top: calc(520 / 400 * 50%);
            }

            @media screen and(max-width: 1200px) {
                padding-top: calc(520 / 398 * 50%);
            }
        }

        .productSwiper {
            .preloader_video {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 0;
                padding-bottom: calc((596 / 447) * 100%);
                z-index: 10;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                    pointer-events: none;
                }
            }

            .imgProd {
                + .imgProd {
                    opacity: 0;
                    transition: opacity 0.4s;
                }
            }
        }
    }

    .block_push {
        display: block;
        position: relative;
        width: 100%;
        padding-bottom: calc(596 / 447 * 100%);

        img {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
        }

        .infos {
            position: absolute;
            z-index: 2;
            bottom: 0;
            left: 0;
        }

        .alt_bandeau {
            font-size: 24px;
            font-weight: 500;
            text-transform: uppercase;
            color: white;
            margin: 0 0 8px 32px;
        }

        .taunt_click {
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
            text-decoration: underline;
            text-underline-position: under;
            color: white;
            margin: 0 0 32px 32px;
        }
    }

    a.blockInfosProd {
        position: relative;
        width: 100%;
        color: $black;
        text-decoration: none;

        .wrapper_description {

            .item_title,
            .item_subtitle {
                font-size: 13px;
                font-weight: 500;
            }

            .item_title {
                width: 99%;
                white-space: nowrap;
                line-height: initial;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .item_subtitle {
                display: none;
            }
        }

        .item_price {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
            align-items: center;
            margin-top: 4px;
            font-size: 13px;
            font-weight: 500;

            .eclat_rect {
                margin-left: 11px;
                position: relative;
                top: 2px;
            }

            .pricetag+.pricetag {
                font-size: 12px;
                color: $crossedPrice;
                text-decoration: line-through;
                margin-right: 8px;
            }
        }
    }

    &:hover {

        .wrap_rolloverproduit .bloc_add_color,
        .productSizeFieldset {
            opacity: 1;
        }

        .productSwiper {
            .imgProd {
                + .imgProd {
                    opacity: 1;
                }
            }
        }
    }
}

#productAssociationSwiper,
#productRangesSwiper {
    .item {
        max-width: 447px;
        padding-bottom: 30px;
    }

    a.block_lnk {
        max-width: 100%;
        width: 100%;
        padding-top: calc(596 / 447 * 100%);
    }
}

.swiper-lazy-preloader {
    display: block;
    width: 100%;
    height: auto;
    left: 0;
    top: 0;
    margin-left: 0;
    margin-top: 0;
    z-index: 10;
    background-color: #fff;
    transform-origin: none !important;
    animation: none !important;
    border: 0;
    border-radius: 0;

    &:after {
        display: none;
    }

    .ill_img {
        position: relative;
        display: block;
        width: 100%;
        padding-top: calc(596 / 447 * 100%);

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
        }
    }

    &.searchLazy {
        padding-top: calc(596 / 447 * 100%);
        background-repeat: no-repeat;
        background-size: 100% auto;
    }
}

.doubleCol {
    .swiper-lazy-preloader.searchLazy {
        padding-top: calc(596 / 447 * 100%);

        @media screen and(max-width: 1500px) {
            padding-top: calc(520 / 414 * 100%);
        }

        @media screen and(max-width: 1400px) {
            padding-top: calc(520 / 399 * 100%);
        }

        @media screen and(max-width: 1300px) {
            padding-top: calc(520 / 400 * 100%);
        }

        @media screen and(max-width: 1200px) {
            padding-top: calc(520 / 398 * 100%);
        }
    }
}

.productSwiper {

    /**
     * Swiper arrow navs
     */
    .blackarrow {
        opacity: 0;
        position: absolute;
        z-index: 5;
        top: 0;
        height: 100%;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &.rollover_rayonsPrev {
            left: 0;
            transform: rotate(180deg);
        }

        &.rollover_rayonsNext {
            right: 0;
        }
    }

    &:hover {
        .blackarrow {
            opacity: 1;

            &.rollover_rayonsPrev {
                left: 1rem;
            }

            &.rollover_rayonsNext {
                right: 1rem;
            }

            &.swiper-button-disabled {
                cursor: default;
                opacity: 0.2;
            }
        }
    }

    img.swiper-lazy {
        width: 100%;
        height: auto;
        vertical-align: top;
    }

    .wrap_video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding-top: calc(596 / 447 * 100%);

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &.doubleCol {
        .swiper-slide {
            width: 50% !important;
        }
    }
}

/**
 * Special case for homepage TG / Associations FP
 */
.home-module.push-product-module,
#productAssociationSwiper,
#productRangesSwiper {
    .productSwiper .blackarrow {
        display: none;
    }
}

a.block_lnk {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    padding-top: calc(596 / 447 * 100%);

    img.imgProd {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        vertical-align: top;
        font-size: 0; // Avoid alt attribute to be displayed
    }
}

/**
 * Prod infos
 */
.wrap_rolloverproduit {
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 0;
    width: 100%;

    .rollover_left {
        width: 100%;
    }

    form {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .titre_choices_list {
        display: none;
    }

    .btnAddBasketWrapper {
        position: relative;
        width: 100%;
        min-height: 73px;
        z-index: 3;
        margin-top: 40px;

        &.expressAlertStock {
            background-color: #fff;
        }
    }

    .bloc_add_color {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        width: 165px;
        height: 32px;
        opacity: 0;
        transition: all 0.3s ease-in-out;

        @media screen and(max-width: 1400px) {
            width: 140px;
        }

        @media screen and(max-width: 1300px) {
            width: 25px;
            height: 25px;
            background: transparent;
        }

        .button {
            letter-spacing: 0.01rem;

            @media screen and(max-width: 1400px) {
                font-size: 11px;
            }

            @media screen and(max-width: 1300px) {
                display: inherit;
                font-size: 0;
                border: 0;
                background: transparent;

                &:hover,
                &:focus {
                    background-color: transparent;
                }

                &:after {
                    content: "";
                    display: block;
                    width: 25px;
                    height: 25px;
                    @include bgIconPath("../svg/spritesheet.svg");
                    @include bgIconCoord(4, 6, 6);
                }

                &.loader {
                    background: transparent url(../svg/three_dots_black.svg) no-repeat center;
                    background-size: auto 18%;

                    &:after {
                        display: none;
                    }
                }
            }
        }

        @media screen and(max-width: 1300px) {
            &.loading {
                .w-submit-button {
                    opacity: 0;
                }

                .loader {
                    z-index: 1;
                    opacity: 1;
                }
            }
        }
    }

    .rollover_left {
        max-height: 47px;

        .productSizeFieldset {
            min-height: 47px;
        }

        .choices_list {
            a {
                color: $black;
                text-decoration: none;
            }
        }
    }

    .form_submit.bloc_add_color,
    .bloc_add_color {
        background: transparent;
        width: 30px;

        .button.w-submit-button {
            background: transparent;
            border: none;
            position: relative;

            span {
                display: none;
            }

            &:after {
                content: "";
                position: absolute;
                top: 6px;
                right: 0;
                width: 14px;
                height: 18px;
                opacity: 1;
                background-image: url(../svg/bag.svg);
                background-repeat: no-repeat;
                background-size: 100% auto;
                transition: all 0.2s ease-out;
            }

            &:hover {
                &:after {
                    opacity: .4;
                }
            }
        }
    }

    .choices_list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin-top: -6px;

        @media screen and (max-width: 912px) {
            flex-wrap: nowrap;
        }
    }

    .productColorFieldset,
    .productSizeFieldset {
        input[type="radio"] {
            display: none;
        }
    }

    .productColorFieldset {
        position: absolute;
        bottom: -67px;

        .prod_listes {
            .choices_list {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
            }
        }

        input[type="radio"] {
            display: none;

            &+label {
                position: relative;
                display: block;
                width: 13px;
                height: 13px;
                border-radius: 50%;
                background-size: contain;
                margin-right: 8px;
                box-sizing: border-box;
                cursor: pointer;

                &:before {
                    display: none;
                }
            }

            &:checked+label,
            &+label:hover {
                border: 0.5px solid $black;
            }
        }

        div[data-color="blanc"] {
            label {
                border: 0.5px solid $greyBorder;
            }
        }

        .remaining_colors {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 13px;
            font-size: 9px;
            font-weight: 400;
            padding: 0 0.5rem;
            border-radius: 16px;
            border: 1px solid #000;

            @media screen and (max-width: 1024px) {
                padding: 0 0.18rem;
            }
        }
    }

    .productSizeFieldset {
        width: 100%;
        min-height: 47px;
        opacity: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: rgba(255, 255, 255, 0.8);
        transition: all 0.3s ease-in-out;

        .choices_list {
            justify-content: center;

            input[type="radio"] {
                display: none;

                &+label {
                    border-radius: 0;
                    width: auto;
                    background-color: transparent;
                    margin: 0 0 0 16px;
                    cursor: pointer;

                    span {
                        position: relative;
                        font-size: 13px;

                        &:after {
                            content: "";
                            display: block;
                            width: 0;
                            height: 1px;
                            background-color: $black;
                            position: absolute;
                            top: 110%;
                            left: 0;
                            transition: width 0.2s ease-in-out;
                        }
                    }

                    &:before {
                        display: none;
                    }
                }

                &+label:hover span:after,
                &:checked+label span:after {
                    width: 100%;
                }

                &.disabled {
                    &+label span {
                        color: #a9a9a9;

                        &:after {
                            width: 100%;
                            top: 50%;
                            background-color: #a9a9a9;
                        }
                    }
                }
            }
        }


    }

    .alert_stock {
        font-size: 13px;
        margin: 1rem;
    }

    .bloc_add_alert_form {
        width: calc(100% - 2rem);
        margin: 0 auto;
        padding-bottom: 1rem;

        .w-input-container {
            width: 100%;

            .w-input-element:not([type="radio"]):not([type="checkbox"]),
            input[type="text"].w-input-element {
                height: 46px;
                border: 1px solid #989898;

                &.inputErr {
                    border-color: red;
                }
            }
        }

        .form_submit {
            position: absolute;
            top: 1px;
            right: 1px;
            width: 44px;
            height: 44px;

            button {
                border: 0;
                background-color: #fff;
                color: #000;
                font-size: 13px;
                font-weight: 500;
                color: #000;
                cursor: pointer;
            }
        }
    }

    .bloc_add_alert_confirmation {
        font-size: 13px;
        padding: 1.5em;
        line-height: 1.2;
    }

    .alert_return {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        @extend .closeBtn;

        span {
            font-size: 0;
        }
    }
}

/**
 * TDG Sizes aligned with product title
 */
.pushProductHome {
    width: 100%;

    .wrap_rolloverproduit .productSizeFieldset .choices_list {
        margin-left: 0.6rem;
    }
}
.swiper-lazy-preloader .ill_img {
    padding-top: 0;
}