/**
 * General styles
 */
#copyright {
    background-color: #fff;

    section {
        &:nth-child(2) {
            display: flex;
            background: linear-gradient(90deg, #000 0%, #000 50%, #fff 50%, #fff 100%);
            border-top: 1px solid #000;

            @media screen and(max-width: 1280px) {
                background: #fff;
                border-top: 0;
            }
        }
    }

    .footerWrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 98%;
        max-width: 1800px;
        height: 316px;
        margin: 0 auto;

        @media screen and(max-width: 1280px) {
            flex-direction: column;
            justify-content: flex-start;
            width: 100%;
            height: auto;
        }

        .wrapper {
            position: relative;
            display: flex;
            height: 100%;
            padding-top: 38px;
            box-sizing: border-box;
        }
    }

    .leftPart {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 39.5%;
        max-width: 663px;
        background-color: #000;
        color: #fff;

        @media screen and(max-width: 1280px) {
            order: 2;
            width: 100%;
            max-width: 100%;
            padding: 38px 0 68px;
        }

        aside {
            position: absolute;
            left: 0;
            bottom: 18px;
            font-size: 10px;

            a {
                color: #fff;
                text-decoration: none;
            }
        }

        #mention_legales_popup {
            position: absolute;
            right: 1rem;
            bottom: 18px;
            font-size: 10px;

            a {
                color: #fff;
                text-decoration: underline;
                text-underline-position: under;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .rightPart {
        justify-content: flex-end;
        width: calc(100% - 39.5%);
        background-color: #fff;

        @media screen and(max-width: 1280px) {
            order: 1;
            justify-content: center;
            width: calc(100% - 64px);
            margin: 0 auto;
            border-top: 1px solid #000;
            padding: 32px 0;
        }
    }
}

/**
 * Reinsurance
 */
.reinsuranceWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 98%;
    max-width: 1437px;
    margin: 0 auto;
    padding: 67px 0 33px;

    @media screen and(max-width: 1280px) {
        width: calc(100% - 64px);
    }

    a {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        color: #000;
        text-decoration: none;

        .bgSprite {
            display: flex;
            align-items: center;
            height: 60px;

            &:before {
                width: 38px;
                height: 38px;
            }

            &.delivery:before {
                width: 31px;
                height: 31px;
            }
        }

        div:not(.bgSprite) {
            text-align: center;
        }

        p {
            font-size: 13px;
            line-height: 1.2;

            &.rea_titles {
                font-size: 16px;
                font-weight: 500;
                line-height: 1;
                text-transform: uppercase;
                margin-bottom: 8px;

                &.payment + p::after {
                    content: "";
                    display: inline-block;
                    width: 30px;
                    height: 15px;
                    background-image: url('../img/alma.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-color: $white;
                    margin-bottom: -0.2rem;
                    margin-left: 0.25rem;
                }
            }
        }
    }
}

body.wishlist {
    .reinsuranceWrapper {
        padding: 27px 0 33px;
    }
}

/**
 * Newletter subscription
 */
.bot_subscription_title {
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.bot_subscription_subtitle {
    font-size: 13px;
    line-height: 1.2;
    margin-bottom: 18px;

    span {
        display: block;
    }

    @media screen and(max-width: 1280px) {
        span {
            display: initial;
        }
    }
}

.bot_subscription_mention {
    font-size: 10px;
    font-weight: 300;
    margin-top: 8px;
}

.footerWrapper {
    .w-newsletter-form {
        width: 90%;
        max-width: 543px;

        .w-form-line {
            margin-bottom: 0;

            &.w-submit {
                width: 48px;
                height: 46px;
                top: 1px;
                right: 1px;

                button.w-submit-button {
                    width: 100%;
                    height: 100%;
                    border: 1px solid transparent;
                    border-left: 0;
                    font-size: 16px;
                    font-weight: 500;
                    text-transform: uppercase;

                    &:hover,
                    &:focus {
                        background: #000;
                        color: #fff;
                        border: 1px solid transparent;
                        border-left: 0;
                    }

                    &.w-loader {
                        background: #000 url(../svg/three_dots.svg) no-repeat center;
                        background-size: auto 14%;
                    }
                }
            }
        }

        .w-input-element:not([type=radio]):not([type=checkbox]),
        input[type=email].w-input-element {
            border: 1px solid #fff;
            border-radius: 0;
            padding-right: 68px;
            color: #fff;
        }

        .w-has-error:not(.w-dropdown) .w-input-element:not([type=radio]):not([type=checkbox]),
        .w-has-error:not(.w-dropdown) input[type=email].w-input-element {
            border: 1px solid red;
            box-shadow: none;
            color: red;
        }

        .w-btn-wrapper {
            width: 100%;
            height: 100%;
        }

        .w-input-note {
            text-align: right;

            @media screen and(max-width: 1400px) {
                margin: 22px 0 0;
            }

            @media screen and(max-width: 1280px) {
                margin: .33333em 0 0;
            }

            &.w-input-valid {
                color: green;
            }
            &.w-input-error {
                color: red;
            }
        }
    }
}

@media screen and(max-width: 1280px) {
    .bot_subscription_title,
    .bot_subscription_subtitle,
    .footerWrapper .w-newsletter-form,
    .bot_subscription_mention,
    .socialWrapper,
    #copyright .leftPart aside {
        width: 600px;
        max-width: 600px;
        margin: 0 auto;
    }

    .bot_subscription_title {
        margin: 0 auto 16px;
    }

    .bot_subscription_subtitle {
        margin: 0 auto 32px;
    }

    .bot_subscription_mention {
        margin: 8px auto 0;
    }

    #copyright .leftPart aside {
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }
}

/**
 * Socials
 */
.socialWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 22px;

    a:first-child {
        margin: 0 2px 0 -3px;
    }

    .bgSprite:before {
        width: 30px;
        height: 30px;
    }
}

/**
 * Footer SEO links
 */
.seo_blocks {
    .ctn,
    .footer_navs {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .ctn {
        margin-right: 3%;
        width: 100%;

        @media screen and(min-width: 1281px) {
            max-width: 160px;
        }

        @media screen and(min-width: 1500px) {
            margin-right: 70px;
        }

        @media screen and(min-width: 1600px) {
            max-width: 170px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .footer_titles {
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    a {
        position: relative;
        font-size: 13px;
        color: #000;
        text-decoration: none;
        margin-bottom: 11px;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 110%;
            left: 0;
            width: 0;
            height: 1px;
            background-color: #000;
            transition: width .2s ease-in-out;
        }

        &:hover:after,
        &:focus:after {
            width: 100%;
        }
    }
}