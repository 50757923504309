/******************************************************************************/
/* SITE COLORS */
/******************************************************************************/

$white:             #FFFFFF;
$black:             #000000;
$red:               #FF0000;

$primaryColor:      $black;
$secondaryColor:    $white;
$tertiary:          #989898;
$merino:            #E3DBD0;
$green:             #007C58;
$greenOpen:         #04AC6C;
$fluo:              #41EE48;
$crossedPrice:      #989898;
$grey:              #E5E5E5;
$grey01:            #A8A8A8;
$greyBorder:        #B1B1B1;
$inputGrey:         #868686;
$darkGrey:          #030303;
$orangeAlert:       #FF5630;
$errormsgadd:       #888c8d;
$greenMedium:       #04AC6C;