/**
GENERAL LIGHTBOX STYLES
 */
.lightbox {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    opacity: 0;
    width: 640px;
    height: 460px;
    background-color: white;
    transition: all 0.3s ease-in-out;

    .closeBtn {
        position: absolute;
        z-index: 110;
        top: 20px;
        right: 20px;

        span {
            display: none;
        }
    }

    &.actif {
        display: block;
        z-index: 200;
        opacity: 1;
        transition: all 0.3s ease-in-out;
    }

    .light_title {
        position: relative;
        font-size: 40px;
        font-weight: 500;
        text-align: center;
    }
}

/**
LIGHTBOX SHADES
 */
.shad {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.4);
    opacity: 0;
    transition: all 0.3s ease-in-out;

    &.actif {
        z-index: 65;
        opacity: 1;
        transition: all 0.3s ease-in-out;
    }

    &#shad_header.actif {
        z-index: 50;
    }

    &.forZoom {
        background: rgba(white, 0.9);
    }
}

/**
 * Alert box
 */
#abox {
    height: auto;
    min-height: 200px;

    .txt_alert {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
    }

    .form_submit {
        width: 145px;
        height: 46px;
        margin: 0 auto;
    }
}

/**
 * Know more about optin
 */
#popup_savoir_plus {
    height: 320px;
    padding: 42px;
    font-size: 14px;

    .light_title {
        margin-bottom: 40px;
    }

    #content_savoir_plus,
    #bottom_savoir_plus {
        margin-top: 40px;
    }

    ul {
        width: 95%;
        margin: 1rem 0 0 3%;
        list-style-type: disc;
        list-style-position: outside;

        li {
            line-height: 1.2;
            margin-bottom: 0.8rem;
        }
    }
}

/**
 * Send wishlist
 */
#sendwishlistbox {
    position: absolute;
    top: 149px;
    transform: translateX(-50%);
    width: 650px;
    height: auto;
    padding: 78px 80px;
    box-sizing: border-box;

    .popup_title {
        .title {
            font-size: 40px;
            font-weight: 500;
            margin-bottom: 26px;
        }

        .subtitle {
            font-size: 13px;
            margin-bottom: 27px;
        }
    }

    input[type="email"],
    input[type="text"],
    textarea {
        width: 100%;
        border: 1px solid #989898;
        box-sizing: border-box;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;

        &::placeholder {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 14px;
            color: #989898;
        }

        &.inputErr {
            border-color: red;
        }
    }

    input[type="email"],
    input[type="text"] {
        height: 48px;
        padding: 0 1.1em;
    }

    textarea {
        height: 120px;
        padding: 1em 1.1em;
    }

    ::placeholder {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        color: #989898;
    }

    .form_title {
        font-size: 13px;
        margin-bottom: 14px;
    }

    small {
        display: block;
        font-size: 10px;
        margin: 8px 0 34px;
    }

    #wishlist_form_from {
        .form_title {
            margin-top: 32px;
        }
    }

    input#sendWishListYourName {
        margin-bottom: 24px;
    }

    input[type="checkbox"] {
        display: none;

        & + label {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 14px;
            margin: 27px 0;

            &:before {
                content: "";
                display: block;
                width: 15px;
                height: 15px;
                border: 1px solid #989898;
                box-sizing: border-box;
                font-size: 0;
                margin-right: 8px;
                background-color: #000;
                box-shadow: inset 0 0 0 15px #fff;
                transition: box-shadow 0.2s ease-in-out;
            }
        }

        &:checked + label:before {
            box-shadow: inset 0 0 0 2px #fff;
        }
    }

    .g-recaptcha {
        display: flex;
        justify-content: center;
    }

    .form_submit {
        width: 118px;
        margin: 24px auto 0;
    }

    #sendfriend_alert_mail {
        font-size: 14px;

        div {
            margin-top: 80px;
        }
    }
}

/**
 * Connexion wishlist
 */
#abox.wishConnection {
    width: 500px;
    height: 289px;

    .txt_alert {
        display: block;
        text-transform: initial;
    }

    .light_title {
        margin: 38px 0 17px;
    }

    p {
        font-size: 13px;
        line-height: 1.2;
        text-align: center;
        font-weight: 400;

        strong {
            display: block;
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
            line-height: 1;
            margin-bottom: 19px;
        }
    }

    .btn_container {
        width: 145px;
        margin: 43px auto 0;

        .button {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

/**
 * Zoom FP
 */
#zoomBox {
    position: absolute;
    width: 98%;
    max-width: 1500px;
    height: auto;
    transform: translate(-50%, 0);
    top: 120px;
    left: 50%;

    .closeBtn {
        width: 26px;
        height: 26px;
        top: 46px;
        right: 46px;

        &:before,
        &:after {
            height: 25px;
            width: 2px;
            left: 11px;
        }
    }

    #zoom_box_wrapper {
        img.img_large {
            width: 100%;
            height: auto;
            vertical-align: top;
        }
    }

    #zoom_box_details {
        position: absolute;
        z-index: 5;
        top: 119px;
        left: 39px;
        max-width: 122px;

        .swiper-slide {
            width: 120px !important;
            height: 160px !important;
            margin-bottom: 22px;
            border: 1px solid transparent;
            box-sizing: content-box;

            .block_vid {
                width: 100%;
                height: 100%;
            }

            iframe {
                width: 100%;
                height: 100%;
            }

            &.swiper-slide-thumb-active {
                border: 1px solid #000;
            }
        }
    }
}

/**
 * Sizeguide
 */
#sizeguide_light {
    width: 1000px;
    height: 700px;

    @media screen and(max-width: 1400px) {
        height: 620px;
    }

    .light_title {
        margin: 39px auto 23px;
    }

    .lightb_subtitle {
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        line-height: 1.2;
    }

    .top-content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 840px;
        margin: 0 auto;
    }

    .block-texte {
        line-height: 1;

        p.sizeguide_title {
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: 11px;
        }

        span {
            display: block;
            font-size: 13px;
            margin-bottom: 19px;
        }
    }

    .function {
        font-size: 13px;
    }

    #sizeguideScroll {
        height: 457px;
        margin-top: 44px;
        padding-bottom: 40px;

        @media screen and(max-width: 1400px) {
            height: 375px;
        }
    }

    section.taille {
        width: 840px;
        margin: 0 auto;

        .title {
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
            margin: 23px 0 12px;
        }
    }

    table {
        width: 100%;
        font-size: 13px;
        border-collapse: collapse;
        border: 1px solid #000;

        th,
        td {
            vertical-align: middle;
            height: 2.3em;
        }

        th {
            font-weight: 500;
            text-transform: uppercase;
            background-color: #000;
            color: #fff;
        }

        td {
            border: 1px solid #000;
        }

        tr {
            td {
                text-align: center;
                width: 184px;

                &:first-child {
                    width: auto;
                    text-align: left;
                    text-indent: 16px;
                }
            }
        }
    }
}

/**
 * Lightbox inscription
 */
#exit_overlay_OVI {
    width: 98%;
    max-width: 1029px;
    height: auto;

    @media screen and(max-height: 735px) {
        width: 54%;
    }

    @media screen and(max-width: 1400px) {
        width: 60%;
    }

    .pop_contents {
        &.lightbox-image {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding: 24px;

            @media screen and(max-width: 1400px) {
                align-items: center;
            }
        }
    }

    .wrapper_img {
        width: 51%;
        max-width: 492px;

        @media screen and(max-height: 735px) {
            width: 46%;
        }

        .ill_img {
            position: relative;
            display: block;
            width: 100%;
            padding-top: calc(684 / 492 * 100%);

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
            }
        }
    }

    .wrapper_newspopup_home {
        width: 49%;
        padding: 0 5.6% 0 7.3%;
        box-sizing: border-box;
        margin: auto 0;
    }

    .title_popup_inscription_home {
        font-size: 50px;
        font-weight: 500;
        line-height: 60px;
        text-transform: uppercase;

        @media screen and(max-width: 1400px) {
            font-size: 40px;
            margin-top: 0;
        }
    }

    .pop_txt_listing {
        font-size: 13px;
        line-height: 1.3;
        margin: 10px 0 24px;

        &.pop_cgv_listing {
            margin-top: 16px;
            margin-bottom: 0 !important;

            a {
                font-family: $secondaryFont;
                font-size: 16px;
                font-style: italic;
                text-decoration: underline;
                color: #000;
                line-height: 1.2;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .w-form-line {
        margin-bottom: 0;

        &.w-submit {
            max-width: 100%;
            height: 46px;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 40px white inset !important;
    }

    .w-input-element:not([type="radio"]):not([type="checkbox"]),
    input[type="email"].w-input-element {
        border: 0;
        border-bottom: 1px solid #000;
        padding: 0;
    }

    .w-has-error {
        .w-input-element:not([type="radio"]):not([type="checkbox"]),
        input[type="email"].w-input-element {
            border-color: red;
        }
    }

    .w-input-label {
        font-family: $primaryFont;
        font-size: 13px;
        font-weight: 400;
        color: #000;
        left: 0;
    }

    .w-nonempty .w-input-label {
        transform: translateY(-184%);
    }

    #conf_inscription_news {
        .title_popup_inscription_home {
            margin-top: 250px;

            @media screen and(max-width: 1400px) {
                margin-top: 0;
            }
        }

        button {
            text-transform: uppercase;
        }
    }
}

.lightbox#exit_overlay_OVI #formu_inscription_news form.w-adwords-form .w-form-line.w-submit {
    margin-top: 26px;
}

/**
 * Lightbox produit offert
 */
#selection-offer {
    width: 789px;
    // height: 696px;
    height: auto;
    padding-bottom: 50px;

    .close_pop {
        position: absolute;
        z-index: 5;
        top: 14px;
        right: 32px;
        background-color: transparent;
        cursor: pointer;
        //opacity: 0;
        transition: opacity 0.3s ease-in-out;
        border: none;
        font-size: 20px;

        span:before {
            content: "";
            width: 12px;
            height: 12px;
            position: absolute;
            background-image: url(../svg/spritesheet.svg);
            background-repeat: no-repeat;
            background-size: 600% auto;
            background-position: -48px 0;

            &:hover {
                opacity: 0.75;
            }
        }
    }

    .selection-offer-header {
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;

        p.selection-offer-title {
            font-family: "Graphik";
            font-style: normal;
            font-weight: 500;
            font-size: 40px;
            line-height: 48px;
            color: #000000;
            padding-top: 18px;
        }

        p:last-child {
            display: none;
        }
    }

    #wrap_lion {
        display: block;

        .js-form-selection-offer {
            display: flex;
            width: 93.3%;
            min-height: 208px;
            margin: 15px 34px 40px;
            opacity: 1;
            padding: 0;

            .form_content_wrapper {
                width: 100%;
                display: flex;
                padding: 1.5rem;

                .selection-offer-elem {
                    width: 100%;
                    padding: 7px 10px;

                    .form_itms {
                        display: flex;
                        flex-wrap: wrap;
                        align-content: center;

                        .form_itm.color {
                            padding: 7px;
                        }
                        .form_itm.size {
                            padding-right: 17px;
                        }
                    }

                    .titleOfferedProd {
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 16px;
                        // padding-left: 6px;
                        padding-left: 14px;

                        span {
                            display: none;
                        }
                    }

                    .color_list {
                        padding: 19.6px 8px 10px !important;
                        display: flex;

                        input[type="radio"] + label {
                            width: 18px;
                            height: 18px;
                        }

                        input[type="radio"]:checked + label {
                            position: relative;
                            top: -1px;
                            width: 19px;
                            height: 19px;
                            box-shadow: inset 0 0 0 3px #fff;
                        }
                    }

                    .size_list {
                        padding: 7px 14px;

                        input[type="radio"]:checked + label:after {
                            width: 70%;
                            left: 15%;
                        }
                    }

                    .swiper-button-disabled {
                        display: none;
                    }
                }
            }

            &:before {
                left: -8px;
                top: 50%;
                transform: translateY(-50%);
                z-index: 2;
                width: 15px;
                height: 15px;
            }
        }

        img.visuOfferedProd {
            width: 120px;
            height: auto;
        }

    }
}

#selection-offer .selection-offer-submit.disabled .button,
#selection-offer .selection-offer-submit .button {
    opacity: 1;
    width: 340px;
}
#selection-offer .selection-offer-content {
    padding-bottom: 0px;
}
#selection-offer #errorLabel {
    max-width: 400px;
    bottom: 10px;
    left: 25%;
    position: absolute;
}

/* CODE PROMO TUNNEL */
#modboxpromo {
    max-width: 500px;
    height: 100px;
    padding: 2rem;
    text-align: center;
    flex-direction: column;

    &.actif {
        display: flex;
    }

    .texte {
        font-size: 16px;
        line-height: 1;
        margin-bottom: 10px;
        text-align: center;
    }

    #btns_panier {
        width: 100%;
        max-width: 190px;
        margin: 0 auto;

        .button {
            line-height: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            width: 100%;
            max-width: 190px;
            transition: all ease-in-out 0.35s;
            font-weight: 400;

            &:hover {
                background: #fff;
                color: #000;
            }
        }
    }
}

/**
 * Express buy (Lookbook)
 */
#lightbox_achat_express {
    position: fixed;
    top: 129px;
    left: auto;
    right: 0;
    transform: none;
    width: 1311px;
    height: auto;

    @media screen and(max-width: 1400px) {
        top: 50%;
        transform: translateY(-50%);
        width: 900px;
    }

    .prod_top_wrapper {
        align-items: center;
        margin: 0 auto;
    }

    #expressLider {
        width: 46%;
        max-width: 603px;
        margin: 0;

        img.swiper-lazy {
            width: 100%;
            height: auto;
            vertical-align: top;
        }

        .block_vid {
            position: relative;
            width: 100%;
            padding-top: calc(804 / 603 * 100%);

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    #fpRightPart {
        position: relative;
        width: 54%;
        top: auto;
        padding: 0 10%;

        #itm_name {
            margin: 0 0 14px;
        }

        @media screen and(max-width: 1400px) {
            padding: 0 2%;
        }
    }

    .expressLiderPrev,
    .expressLiderNext {
        position: absolute;
        z-index: 5;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;

        &.swiper-button-disabled {
            opacity: 0.2;
            cursor: default;
        }
    }

    .expressLiderPrev {
        left: 1rem;

        &:before {
            transform: rotate(180deg);
        }
    }

    .expressLiderNext {
        right: 1rem;
    }

    .go_to_fp {
        position: relative;
        display: inline-block;
        font-family: $secondaryFont;
        font-size: 24px;
        font-style: italic;
        color: #000;
        text-decoration: none;
        margin-top: 24px;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 88%;
            left: 0;
            right: 0;
            height: 1px;
            background-color: #000;
            transition: left 0.3s ease-in-out;
        }

        &:hover:after {
            left: 100%;
        }
    }
}

/**
 * Current offers infos (footer)
 */
#content_mention_legales_pop_up {
    color: #000;

    .pop_mention_title {
        font-size: 40px;
        font-weight: 500;
        text-align: center;
        margin: 40px 0;
    }

    .pop_mention_detail {
        font-size: 13px;
        line-height: 1.2;
        text-align: center;

        strong {
            display: block;
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
            line-height: 1;
            margin-bottom: 19px;
        }
    }
}

body.en {
    #exit_overlay_OVI {
        #conf_inscription_news {
            .title_popup_inscription_home {
                font-size: clamp(28px, 1.839vw, 35px);
            }
        }
    }
}

#availabilityInStore {
    width: 400px;
    height: 100vh;
    top: 0;
    right: 0;
    left: auto;
    transform: none;
    padding: 50px 0px 42px 25px;

    @media screen and (min-width: 1921px) {
        right: calc((100vw - 1920px) / 2);
    }

    .store_locator {
        & > p {
            font-family: $primaryFont;
            font-size: 13px;
            font-weight: 400;
            line-height: 15.6px;
            text-align: center;
            margin-right: 25px;
        }

        .availabilityInStoreTitle {
            font-family: $primaryFont;
            font-size: 28px;
            font-weight: 500;
            line-height: 33.6px;
            text-align: center;
            margin-bottom: 10px;
            max-width: 360px;

            &:before {
                display: none;
            }
        }

        .availabilityInStoreDescription {
            font-family: $primaryFont;
            font-size: 13px;
            font-weight: 400;
            line-height: 15.6px;
            text-align: center;
            margin-bottom: 15px;
            max-width: 360px;
        }

        #storeListForm {
            margin-bottom: 16px;

            .formWrapper {
                width: 100%;
                max-width: 360px;

                .field_address {
                    position: relative;

                    .loc_input {
                        width: 100%;
                        max-width: 360px;
                        height: 48px;
                        border: 1px solid $tertiary;
                        padding-left: 10px;

                        &::placeholder {
                            font-family: $arial;
                            font-size: 14px;
                            font-weight: 400;
                            font-style: normal;
                            line-height: 21px;
                            text-align: left;
                            color: $tertiary;
                        }
                    }

                    button {
                        position: absolute;                        
                        background: url('../img/localisation.png');
                        width: 20px;
                        height: 20px;
                        right: 5px;
                        top: 15px;
                        border: none;
                        padding: 0;

                        span {
                            display: none;
                        }
                    }
                }
            }
        }

        #storeList {
            #storeListScrollWrapper {
                height: auto;
                max-height: 68vh;
            }

            .os-host-overflow {
                overflow: hidden !important;
            }
            

            .liste_distributeur_scrollbar {
                height: 100vh;

                .store {
                    width: 100%;
                    max-width: 360px;
                    border-bottom: 1px solid $tertiary;

                    &:first-of-type {
                        margin-top: 15px;
                    }

                    &:not(:first-of-type) {
                        margin-top: 15px;
                    }

                    .elem_list_contents {
                        margin-bottom: 1.5rem;
                    
                        .store_name {
                            .store_name_wrapper {
                                position: relative;
                                display: flex;
                                justify-content: space-between;
                                margin-bottom: 5px;

                                a {
                                    text-decoration: none;

                                    .title {
                                        font-family: $primaryFont;
                                        font-size: 16px;
                                        font-weight: 500;
                                        line-height: 19.2px;
                                        color: $black;
                                        margin-bottom: 4px;

                                        .distance {
                                            display: none;
                                        }
                                    }
                                }

                                .storeStock {
                                    position: absolute;
                                    top: 30px;
                                    right: 0px;
                                    font-family: $primaryFont;
                                    font-size: 13px;
                                    font-weight: 400;
                                    line-height: 15.6px;
                                    text-align: left;

                                    &:before {
                                        content: '';
                                        line-height: 0;
                                        display: inline-block;
                                        height: 8px;
                                        width: 8px;
                                        border-radius: 50%;
                                        margin-right: 10px;
                                        align-self: center;
                                        margin-bottom: 1px;
                                    }

                                    &.low {
                                        &:before {
                                            background-color: $red;
                                        }
                                    }

                                    &.medium {
                                        &:before {
                                            background-color: $orangeAlert;
                                            ;
                                        }
                                    }

                                    &.high {
                                        &:before {
                                            background-color: $greenMedium;
                                        }
                                    }
                                }
                            }
                        }

                        .store_details {
                            a {
                                text-decoration: none;

                                .store_address {
                                    font-family: $primaryFont;
                                    font-size: 13px;
                                    font-weight: 400;
                                    line-height: 15.6px;
                                    text-align: left;
                                    color: $black;
                                    max-width: 188px;
                                }
                            }
                            .store_phone {
                                font-family: $primaryFont;
                                font-size: 13px;
                                font-weight: 400;
                                line-height: 15.6px;
                                text-align: left;
                                color: $black;
                                max-width: 188px;
                            }
                        }

                        .see_map {
                            display: none;
                        }

                        .store_open_hours {
                            display: block !important;

                            &:has(.txt_store_close_soon) {
                                font-size: 13px;
                            }

                            .txt_store_open {
                                font-family: $primaryFont;
                                font-size: 13px;
                                font-weight: 400;
                                line-height: 15.6px;
                                text-align: left;
                                color: $greenMedium;

                                &:before {
                                    content: "";
                                    width: 7px;
                                    height: 7px;
                                    background-color: $greenMedium;
                                    display: inline-block;
                                    border-radius: 50%;
                                    margin-right: 5px;
                                    top: -1px;
                                    position: relative;
                                }
                            }

                            .txt_store_open_second {
                                font-family: $primaryFont;
                                font-size: 13px;
                                font-weight: 400;
                                line-height: 15.6px;
                                text-align: left;
                                color: $black;
                            }

                            .txt_store_closed {
                                font-family: $primaryFont;
                                font-size: 13px;
                                font-weight: 400;
                                line-height: 15.6px;
                                text-align: left;
                                color: $red;

                                &:before {
                                    content: "";
                                    width: 7px;
                                    height: 7px;
                                    background-color: $red;
                                    display: inline-block;
                                    border-radius: 50%;
                                    margin-right: 5px;
                                    top: -1px;
                                    position: relative;
                                }
                            }
                        }

                        .link_store_wrapper {
                            .schedule_wrapper {
                                .show_schedule  {
                                    font-family: $primaryFont;
                                    font-size: 13px;
                                    font-weight: 400;
                                    line-height: 15.6px;
                                    text-align: left;
                                    text-decoration: underline;
                                    margin-top: 5px;
                                    cursor: pointer;
                                }

                                .store_week_schedule  {
                                    display: none;
                                    margin-top: 5px;
                                    font-family: $primaryFont;
                                    font-size: 13px;
                                    font-weight: 400;
                                    line-height: 15.6px;
                                }
                            }

                            .choice_store {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .close_pop {
        position: absolute;
        right: 25px;
        top: 20px;
        font-size: 0px;
        background-color: $black;
        width: 16px;
        height: 16px;
        mask-image: url('../svg/spritesheet.svg');
        @include maskIconCoord(5, 6, 6);
        background-size: 600% auto;
        background-repeat: no-repeat;
        cursor: pointer;
    }
}