
.wrapper_moncompte .wrapper_menu_compte {
    padding-top: 26px;
    padding-left: 9px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 1016px;
    margin: 0 auto 50px;
    box-sizing: border-box;

    a {
        position: relative;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        text-decoration: none;
        color: #000;

        &.actif{
            font-weight: 500;
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 120%;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: #000;
                }
        }

    }
}
