@import '../../_app/_src/scss/desktop/mixins/typeFace';

$graphik: (
    normal: (light, regular, medium)
);

$millerbanner: (
    normal: (regular),
    italic: (regular)
);

@include typeFace('graphik', $graphik);
@include typeFace('millerbanner', $millerbanner);

$primaryFont: 'graphik', sans-serif;
$secondaryFont: 'millerbanner', serif;
$arial: 'arial', serif;