/**
 * Product page general styles
 */
.prod_top_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 1860px;
    margin: 10px auto 10px 0;

    @media screen and (min-width: 1921px) {
        margin: 10px auto;
        justify-content: center;
    }
}

/**
 * Left part / Main visus
 */
#jqzoom {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 63%;

    .eclatProd {
        position: absolute;
        top: 2.5rem;
        left: -1rem;
        transform: rotate(-90deg);
    }

    @media screen and(max-width: 1280px) {
        width: 58%;
    }

    .prodPageMainVisu {
        width: 49.8%;
        max-width: 603px;
        margin-bottom: 0.3%;
        margin-left: 0.4%;
        cursor: pointer;

        &:nth-child(2n + 1) {
            margin-left: 0;
        }

        &:nth-child(3),
        &:nth-child(4) {
            margin-bottom: 0.3%;
        }

        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
            width: 24.7%;
        }

        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
            margin-left: 0.4%;
        }

        .ill_img {
            position: relative;
            display: block;
            width: 100%;
            padding-bottom: calc(804 / 603 * 100%);

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
                vertical-align: top;
            }
        }
    }

    .has_video {
        width: 49.8%;
        max-width: 603px;
        margin-bottom: 0.3%;

        .block_vid {
            position: relative;
            width: 100%;
            padding-bottom: calc(804 / 603 * 100%);
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

/**
 * Right part / Prod infos
 */
#fpRightPart {
    position: sticky;
    top: 120px;
    width: 36.6%;
    padding-left: 2.1%;
    box-sizing: border-box;

    @media screen and(max-width: 1280px) {
        width: 41%;
    }

    .product_info {

        .item_name {
            position: relative;

            .alma_upselling_panier {
                position: absolute;
                cursor: pointer;
                right: 0;
                bottom: 0;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: fit-content;
                font-family: $primaryFont;
                font-size: 10px;
                font-weight: 400;
                text-transform: uppercase;
                text-align: center;

                span {
                    text-decoration: underline;
                }

                &::after {
                    content: "";
                    display: block;
                    width: 33px;
                    height: 16px;
                    background: url("../img/alma.svg");
                    background-size: contain;
                    margin-left: 8px;
                }
            }
        }

        .itm_form_group {
            .prod_btn_wrapper {
                width: 100%;
                max-width: 300px;
                box-sizing: border-box;
                margin-right: 7px;
                display: none;

                #sizeerror {
                    font-size: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 0.5rem;
                }

                #bloc_add_basket {
                    box-sizing: border-box;

                    &.loading .loader {
                        opacity: 1;
                        z-index: 2;
                    }
                }

                #bloc_add_alert {
                    width: 100%;
                    box-sizing: border-box;

                    .btnAddBasket {
                        width: 100%;
                    }

                    #keep_alive_stock {
                        width: 100%;

                        input#mail_alerte_stock {
                            height: 46px;
                        }
                    }
                }

                #bloc_reserve_instore {
                    min-width: 270px;
                    max-width: 49%;
                    box-sizing: border-box;
                }

                #bloc_availability {
                    display: block;
                    max-width: 270px;
                    width: 100%;
                    margin-left: 8px;
                    box-sizing: border-box;

                    .disponibility {
                        border: 1px solid $black;
                        box-sizing: border-box;
                        background-color: $white;
                        transition: 0.2s ease-out;

                        &:before {
                            display: none;
                        }

                        &:hover {
                            background-color: $black;

                            cursor: pointer;

                            span {
                                color: $white;
                            }
                        }

                        span {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-family: graphik;
                            font-size: 13px;
                            font-weight: 500;
                            line-height: 0.9;
                            letter-spacing: 0.4px;
                            text-decoration: none;
                            text-transform: uppercase;
                            color: $black;
                        }
                    }
                }
            }

            #size_is_not_available {
                padding-top: 19px;
                display: block;
                font-size: 13px;
                font-weight: 400;
                line-height: 19px;
                font-family: $primaryFont;
            }
        }
    }

    #itm_name {
        position: relative;
        justify-content: space-between;
        align-items: center;
        font-size: 28px;
        font-weight: 500;
        margin: 5px 0 14px;
        padding: 0 28px 0 0;

        .bgSprite {
            position: absolute;
            top: 5px;
            right: -3px;

            &:before {
                width: 28px;
                height: 28px;
            }

            &.existToWishlistButton:before {
                @include bgIconPath("../svg/spritesheet.svg");
                @include bgIconCoord(23, 6, 6);
            }
        }

        small {
            font-size: 10px;
            text-transform: uppercase;
            font-weight: 400;
        }
    }

    #productRef {
        display: none;
    }

    .item_price {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: baseline;

        .pricetag {
            position: relative;
            font-size: 20px;
            font-weight: 500;
            line-height: 1;

            +.pricetag {
                font-size: 20px;
                color: #989898;
                text-decoration: line-through;
                margin-right: 16px;
            }
        }

        .ecalt_rect_place {
            margin-left: 16px;
        }
    }

    .productColorFieldset,
    .productSizeFieldset {
        .colorSizeTitle {
            display: flex;
            justify-content: flex-start;
            align-items: baseline;
            font-size: 13px;
            font-weight: 500;
            text-transform: uppercase;
            line-height: 1;

            label {
                width: 94px;
            }
        }

        .choices_list {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;

        }

        input[type="radio"] {
            display: none;
        }
    }

    #sizeguide {
        position: relative;
        font-family: $secondaryFont;
        font-style: italic;
        font-size: 13px;
        font-weight: 400;
        text-transform: initial;
        color: #000;
        cursor: pointer;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 92%;
            left: 0;
            right: 0;
            height: 1px;
            background-color: #000;
            transition: left 0.2s ease-in-out;
        }

        &:hover:after {
            left: 100%;
        }
    }

    .productColorFieldset {
        margin-top: 20px;

        .colorSizeTitle {
            margin-bottom: 21px;
        }

        .choices_list {
            margin-left: 3px;
            max-width: 100%;
        }

        .form_itm {
            margin-right: 13px;
            margin-bottom: 13px;
        }

        input[type="radio"] {
            +label {
                position: relative;
                display: block;
                width: 14px;
                height: 14px;
                box-sizing: border-box;
                border-radius: 50%;
                cursor: pointer;
            }

            &:checked+label,
            +label:hover {
                border: 1px solid #000;
            }

            &#color_4+label {
                border: 1px solid #aaa;
            }
        }

        #currentSelectedColor {
            font-family: $secondaryFont;
            font-style: italic;
            font-size: 13px;
            font-weight: 400;
            text-transform: initial;
            color: #989898;
        }
    }

    .productSizeFieldset {
        margin-top: 10px;

        .colorSizeTitle {
            margin-bottom: 18px;
        }

        .form_itm {
            margin-right: 25px;
        }

        input[type="radio"] {
            +label {
                position: relative;
                font-size: 16px;
                cursor: pointer;

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 106%;
                    left: 0;
                    width: 0;
                    height: 1px;
                    background-color: #000;
                    transition: width 0.2s ease-in-out;
                }
            }

            &:checked+label:after,
            +label:hover:after {
                width: 100%;
            }

            &.disabled {
                +label {
                    color: #989898;

                    &:after {
                        width: 100%;
                        top: 53%;
                        background-color: #989898;
                    }
                }
            }
        }
    }

    #product_addcart {
        margin-top: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .prod_btn_wrapper {
            #bloc_add_alert {
                height: auto;

                #btn_add_alert {
                    height: 46px;
                }

                .bloc_add_alert_confirmation {
                    height: 80px;
                }
            }

            #bloc_add_basket {
                width: 100%;
                max-width: 300px;

                .loader {
                    width: calc(100% - 2px); // Account for border
                    height:calc(100% - 2px); // Account for border
                }
            }
        }

        #bloc_availability {
            width: 100%;
            max-width: 300px;

            #ctaAvailabilityInStore {
                background-color: $white;
                border: 1px solid $black;
                cursor: pointer;

                &:hover {
                    background-color: $black;
                    transition: all 0.4s ease-in-out;

                    span {
                        color: $white;
                    }
                }

                span {
                    font-family: $primaryFont;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 15.6px;
                    text-align: center;
                    text-transform: uppercase;
                    color: $black;
                }
            }

            &.inactiv {
                opacity: 0.6;

                #ctaAvailabilityInStore {
                    &:hover {
                        background-color: $white;

                        span {
                            color: $black;
                        }
                    }
                }
            }
        }

        // To make "ctaAvailabilityInStore" align to the left when "bloc_add_basket" is hidden
        &:has(#bloc_add_basket[style="display: none;"]),
        &:has(#bloc_add_basket.cache) {
            .prod_btn_wrapper {
                display: none!important;
            }
        }
    }

    .disabledBtnTooltip .disabledBtnInfos {
        font-size: 13px;
        line-height: 17px;
    }

    #bloc_add_basket,
    #bloc_add_alert {
        width: 270px;
        width: 49%;
    }

    .product_feats_wrapper {
        margin-top: 15px;

        .thumbcnt {
            .productFeatTitle {
                font-size: 16px;
                font-weight: 500;
                text-transform: uppercase;
                color: $black;
                cursor: pointer;
            }
        }

        .thumbnav {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 16px;

            @media screen and (max-width: 1200px) {
                .thumbnav {
                    width: 100%;

                    .thumb {
                        width: 49%;

                        &:nth-of-type(1) {
                            display: flex;
                        }
                    }
                }
            }
        }

        .thumblnk {
            color: #000;
            text-decoration: none;
            cursor: pointer;

            .title_thumblnk {
                font-size: 16px;
                font-weight: 500;
                text-transform: uppercase;
            }

            &.current {
                cursor: default;

                .title_thumblnk {
                    position: relative;
                    font-family: $secondaryFont;
                    font-size: 20px;
                    font-weight: 400;
                    font-style: italic;
                    text-transform: initial;
                    letter-spacing: 0.01rem;

                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 90%;
                        left: 0;
                        width: 100%;
                        height: 1px;
                        background-color: #000;
                    }
                }
            }
        }

        .thumbslide {
            display: none;
            font-size: 13px;
            line-height: 1.2;

            &.current {
                display: block;
            }

            .title_compo_entretien {
                font-weight: bold;
            }
        }

        .thumbcnt {
            div {
                font-weight: 400;
            }
        }
    }

    .fp_reassu {
        margin-top: 15px;
        font-size: 13px;

        p {
            margin-bottom: .5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        strong {
            font-weight: 500;
        }
    }

    #keep_alive_stock {
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
        width: 270px;
        background-color: #fff;
    }

    .w-alertestock-form {
        width: 100%;
        margin: 0;

        .w-form-line {
            margin-bottom: 0;

            &.w-submit {
                position: absolute;
                top: 1px;
                right: 1px;
                width: 44px;
                height: 44px;
            }

            .w-submit-button,
            button.w-submit-button {
                width: 100%;
                height: 100%;
                background-color: #fff;
                border: 0;
                color: #000;
                font-size: 13px;
                font-weight: 500;
            }
        }

        .w-submit-button.w-loader,
        button.w-submit-button.w-loader {
            background-size: auto 15%;
        }

        .w-input-label,
        .w-input-note.w-input-error {
            text-transform: initial;
        }

        .w-input-note.w-input-error {
            line-height: 1;
        }
    }

    .bloc_add_alert_confirmation {
        position: relative;
        z-index: 2;
        background: #fff;
        text-transform: initial;
        padding: 1em 0 2em;
        font-size: 13px;
        font-weight: 400;

        p {
            margin-bottom: 1.5em !important;
        }

        .w-submit-button,
        button.w-submit-button {
            height: 46px;
        }
    }
}

.disabledBtnTooltip {
    top: 140%;
    text-align: left;
    font-size: 10px;
    font-weight: 500;
    border: 0;
    padding: 0;
}

/**
 * Associations / Ranges
 */
.product_associations_wrapper,
.product_ranges_wrapper {
    margin-top: 75px;

    p.product_ranges_wrapper_title:not(.item_title),
    h3:not(.item_title) {
        width: 98%;
        max-width: 1800px;
        margin: 0 auto 28px;
        font-size: clamp(20px, fs-vw(50, 1920), 50px);
        font-weight: 500;
        text-transform: uppercase;
    }

    h3 {
        width: 98%;
        max-width: 1800px;
        margin: 0 auto;
        font-size: clamp(20px, fs-vw(50, 1920), 50px);
        font-weight: 500;
    }

    h2.product_ranges_wrapper_title,
    h2.complete_look_title {
        width: 98%;
        max-width: 1800px;
        margin: 0 auto 28px;
        font-size: clamp(20px, fs-vw(30, 1920), 50px);
        font-weight: 500;
        text-transform: uppercase;
    }

    #productAssociationSwiper,
    #productRangesSwiper {
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;

        .noSizeAvailable {
            background: $white;
            text-align: center;

            p#size_is_not_available {
                margin-top: 24px;
                font-size: 0.9rem;
            }
        }

        .wrap_rolloverproduit {
            .btnAddBasketWrapper {
                min-height: 64px;
                margin-top: unset;
            }
        }
    }

    .item a.blockInfosProd,
    .wrap_rolloverproduit .productColorFieldset {
        margin-left: 20px;
    }

    .wrap_rolloverproduit .productColorFieldset input[type="radio"]+label {
        margin-right: 8px;
        border: none;
    }

    .wrap_rolloverproduit .productColorFieldset {

        input[type=radio]+label:hover,
        input[type=radio]:checked+label {
            border: 0.5px solid #000;
            box-shadow: none;
        }
    }

    .prevAssoc,
    .nextAssoc,
    .prevRange,
    .nextRange {
        position: absolute;
        z-index: 1;
        top: 40%;
        cursor: pointer;

        &.bgSprite:before {
            width: 30px;
            height: 30px;
        }

        &.swiper-button-disabled {
            opacity: 0.2;
            cursor: default;
        }
    }

    .prevAssoc,
    .prevRange {
        left: 1rem;
        transform: rotate(180deg);
    }

    .nextAssoc,
    .nextRange {
        right: 1rem;
    }
}

/* E-RESA */
#e-resaBox {
    width: 820px;
    min-height: 680px;
    padding: 15px 120px;
    box-sizing: border-box;

    .close_pop {
        border-style: none;
        border-color: transparent;
        border: none;
        background-color: transparent;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;

        &:after,
        &:before {
            display: none;
        }
    }

    .content_e-resaBox {
        min-height: 515px;

        .popup_title {
            margin-bottom: 2rem;
            font-weight: 500;
            font-size: 40px;
            line-height: 48px;
            text-align: center;
        }

        .popup_info {
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
            text-align: center;
            margin-bottom: 40px;
        }
    }

    .popup_header,
    .steps_num {
        padding: 0 3rem;
    }

    .popup_header {
        text-align: center;

        .popup_title {
            margin: 0 0 30px 0;
            font-size: 35px;
            font-weight: 300;

            strong {
                font-weight: 600;
            }
        }

        .popup_desc {
            display: none;
            font-size: 14px;
            font-weight: 500;
            margin: -10px 0 30px;
        }
    }

    .steps_num {
        display: flex;
        justify-content: center;
        margin: 0 0 25px;

        .step_num {
            font-size: 13px;
            color: black;
            font-weight: 400;
            margin: 0 2rem;
            text-transform: uppercase;
            line-height: 16px;

            &.actif {
                font-weight: 600;
            }
        }
    }

    .steps {
        position: relative;
        height: 360px;

        &.loading {
            background: white url(../img/loader.gif) no-repeat center;

            .step {
                opacity: 0;
            }
        }

        .step {
            display: none;
            width: 100%;

            &.loading {
                .step_content {
                    opacity: 0;
                }

                background: url("../img/Ellipsis-1.8s-40px.gif") center center no-repeat;
            }

            &.actif {
                display: block;
                min-height: 340px;

                .step_content {
                    opacity: 1;
                    min-height: 340px;
                }
            }
        }

        .error {
            text-align: center;
        }

        #step1 {
            height: 400px;
        }

        #step2 {
            .opening_until {
                padding-left: 1.125rem;
                position: relative;

                span.txt_store_open,
                span.txt_store_closed {
                    text-transform: initial;
                    font-weight: bold;
                }

                span.txt_store_open {
                    color: #33c844;

                    &::before {
                        height: 12px;
                        width: 12px;
                        border-radius: 50%;
                        background: #33c844;
                        position: absolute;
                        content: "";
                        top: 0.2rem;
                        left: 0;
                    }
                }

                span.txt_store_closed {
                    color: red;

                    &::before {
                        height: 12px;
                        width: 12px;
                        border-radius: 50%;
                        background: red;
                        position: absolute;
                        content: "";
                        top: 0.2rem;
                        left: 0;
                    }
                }
            }

            .step_content {
                .w-form-line {
                    .w-input-container {
                        &.country {
                            width: 35%;
                        }

                        &.adresse {
                            width: 65%;
                        }
                    }
                }

                .store_locator {

                    /* width */
                    ::-webkit-scrollbar {
                        width: 10px;
                    }

                    /* Track */
                    ::-webkit-scrollbar-track {
                        background: #f1f1f1;
                    }

                    /* Handle */
                    ::-webkit-scrollbar-thumb {
                        background: #888;
                    }

                    /* Handle on hover */
                    ::-webkit-scrollbar-thumb:hover {
                        background: #555;
                    }
                }
            }

            .w-input-element:not([type="radio"]):not([type="checkbox"]),
            .w-nonempty:not(.no-label) .w-input-element:not([type="radio"]):not([type="checkbox"]):not(textarea) {
                width: 100%;
                height: 48px;
                padding: 0 1rem;
                box-sizing: border-box;
            }

            .w-dropdown {
                height: 48px;
                box-sizing: border-box;
                border-radius: 0;
                border-color: #c5c5c5;

                &:after {
                    border: 0;
                    width: 11px;
                    height: 5px;
                    background: transparent url(../img/select.png) no-repeat center;
                }
            }


            .store_locator {
                height: 100%;
                max-height: 295px;
                width: 102%;
                opacity: 0;
                overflow-x: hidden;

                &.loaded {
                    opacity: 1;
                }

                .js_store_report {
                    min-height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 13px;
                }

                .store {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    padding: 1rem 0rem 1rem 0.5rem;
                    margin-right: 2rem;
                    box-sizing: border-box;
                    border-top: 1px solid #eeeeee;
                    min-height: 147.5px;
                    width: 100%;

                    &:first-of-type {
                        border-top: none;
                    }

                    .store_info {
                        width: 60%;
                        margin-right: 2%;
                    }

                    .form_submit {
                        width: 30%;
                        padding-right: 1rem;
                    }
                }

                .store_info {
                    .distance_store {
                        display: none;
                    }

                    .name_store {
                        font-size: 16px;
                        font-weight: 500;
                        text-transform: uppercase;
                        margin-bottom: 12px;
                    }

                    .infos_store {
                        font-size: 14px;
                        margin-bottom: 12px;
                        line-height: 1.4;
                    }

                    .adresse_store,
                    .phone_store {
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 16px;
                        margin-bottom: 0.2rem;
                    }

                    .phone_store {
                        margin-bottom: 0.4rem;
                        display: none;
                    }

                    .opening_until {
                        display: block !important;
                        font-size: 0.85rem;
                        margin-bottom: 0.8rem;

                        .txt_store_open {
                            &::before {
                                height: 9px;
                                width: 9px;
                                top: 0.15rem;
                            }
                        }
                    }

                    a.js_have_horaire_store {
                        font-size: 14px;
                        font-weight: 400;
                        color: black;
                        text-decoration: underline;
                        text-underline-position: under;

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    .schedule_store {
                        display: none;
                        margin-top: 1rem;

                        p {
                            font-size: 14px;
                            line-height: 1.4;
                        }

                        &.actif {
                            display: block;
                        }
                    }
                }

                .form_submit {
                    position: relative;
                    width: 210px;
                    height: 46px;

                    .button,
                    .loader {
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        appearance: none;
                    }

                    .button {
                        display: flex;
                        border: 1px solid black;
                        background-color: black;
                        color: white;
                        text-transform: uppercase;
                        transition: all 0.3s ease-in-out;
                        cursor: pointer;

                        &:hover {
                            background-color: white;
                            color: black;
                        }
                    }

                    .loader {
                        border: 1px solid black;
                        background-color: white;
                        z-index: -1;
                        background: transparent url(../svg/three_dots.svg) no-repeat center;
                        background-size: 30%;
                    }

                    .quantity_store {
                        position: absolute;
                        top: 130%;
                        left: 0;
                        width: 100%;
                        text-align: center;
                        font-size: 11px;
                        max-width: 175px;
                        display: flex;
                        justify-content: center;
                        display: none;

                        &.actif {
                            display: block;

                            .article_disponible {
                                display: block;
                            }
                        }

                        &:not(.actif) {
                            .article_non_disponible {
                                display: block;
                            }
                        }

                        &.no_stock {
                            display: block;
                        }

                        .article_disponible {
                            .stock-alerte- {
                                display: inline-block;
                                color: $orangeAlert;

                                &s {
                                    display: none;
                                }
                            }

                            .in-stock- {
                                display: none;

                                &s {
                                    display: inline-block;
                                    color: #33c844;
                                }
                            }
                        }

                        .article_non_disponible {
                            color: red;
                            margin-right: 4px;
                            display: block;
                        }
                    }
                }
            }
        }

        #step3 {
            font-size: 14px;

            #error_limit_quota {
                margin-bottom: 10px;

                small {
                    text-align: left;
                    color: red;
                }
            }

            .infos,
            #error_limit_quota small {
                font-family: Arial, Helvetica, sans-serif;
                font-size: 11px;
                line-height: 1.2;
            }

            .w-eresa-form .mandatory {
                font-family: Arial, Helvetica, sans-serif;
                display: none;
            }

            .w-checkbox-input .w-input-element+.w-input-label {
                font-weight: 400;

                span {
                    white-space: nowrap;
                }
            }

            .w-form-line {

                &.utilisation_condition,
                &.optin {
                    flex-wrap: wrap;

                    .checkbox-group {
                        display: flex;
                        align-items: center;

                        .w-input-container+.w-input-container {
                            margin-left: 2rem;
                        }
                    }
                }

                &.optin {
                    margin: 1rem 0 1rem;

                    label {
                        width: 100%;
                        font-weight: 400;
                        text-align: center;
                        margin-bottom: 0.5rem;
                        text-align: start;
                    }
                }

                &.utilisation_condition {
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 13px;

                    .w-radio-input .w-input-element {
                        &+.w-input-label {
                            font-family: Arial, Helvetica, sans-serif;

                            &::before {
                                border-radius: 0;
                            }
                        }
                    }

                    a {
                        color: black;
                        text-decoration: underline;
                        text-underline-position: under;

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    .w-input-note.w-input-error {
                        text-align: center;
                    }
                }

                &.form-eresa-civility {
                    +.w-form-line {
                        +.w-form-line {
                            margin-bottom: 1.275rem;
                            align-items: baseline;

                            .w-input-container {
                                +.w-input-container {
                                    .w-input {
                                        .w-input-label {
                                            padding-left: 0.9rem;
                                        }

                                        &.w-nonempty {
                                            .w-input-element {
                                                padding-left: 5.4286em;
                                            }
                                        }
                                    }

                                    .errormsgadd {
                                        color: $errormsgadd;
                                        font-size: 12px;
                                        font-weight: 400;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .w-form-line.w-submit {
                max-width: 150px;
                margin: 0 auto;
                height: 46px;

                .w-submit-button.w-loader {
                    opacity: 1;
                }
            }

            .bookinstore_form_client {
                .form-eresa-civility {
                    .w-input-label {
                        font-weight: 400;
                    }
                }

                button.selected-flag.has-flag-icn {
                    left: 1px;
                }
            }

            #form_eresa {
                .w-form-line {

                    &:nth-of-type(2),
                    &:nth-of-type(3) {

                        input:-webkit-autofill,
                        input:-webkit-autofill:focus {
                            transition: background-color 600000s 0s, color 600000s 0s;
                        }

                        input[data-autocompleted] {
                            background-color: transparent;
                        }
                    }
                }
            }
        }

        #step4 {

            .distance_store,
            .store .form_submit {
                display: none;
            }

            .confirm_info {
                font-size: 14px;
                font-weight: 500;
                text-align: center;
                margin-bottom: 1.5rem;
            }

            .eresa_recap {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-wrap: wrap;
                width: 100%;

                .eresa_choices {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    padding-bottom: 5%;

                    .eresa_prod {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-end;
                        width: 70%;

                        &:after {
                            content: "";
                            height: 150px;
                            border-right: 2px solid grey;
                            padding-left: 70px;
                        }

                        img {
                            width: 120px;
                            vertical-align: top;
                        }

                        .eresa_prod_detail {
                            padding-left: 20px;
                            padding: 0 0 6% 5%;
                            font-size: 13px;
                            line-height: 1.5;

                            .prod_title {
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 16px;
                            }

                            .item_price_wrapper {
                                padding: 3px 0 32px;

                                .item_price {
                                    font-size: 13px;
                                    font-weight: 500;
                                    margin-bottom: 0.4rem;

                                    .pricetag.crossed,
                                    .pricetag+.pricetag {
                                        position: relative;
                                        color: #777;
                                        font-weight: 500;
                                        margin-right: 1rem;

                                        &:after {
                                            content: "";
                                            display: block;
                                            position: absolute;
                                            top: 50%;
                                            left: 0;
                                            width: 100%;
                                            height: 1px;
                                            background-color: #777;
                                        }
                                    }
                                }
                            }

                            .item_taille_color_container {
                                strong {
                                    font-weight: 400;
                                }
                            }

                            .titre_couleur {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;

                                strong {
                                    margin-right: 0.2rem;
                                }

                                #js_color {
                                    white-space: nowrap;

                                    &.with_rgb {
                                        font-size: 0;
                                        width: 10px;
                                        height: 10px;
                                        display: inline-block;
                                        position: relative;
                                        margin-left: 0.2rem;

                                        &::after {
                                            content: "";
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                            border-radius: 50%;
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            background-color: var(--prodColor);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .eresa_store_choice {
                        width: 30%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .store {
                            padding: 25% 0 0 20px;
                        }
                    }
                }
            }

            .store_info {
                font-size: 13px;
                line-height: 1.4;

                .name_store {
                    font-size: 13px;
                    font-weight: 600;
                    text-transform: uppercase;
                    margin-bottom: 12px;
                }

                .infos_store {
                    font-size: 13px;
                    margin-bottom: 12px;
                    line-height: 1.4;
                }

                .phone_store {
                    margin-bottom: 10px;
                }

                .js_have_horaire_store {
                    font-size: 13px;
                    font-weight: 400;
                    color: $black;
                    text-decoration: underline;
                    text-underline-position: under;

                    &:hover {
                        text-decoration: none;
                    }
                }

                .schedule_store {
                    display: none;

                    &.actif {
                        display: block;
                    }
                }
            }

            .eresa_procedure {
                font-size: 13px;
                line-height: 1.5;
                width: 100%;

                .eresa_procedure_title {
                    font-weight: 500;
                    margin-bottom: 0.2rem;
                    text-transform: uppercase;
                    font-size: 13px;
                }

                .eresa_procedure_content {
                    margin-bottom: 1rem;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 1.3;
                }
            }

            .form_submit {
                width: 150px;
                height: 40px;
                margin: 0 auto;

                .button {
                    width: 100%;
                    height: 100%;
                    background-color: $black;
                    color: $white;
                }
            }
        }
    }

    #content_e-resaBox {
        #error_limit_quota+.infos {
            padding-top: 1rem;
        }
    }
}

#e-resaBox .steps #step3 {
    .w-form-line.optin>.checkbox-group {
        width: 100%;
        align-items: center;
        margin: 0.5rem 0;

        >.w-form-line {
            margin-bottom: 0;
            width: 33%;

            &:first-of-type {
                margin-right: 4.5%;
            }

            >span {
                margin-right: 4%;
            }

            >.w-radio-group {
                >label.w-radio-input {
                    margin-bottom: 0;
                }
            }
        }
    }
}


.product_feats_wrapper {
    width: 97%;
    padding-top: 8px;
    margin-right: 24px;

    .ref {
        margin-bottom: 18px;
        font-family: $primaryFont;
        font-size: 10px;
        line-height: 18px;
        letter-spacing: .07em;
        color: $primaryColor;
        text-transform: uppercase;
    }

    .productFeatDescription {
        display: none;
        padding-bottom: 18px;
        font-size: 13px;
        font-weight: 400;
        line-height: 19px;
        font-family: $primaryFont;

        .thumbsilde_title {
            margin-bottom: 5px;
            font-family: $primaryFont;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 300;
            letter-spacing: 1.3px;
        }

        .contents {
            margin-bottom: 20px;

            p:first-child,
            .thumbsilde_subtitle {
                margin-bottom: 10px;
            }

            .thumbsilde_subtitle {
                +p {
                    margin-bottom: 10px;

                    +ul {
                        +p {
                            margin-bottom: 10px;

                            .thumbsilde_subtitle.international_shipping {
                                margin-bottom: 10px;

                                +p {
                                    margin-bottom: 10px;
                                }
                            }
                        }
                    }
                }
            }

            .about_shipping_rates,
            .about_product_return {
                text-decoration: underline;
            }
        }

        .title_compo_entretien {
            text-decoration: underline;
            font-weight: 500;
            margin: .2rem 0;
        }

        &.blocEntretien {
            label {
                display: block;
                font-weight: bold;
                margin: 1rem 0 .5rem;
            }
        }

        a {
            color: $black;
            text-decoration: underline;
        }
    }

    .productFeatTitle {
        position: relative;
        display: flex;
        margin-bottom: 14px;
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 25px;
        font-family: $primaryFont;
        cursor: pointer;

        span,
        a {
            display: inline;
            width: auto;
            position: relative;

            &:before {
                position: absolute;
                content: ' ';
                bottom: 0;
                display: inline-block;
                width: calc(100%);
                height: 1px;
                background: #272525;
                opacity: 0;
                transform: scale(0);
                transform-origin: center;
                transition: .3s ease-in-out;
            }

            &:hover {
                &:before {
                    transform: scale(1);
                    opacity: 1;
                }
            }
        }

        &.current {
            &::after {
                background-image: url("../svg/moins.svg");
                top: 11px;
            }
        }

        &.disabled {
            opacity: .3;
            cursor: not-allowed;

            span,
            a {
                &:hover {
                    &:before {
                        transform: none;
                        opacity: 0;
                    }
                }
            }
        }

        &::after {
            position: absolute;
            content: "";
            display: block;
            right: 0;
            top: 7px;
            width: 15px;
            height: 15px;
            background-image: url("../svg/plus.svg");
            background-repeat: no-repeat;
        }
    }
}