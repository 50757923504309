/**
 * Top elements
 */
.wrapper_wishlist {
    width: 98%;
    max-width: 1280px;
    margin: -2px auto 0;

    .intro {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;

        p {
            font-size: 13px;
        }

        #nrArticlesSelected {
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
        }
    }

    .actionButtons {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 24px;

        .form_submit {
            width: 200px;

            + .form_submit {
                margin-left: 8px;
            }

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
            }
        }

        .mail_share {
            position: relative;
            font-size: 16px;
            font-weight: 500;
            color: #000;
            text-transform: uppercase;
            text-decoration: none;
            margin: -5px 0 0 24px;

            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 126%;
                right: 0;
                width: 100%;
                height: 1px;
                background: #000;
                transition: width 0.2s ease-in-out;
            }

            &:hover:after {
                width: 0;
            }
        }
    }
}

/**
 * Wishlist products
 */
.wish_liste_product {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 40px;

    .product_ctn {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 48%;
        max-width: 612px;
        height: 208px;
        margin-bottom: 40px;
        padding: 24px;
        border: 1px solid #989898;
        box-sizing: border-box;

        @media screen and(max-width: 1360px) {
            width: 46%;
        }

        @media (orientation: portrait) {
            width: 100%;
            max-width: 100%;
        }

        &:nth-child(2n + 1) {
            margin-left: 8px;
        }

        &:nth-child(2n + 2) {
            margin-left: 48px;

            @media (orientation: portrait) {
                margin-left: 8px;
            }
        }
    }

    div.remove {
        position: absolute;
        top: 27px;
        right: 27px;
    }

    .checkbox_select_product {
        display: none;

        & + label {
            position: absolute;
            top: 50%;
            left: -9px;
            transform: translateY(-50%);
            display: block;
            width: 15px;
            height: 15px;
            border: 1px solid $tertiary;
            box-sizing: border-box;
            font-size: 0;
            background-color: $white;
            box-shadow: inset 0 0 0 15px $white;
            transition: all 0.2s ease-in-out;
        }

        &:checked + label {
            background-color: $black;
            box-shadow: inset 0 0 0 2px $white;
        }
    }

    .wrapper_img {
        position: relative;
        width: 120px;
        height: 160px;

        span.ill_img {
            position: relative;
            display: block;
            width: 100%;
            padding-top: (160 / 120 * 100%);

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
            }
        }
    }

    .wishNoStock {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 2em 0;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(3px);
        font-size: 12px;
        text-align: center;
    }

    .product_desc {
        width: calc(100% - 136px);
        margin-left: 16px;
    }

    .wrap_txt {
        .title {
            font-size: 13px;
            font-weight: 500;
            margin: 15px 0 10px;

            a {
                color: black;
                text-decoration: none;
            }
        }

        .bloc_price {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
            align-items: baseline;
            margin-bottom: 11px;

            .price_tag {
                font-size: 16px;
                font-weight: 500;

                + .price_tag {
                    font-size: 14px;
                    color: #989898;
                    margin-right: 10px;
                    text-decoration: line-through;
                }
            }
        }
    }

    .wrapper_details {
        font-size: 13px;
        line-height: 1.2;
        margin-bottom: 26px;
    }

    .wishlistMod {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 13px;
        margin-left: -3px;

        .cta_add_to_basket,
        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        .cta_add_to_basket {
            cursor: pointer;
        }

        a {
            color: #000;
            margin-left: 38px;
        }

        .bgSprite:before {
            margin: 2px 8px 0 0;
        }
    }
}

// Centers selection sharing success lightbox
body.fr.wishlist ,
body.en.wishlist  {
    #sendwishlistbox:has(.sended){
        position: fixed;
        top: 40%;
}
}
// Centers selection sharing success lightbox END







