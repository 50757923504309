/**
 *
 */
.page_rayon.rayon_lookbook {
    margin-top: -calc(#{$pre-header-height} + #{$header-height});

    .lookbook_bandeau {
        display: none;
    }
}

#list_item_look {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;

    .lookItem {
        position: relative;
        width: 25%;

        &.lookHoriz {
            width: 50%;

            .ill_img {
                padding-top: calc(768 / 960 * 100%);
            }

            &:first-child {
                width: 100%;

                .ill_img {
                    padding-top: calc(870 / 1920 * 100%);
                }
            }
        }

        .ill_img {
            display: block;
            position: relative;
            width: 100%;
            padding-top: calc(768 / 480 * 100%);

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
            }
        }

        .wrapperLookDescr {
            position: absolute;
            top: 50%;
            left: 10%;
            transform: translateY(-50%);
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-transform: uppercase;
            color: $white;
            width: 88%;
            max-width: 1078px;

            .wrapperLookDescrTitle {
                width: min-content;
                font-size: 70px;
                font-weight: 500;
                line-height: 84px;
                text-align: center;
                margin: -10px 68px 0 0;
            }

            h1,
            p {
                font-size: 16px;
                font-weight: 500;
                line-height: 1.2;
            }

            h1 {
                margin-bottom: 1em;
            }
        }
    }

    a.tag {
        position: absolute;
        display: block;
        width: 24px;
        height: 24px;
        text-align: center;
        line-height: 22px;
        background-color: #fff;
        border-radius: 50%;
        color: #000;
        text-decoration: none;

        .pinpoint{
            &:after {
                content: '+';
            }
        }

        .tag_tooltip {
            display: none;
            position: absolute;
            z-index: 5;
            background-color: #fff;
            text-align: left;

            img {
                vertical-align: top;
            }

            .lookItemInfos {
                font-size: 13px;
                font-weight: 500;
                line-height: 1;
                padding: .5rem;
            }

            .lookItemTitle {
                margin-bottom: .5rem;
            }

            .product_price {
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-end;
                align-items: baseline;

                .pricetag+.pricetag {
                    font-size: .9em;
                    color: #989898;
                    text-decoration: line-through;
                    margin-right: .5rem;
                }
            }
        }

        &.right100 .tag_tooltip {
            right: 100%;
        }

        &.left100 .tag_tooltip {
            left: 100%;
        }

        &.bottom100 .tag_tooltip {
            bottom: 100%;
        }

        &.top100 .tag_tooltip {
            top: 100%;
        }
    }
}

.cta_look_collection {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 202px;
    margin: 80px auto;
}