$header-search-height: 124px; // Header search  height

/**
HEADER SEARCH FORM
 */
#form_recherche {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 0px;
    background-color: white;
    transition: all 0.3s ease-in-out;
    overflow: hidden;

    &.appear {
        height: $header-search-height;
        overflow: initial;
    }

    .wrap_search_field {
        position: relative;

        //permet contenir la barre de recherche
        @media screen and (max-width: 912px) {
            width: -webkit-fill-available;
        }
    }

    .wrap_field {
        position: relative;
        width: 970px;
        margin-top: 5px;
    }

    ::input-placeholder {
        font-style: normal;
        font-size: 50px;
        font-weight: 700;
        text-transform: uppercase;
        color: #000;
        letter-spacing: -0.13rem;
    }

    .closeBtn {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 15px;
        height: 15px;

        &:before,
        &:after {
            height: 15px;
            background-color: #999;
        }
    }

    ul#search_results {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 3rem;
            padding: 0 0.5rem;
            box-sizing: border-box;
            cursor: pointer;

            &:hover {
                background-color: #ccc;
                color: $white;
            }

            span + span {
                font-weight: 700;
                margin-left: 0.4rem;
            }
        }

        li.loader {
            background: transparent url(../svg/three_dots.svg) no-repeat center;
            background-size: auto 60%;
            cursor: default;

            span {
                font-size: 0;
            }
        }
    }
}

#search_home,
#search_home_noResult {
    width: 100%;
    height: 76px;
    text-indent: 0;
    border: 0;
    padding: 0;
    border-bottom: 1px solid $black;
    background-color: transparent;
    font-size: 50px;
    font-weight: 700;
    text-transform: uppercase;
    color: #000;
    letter-spacing: -0.13rem;

    &::placeholder,
    &::-webkit-input-placeholder {
        font-style: normal;
        font-size: 50px;
        font-weight: 700;
        text-transform: uppercase;
        color: #000;
        letter-spacing: -0.13rem;
    }

    &:focus {
        outline: none;
    }
}

.fullpage-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 600px;
    background: transparent url(../svg/three_dots_black.svg) no-repeat center;
    background-size: auto 30px;
}

.wrapperSearchNoResult {
    position: relative;
    width: 970px;
    height: $header-search-height;
    margin: 0 auto;

    .closeBtn {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 15px;
        height: 15px;

        &:before,
        &:after {
            height: 15px;
            background-color: #999;
        }
    }
}
