body.contact_faq {
    .contactForm {
        margin-top: 10px;

        h1 {
            font-family: $primaryFont;
            font-style: normal;
            font-weight: 500;
            font-size: 50px;
            line-height: 60px;
            text-transform: uppercase;
            margin-top: 18px;
        }

        .intro {
            display: none;
        }

        .wrapperContentFaqForm {
            .wrap_content {
                display: flex;
                justify-content: space-between;
                max-width: 1800px;
                width: 98%;
                margin: 0 auto;
                box-sizing: border-box;
                gap: 3%;
            }

            #block_img {
                width: 49%;
                max-width: 660px;

                @media screen and (max-width:1024px) {
                    width: 47%;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }

            #contact_form {
                width: 100%;
                max-width: calc(1040px - 1%);
                padding-top: 9px;

                @media screen and (max-width:1024px) {
                    width: 47%;
                }

                #mes_err_fieldset {
                    padding-top: 5%;
                }

                #form_contact1 {
                    padding-bottom: 0;
                    margin-top: 30px;

                    .w-form-line {
                        margin-bottom: 1.3rem !important;

                        .w-textarea {
                            .w-input-element {
                                height: 157px !important;
                                padding: 0.7em 1.05714em;
                            }
                        }

                        .w-textarea.w-nonempty {
                            .w-input-element {
                                padding: 1.15rem 1.05714em;
                            }
                        }

                        .w-submit-button {
                            width: 241px;
                            height: 46px;
                        }

                        .w-input-container {
                            .w-dropdown {
                                border: 1px solid $tertiary;
                                border-radius: 0;
                            }
                        }
                    }

                    .w-submit {
                        margin-bottom: 0 !important;
                    }

                    .file-input-row {
                        margin-left: 4px;

                        .w-form-line.column {
                            width: 162px;

                            label.ajout_pj_label {
                                border: 0 !important;
                                margin-left: -4px;
                                font-family: $primaryFont;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 19px;
                                text-transform: uppercase;
                                width: 162px;
                                position: relative;

                                &:after {
                                    position: absolute;
                                    content: "";
                                    display: block;
                                    top: 85%;
                                    height: 2px;
                                    left: 0;
                                    width: 100%;
                                    background: linear-gradient(to right, white 50%, black 50%);
                                    background-size: 200% 100%;
                                    background-position: right bottom;
                                    transition: all 0.5s ease-out;
                                }

                                &:hover:after {
                                    background-position: left bottom;
                                }
                            }
                        }

                        .w-form-line {
                            margin-bottom: 0.5rem !important;
                        }
                    }

                    #optinId {
                        .w-form-line {
                            .w-radio-group {
                                .w-radio-input {
                                    @media screen and (min-width: 1024px) and (max-width: 1300px) {
                                        margin-left: 0.6rem;
                                    }
                                }
                            }
                        }

                        .know-more {
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                }

                #mes_err_contact {
                    margin-top: 60px;
                }
            }
        }

        .w-lines-group {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            margin: 0.5rem 0 2.5rem;

            .w-fieldset-label {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                color: $black;
                letter-spacing: 0 !important;
                margin-bottom: 0.6rem;

                &::after {
                    content: " :";
                }
            }

            .w-form-line {
                width: auto;

                +.w-form-line {
                    margin-left: 3rem;

                    @media screen and (min-width: 1024px) and (max-width: 1300px) {
                        margin-left: 0.8rem;
                    }
                }
            }

            .w-line-label {
                width: auto;
                margin-right: 0.5rem;
            }

            .optinLightboxContact {
                color: $black;
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                margin-left: 2.5rem;

                @media screen and (min-width: 1024px) and (max-width: 1300px) {
                    margin-left: 0.6rem;
                }
            }
        }

        .champs_requis {
            font-family: $primaryFont;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            position: absolute;
        }
    }

    #recaptcha {
        position: relative;
        transform: scale(0.79);
        transform-origin: left top;
    }
}

.w-contact-form {
    .file-list {
        .rowList {
            button.closePj {

                &:before,
                &:after {
                    left: 0.35rem;
                    top: -0.12rem;
                }
            }
        }
    }
}

body.en.contact_faq {
    .contactForm {
        .wrapperContentFaqForm {
            #contact_form {
                #form_contact1 {
                    .file-input-row {
                        .w-form-line.column {
                            label.ajout_pj_label {
                                &:after {
                                    left: 7px;
                                    width: 91%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}