.store_locator {
    width: 100%;
    margin: 0 auto;

    .wrapper_store {
        max-width: 1800px;
        width: 98%;
        margin: 0 auto;

        h1 {
            span {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 500;
                font-size: 2.180rem;
                line-height: 3.2rem;
                text-transform: uppercase;
                padding-left: 0;
            }
        }     
    }

    .wrapper_retailers_map {
        margin-top: 1.2rem;
        width: 100%;

        @media screen and(max-width: 1400px) {
            margin-top: 1.5rem;
        }

        #retailers_map {
            width: 100%;
            max-width: 1800px;
            height: 438px;

            .gm-style-iw.gm-style-iw-c {
                width: 250px;
                height: 150px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;

                .info_popup {
                    padding-left: 1rem;

                    a {
                        color: $black;
                        font-family: $primaryFont;
                        font-style: normal;
                        text-decoration: none;
                    }

                    .name {
                        font-weight: 500;
                        font-size: 0.8rem;
                        line-height: 19px;
                        text-transform: uppercase;

                        &:focus {
                            border: none;
                        }
                    }

                    .adresse {
                        font-family: $primaryFont;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 16px;
                        margin-bottom: 0.5rem;
                    }

                    .cta_row {
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 16px;

                        a {
                            text-decoration: underline;
                            text-decoration-color: $black;

                            &+a {
                                margin-left: 1rem;
                            }
                        }
                    }
                }
            }
        }

        .storeloc_form {
            margin-top: 2rem;

            .title {
                display: none;
            }

            .wrap_fields {
                display: flex;
                justify-content: center;

                .field_country {
                    margin-right: 16px;
                }

                .field_country,
                .field_country select {
                    width: 150px;
                    height: 48px;

                    &.w-nonempty {
                        .w-value {
                            padding-top: 0;
                        }

                        .w-input-label {
                            display: none;
                        }
                    }
                }

                .field_address,
                .field_address input {
                    box-sizing: border-box;
                    width: 450px;
                    height: 48px;
                }

                .field_country,
                .field_address {
                    box-sizing: border-box;

                    select,
                    input {
                        box-sizing: border-box;
                        border: 1px solid $greyBorder;
                        border-radius: 0;
                        padding: 0;

                        &::focus {
                            border-radius: 0;
                        }
                    }

                    select {
                        appearance: none;
                        padding: 0 1em;
                    }
                }

                .field_address {
                    input {
                        padding-left: 16px;
                    }
                }

                div.ou {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 16px;
                    text-transform: uppercase;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 68px;
                    height: 48px;
                }

                button {
                    border-width: 0;
                    background-color: $black;
                    width: 16.875rem;
                    height: 48px;

                    span {
                        font-family: $primaryFont;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 16px;
                        text-transform: uppercase;
                        color: white;
                    }
                }
            }

            .filtres_wrapper {
                display: flex;
                width: 954px;
                margin: 24px auto 0;

                span.filtre {
                    display: flex;

                    input[type=checkbox] {
                        display: none;

                        &:checked+label:before {
                            opacity: 1;
                            background-color: black;
                            box-shadow: inset 0 0 0 .1em #fff;
                            transition: background-color 0.2s ease-in-out;
                        }
                    }

                    label {
                        cursor: pointer;
                        position: relative;

                        span {
                            font-family: $arial;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 21px;
                            margin-right: 33px;
                            margin-left: 20px;
                        }

                        &:before {
                            content: "";
                            position: absolute;
                            width: 12px;
                            height: 12px;
                            border: 1px solid black;
                            opacity: 0.5;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                            transition: background-color 0.2s ease-in-out;
                        }
                    }
                }
            }
        }

        .liste_recherche_distributeur {
            .resultat_recherche {
                display: none !important;
            }
        }
    }

    #liste_distributeur {
        width: 100% !important;
        max-width: 1280px !important;
        height: auto !important;
        margin: 0 auto;
        margin-top: 2.4875rem;
        display: grid;
        grid-template-columns: repeat(auto-fill, 302px);
        grid-gap: 24px;
        justify-content: center;

        .elem_liste_distributeur {
            width: 302px;
            border: 1px solid $black;
            padding: 24px;
            box-sizing: border-box;
            border-radius: 2px;

            .nom_store {
                width: 90%;
                position: relative;

                .title {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1rem;
                    line-height: 19px;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    color: $black;
                    position: relative;
                    top: -3px;
                    left: -0.5px;
                    width: 150px;
                }

                .statut {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 12px;
                    letter-spacing: -0.7px;
                    position: absolute;
                    right: -25px;
                    top: 2px;
                }
            }

            .content_store {
                .detail_store {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    color: $black;

                    div {
                        display: inline-block;

                        &.rue_store {
                            margin-top: 6px;
                        }

                        &.ville_store {
                            margin-left: 3px;
                        }

                        &.pays_store {
                            display: none;
                        }
                    }

                    .phone_store {
                        display: none;
                    }

                    .horaire_today {
                        text-align: left;
                        display: block;
                        color: $red;

                        span {
                            color: $black;
                        }

                        .puce {
                            display: inline-block;
                            margin-top: 3px;
                            margin-right: 6px;

                            +span {
                                color: $red;
                            }

                            &::before {
                                content: ' ';
                                display: inline-block;
                                width: 8px;
                                height: 8px;
                                border-radius: 50%;
                                background-color: $red;
                            }

                            &.store_open {
                                &::before {
                                    background-color: $green;
                                }

                                +span {
                                    color: $green;
                                }
                            }
                        }

                        .txt_store_open {
                            display: inline;

                            span {
                                color: $greenOpen;
                            }
                        }
                    }
                }

                .store_more {
                    margin-top: 7px;

                    a {
                        font-family: $primaryFont;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 16px;
                        color: $black;
                    }
                }
            }
        }
    }
}

body.satellite_store_details {
    .masgasin_pages {
        width: 100%;
        min-width: 1007px;
        box-sizing: border-box;

        .locator_heading {
            font-family: $primaryFont;
            font-style: normal;
            font-weight: 500;
            font-size: 35px;
            line-height: 42px;
            text-transform: uppercase;
            width: 98%;
            max-width: 1800px;
            margin: 5px auto 22px;
            padding-left: 0.6%;

            .title {
                margin-left: -4px;
            }

            h1 {
                display: none;
            }
        }

        .return_btn {
            font-family: $primaryFont;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            display: block;
            cursor: pointer;
            width: 98%;
            max-width: 1800px;
            margin: 0 auto 20px;

            span {
                display: inline-block;
                background-color: $black;
                width: 14px;
                height: 14px;
                mask-image: url(../svg/spritesheet.svg);
                @include maskIconCoord(25, 6, 6);
                margin-right: 15px;
                position: relative;
                top: 1px;
            }
        }

        .detailStoreWrapper {
            display: flex;
            min-height: 438px;
            height: auto;
            flex-shrink: 0;
            width: 98%;
            max-width: 1800px;
            margin: 0 auto;

            #blocimg {
                img {
                    width: 100%;
                    height: auto;
                    min-height: 430px;
                    object-fit: cover;
                }
            }

            #distribute_section_a {
                width: 37.262%;
                max-width: 628px;
                display: flex;
                flex-wrap: wrap;

                .distribute_info {
                    display: flex;
                    padding-top: 1.9021vw;
                    padding-left: 6.57%;
                    box-sizing: border-box;
                    width: 100%;

                    .store_name_title {
                        font-family: $primaryFont;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 28.8px;
                        text-transform: uppercase;
                    }

                    .main_info_container {
                        width: 50%;
                        padding-right: 0.6rem;
                        box-sizing: border-box;

                        div {
                            span {
                                font-size: 10px;
                                line-height: 12px;
                                font-weight: 500;
                            }
                        }
                    }

                    .distribute_adress {
                        margin-top: 0.8rem;
                        margin-bottom: 0.4rem;

                        p {
                            font-family: $primaryFont;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 15.6px;
                            display: inline-block;
                        }
                    }

                    .openUntil,
                    .store_close {
                        font-family: $primaryFont;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 16px;
                        margin-top: 0.5rem;
                        margin-right: 0.45rem;

                        span {
                            display: inline-block;
                            font-family: 'Graphik';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 16px;
                            color: $green;
                            text-transform: capitalize;

                            &:before {
                                content: '';
                                display: inline-block;
                                width: 7px;
                                height: 7px;
                                background: $green;
                                border-radius: 50%;
                                margin-right: 0.5rem;
                            }
                        }

                        span:first-child {
                            display: none;
                        }
                    }

                    .store_close {
                        color: $red;

                        span {
                            color: $red;
                            margin-right: 6px;

                            &:before {
                                content: '';
                                background: $red;
                            }
                        }
                    }

                    .scheduleTitle {
                        margin-top: 0.2rem;
                        margin-left: -1px;
                        font-family: $primaryFont;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 15.6px;
                        text-transform: uppercase;

                        p {
                            font-weight: 500;
                        }
                    }

                    .schedule {
                        margin-top: -0.5rem;
                    }

                    .time_table {
                        width: 100%;
                        margin-top: 1.6rem;

                        tr {
                            display: flex;

                            &:not(:first-child) {
                                margin-top: 0.5rem;
                            }

                            &:last-of-type {
                                padding-bottom: 0.5rem;
                            }
                        }

                        td {
                            width: 25%;
                            font-family: $primaryFont;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 15.6px;
                            display: flex;
                            align-items: center;

                            &+td {
                                width: 75%;
                                display: inline-block;
                                text-align: center;
                                margin-right: 1.7rem;

                                @media screen and (max-width: 1630px) {
                                    text-align: right;
                                }
                            }
                        }
                    }

                    .holiday {
                        font-size: 12px;
                        margin-top: .5rem;

                        &:last-child {
                            margin-bottom: 1.1rem;
                        }
                    }

                    &:nth-child(2) {
                        padding-top: 0vw;

                        .horaire_container {
                            width: 50%;
                        }

                        .service_container {
                            width: 50%;
                            margin-right: 0.5rem;

                            .scheduleTitle {
                                margin: 0.2rem 0 1.1rem;
                            }

                            .store_services {
                                a {
                                    text-decoration: none;
                                    color: $black;
                                }

                                p {
                                    margin-top: 8px;
                                    font-size: 13px;
                                    line-height: 15.6px;
                                }
                            }
                        }
                    }

                    @media screen and (max-width: 1630px) {
                        flex-direction: column;
                        width: 100%;
                        padding-right: 6.37%;
                        padding-top: 2vw;

                        .main_info_container {
                            width: 100%;
                        }

                        .btn_store_container {
                            margin-top: 1.8rem;
                            padding-left: 0;
                            padding-right: 0;
                        }

                        &:nth-child(2) {
                            .horaire_container {
                                margin-top: 1.2rem;
                                width: 100%;

                                .schedule {
                                    .time_table {
                                        tr {
                                            td+td {
                                                margin-right: unset;
                                            }
                                        }
                                    }
                                }
                            }

                            .service_container {
                                margin-top: 1.2rem;
                                width: 100%;
                            }
                        }
                    }
                }

                .btn_store_container {
                    width: 50%;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: flex-start;
                    justify-content: center;
                    margin: 0.2rem 0.7rem 1.5rem 0;

                    @media screen and (max-width: 1630px) {
                        position: relative;
                        width: 100%;
                        margin: 0 auto;
                        padding-left: 6.37%;
                        padding-right: 6.37%;
                        justify-content: space-between;
                    }

                    .btn_container {
                        width: 261px;
                        height: 46px;
                        background-color: $black;
                        margin-left: -1.8rem;

                        @media screen and (max-width: 1630px) {
                            margin-left: 0;
                            width: 48% !important;
                        }

                        &:first-child {
                            margin-bottom: 10px;
                        }

                        &:hover a {
                            background-color: $white;

                            span p {
                                color: $black;
                            }
                        }

                        a {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-decoration: none;

                            span p {
                                font-family: $primaryFont;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 16px;
                                display: flex;
                                align-items: center;
                                text-align: center;
                                text-transform: uppercase;
                                color: $white;
                            }
                        }

                        &+.btn_container {
                            background-color: $white;
                            width: 261px;
                            height: 46px;

                            @media screen and (max-width: 1630px) {
                                margin-left: 0;
                            }

                            a span p {
                                color: $black;
                                margin: 0 1rem;
                            }

                            &:hover a {
                                background-color: $black;

                                span p {
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }

            #distribute_section_b {
                flex-grow: 1;

                #store_detail_map {
                    width: 100%;
                    height: 100%;
                }
            }
            
            &+.detailStoreWrapper {
                margin-top: 75px;
                justify-content: space-between;
                
                #bloc_description {
                    width: 100%;
                    max-width: 624px;
                    height: auto;
                    padding-right: 2%;

                    .store_title,
                    .store_subtitle {
                        display: inline;
                        font-size: 24px;
                        line-height: 28.8px;
                        font-weight: 500; 
                    }

                    .store_desciption {
                        margin-top: 10px;

                        .txt {
                            font-size: 13px;
                            line-height: 15.6px;

                            .read_more,
                            .read_less {
                                position: relative;
                                display: inline-block;
                                font-size: 16px;
                                font-weight: 500;
                                line-height: 19.2px;
                                text-transform: uppercase;
                                cursor: pointer;
                                margin-top: 10px;

                                .underline {
                                    background-color: $black;
                                    display: block;
                                    height: 1px;
                                    position: absolute;
                                    top: 100%;
                                    left: 0;
                                    right: 0;
                                    transition: left 0.2s ease-in-out;
                                }

                                &:hover span {
                                    left: 100%;
                                }
                            }

                            &.trimed {
                                display: none;
                            }

                            &.trimed.displayed {
                                display: block;
                            }

                            &.full.cache.displayed {
                                display: block;
                            }
                        }
                    }
                }

                #blocimg {
                    max-width: 1040px;
                    height: auto;
                }
            }
        }

        #nearby_stores {
            margin: 72px auto 80px;
            max-width: 1281px;

            .nearby_title {
                font-size: 35px;
                line-height: 42px;
                font-weight: 500;
                text-transform: uppercase;
                margin: 0 0 33px;

                @media screen and(max-width: 1024px) {
                    text-align: center;
                }
            }

            #nearby_lists {
                .swiper-wrapper {
                    max-width: 1280px !important;
                    display: grid;
                    grid-template-columns: repeat(auto-fill, 302px);
                    grid-gap: 24px;
                    justify-content: left;

                    @media screen and(max-width: 1024px) {
                        justify-content: center;
                    }

                    .swiper-slide {
                        max-width: 302px;
                        height: auto;
                        border: 1px solid $black;
                        margin-right: 1.5rem;
                        box-sizing: border-box;

                        .elem_liste_distributeur {
                            .elem_list_contents {
                                padding: 23px;
                                box-sizing: border-box;

                                .nom_store {
                                    width: 90%;
                                    position: relative;

                                    .title {
                                        font-family: $primaryFont;
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 1rem;
                                        line-height: 19px;
                                        display: flex;
                                        align-items: center;
                                        text-transform: uppercase;
                                        color: $black;
                                        position: relative;
                                        top: -3px;
                                        left: -.5px;
                                        width: 150px;
                                    }

                                    .statut {
                                        font-family: $primaryFont;
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 10px;
                                        line-height: 12px;
                                        letter-spacing: -.7px;
                                        position: absolute;
                                        right: -25px;
                                        top: 2px;
                                    }
                                }

                                .content_store {
                                    margin-top: 11px;
                                    font-size: 13px;
                                    font-weight: 400;
                                    line-height: 15.6px;

                                    .detail_store {
                                        .rue_store, 
                                        .ville_store {
                                            display: inline-block;
                                        }
                                        
                                        .pays_store {
                                            display: none;
                                        }

                                        .horaire_today {
                                            span:nth-child(2) {
                                                display: block;

                                                .txt_store_open {
                                                    color: $greenOpen;

                                                    &::before {
                                                        content: ' ';
                                                        display: inline-block;
                                                        width: 7px;
                                                        height: 7px;
                                                        border-radius: 50%;
                                                        background-color: $greenOpen;
                                                        transform: translateY(-1px);
                                                    }
                                                }

                                                .txt_store_closed {
                                                    color: $red;

                                                    &::before {
                                                        content: ' ';
                                                        display: inline-block;
                                                        width: 7px;
                                                        height: 7px;
                                                        border-radius: 50%;
                                                        background-color: $red;
                                                        transform: translateY(-1px);
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .store_more {
                                        margin-top: 13px;
                                        
                                        a {
                                            color: $black;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @media screen and(max-width: 1354px) {
                padding: unset;
                max-width: 670px;
                margin: 72px auto 80px;

                #nearby_lists {
                    .swiper-wrapper {
                        max-width: 950px !important;
                    }
                }
            }
        }

        #store_gondola {
            #wrapper_products_store {
                #title_products_store {
                    font-weight: 500;
                    font-size: 35px;
                    line-height: 42px;
                    width: 98%;
                    max-width: 1800px;
                    margin: 0 auto 18px;
                }

                #wrapper_visus_store {
                    display: flex;

                    .swiper-wrapper {
                        .item.swiper-slide {
                            padding-bottom: 30px;
    
                            .imgWrapper {
                                margin-bottom: 1px;
                                max-height: 520px;
                                height: 100%;
    
                                .swiper-container {
                                    .swiper-wrapper {
                                        max-height: 520px;

                                        .swiper-slide {
                                            .block_lnk {
                                                padding-top: calc(613 / 447 * 100%);
                                            }                                            
                                        }
                                    }

                                    .rollover_rayonsNext,
                                    .rollover_rayonsPrev  {
                                        display: none;
                                    }
                                }
    
                                .wrap_rolloverproduit {
                                    padding-bottom: 3%;
    
                                    .rollover_left  {
                                        .productColorFieldset {
                                            .right_element_prod  {
                                                margin-left: 6px;
                                            }
                                        }
                                    }

                                    .btnAddBasketWrapper {
                                        min-height: 64px;
                                        margin-top: unset;
                                        background: $white;

                                        #size_is_not_available {
                                            margin-top: 24px;
                                            font-size: .9rem;
                                            text-align: center;
                                        }
                                    }
    
                                    .form_submit.bloc_add_color  {
                                        height: 26px; 

                                        .button.w-submit-button:after {
                                            top: -3px;
                                        }
                                    }

                                    @media screen and(max-width: 1024px) {
                                        .rollover_left  {
                                            .productSizeFieldset  {
                                                .size_list  {
                                                    margin-top: 2px;
                                                }
                                            }
                                        }

                                        .form_submit.bloc_add_color  {
                                            .button.w-submit-button:after {
                                                top: -2px;
                                            }
                                        }
                                    }                                    
                                }
                            }
    
                            .blockInfosProd {                            
                                .wrapper_description {
                                    padding-left: 6px;
                                    margin-top: 5px;
                                    
                                    .price_n_eclat_rect_wrapper {
                                        display: flex;
                                        align-items: center;
                                        margin-top: 4px;
                                        font-size: 13px;
                                        font-weight: 500;

                                        @media screen and(max-width: 1024px) {
                                            .pricetag:nth-child(2) {
                                                padding-top: 2px;
                                            }
                                        }

                                        .eclat_rect {
                                            margin-left: 11px;
                                            position: relative;
                                            top: 2px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .prevRange,
                    .nextRange  {
                        position: absolute;
                        z-index: 1;
                        top: 42%;
                        padding: 0  1.4rem;

                        &::before {
                            width: 30px;
                            height: 30px;
                            cursor: pointer;
                        }

                        &.swiper-button-disabled {
                            opacity: 0.2;
                        }
                    }

                    .prevRange {
                        left: 0;
                        padding: 0 2.3rem;
                        
                        &::before {
                            transform: rotate(180deg);
                        }  
                    }

                    .nextRange  {
                        right: 0;
                    }
                }
            }
        }
    }

    .product_associations_wrapper.gondole {
        display: none;
    }

    #ligthbox_send_store_information {
        height: 300px;

        p.light_title {
            width: 100%;
            margin-top: 2.25rem;
            margin-bottom: 2rem;
        }

        .input_bloc {
            width: 76%;
            margin: 0 auto;

            p {
                margin-bottom: 1.25rem;
            }

            .input_label {
                margin-bottom: 0.5rem;
            }

            .w-form-line.w-submit {
                display: flex;
                justify-content: center;
                width: 100%;
                max-width: 100%;

                .w-btn-wrapper {
                    max-width: 15.875rem;
                }
            }
        }
    }
}