/* PARRAINAGE */
// Page Connecter
body.parrainage_index {
    .wrapper_moncompte {
        .wrapper_menu_compte {
            a {
                &:last-child {
                    margin-right: -2px;

                    #number_wishlist_top {
                        display: none;
                    }
                }
            }
        }

        #content_parrainage {
            max-width: 1280px;
            width: 98%;
            margin: -15px auto;

            .header_parrainage {
                display: flex;
                margin-bottom: -7px;

                h3 {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                }

                p {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    margin-top: 9px;
                }

                .texte_1 {
                    width: 479px;
                }

                .texte_2 {
                    width: 509px;
                    margin-left: 80px;
                }
            }

            #parrainage_form {
                padding-top: 45px;

                .bloc_parrainage_form {
                    margin-bottom: 130px;

                    @media only screen and (max-width: 1360px) {
                        padding-right: 20px;
                    }

                    .w-form-line {
                        &.column {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            margin-bottom: 14px;

                            .w-input-container {
                                width: 32.4%;
                                max-width: 410.67px;

                                input {
                                    height: 51px;
                                    border: 1px solid $grey01;
                                    font-size: 14px;
                                    color: $black;
                                }

                                label.w-has-error {
                                    margin-bottom: 28px;
                                }

                                small.w-input-note {
                                    top: calc(100% - 28px);
                                }
                            }

                            .remove_filleul {
                                right: -20px;
                                top: 15px;
                            }

                            &+.column {
                                margin-top: 20px;
                            }
                        }

                        &.w-submit {
                            justify-content: flex-end;
                            max-width: inherit;
                            padding-right: 9px;
                            margin-top: 0.5rem;

                            .w-submit-button {
                                background: transparent;
                                border: none;
                                height: auto;
                                line-height: 1;
                                color: $primaryColor;
                                display: inline-block;
                                width: auto;
                                padding: 0;
                                text-transform: inherit;

                                span {
                                    position: relative;
                                    font-family: $primaryFont;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 19px;
                                    text-transform: uppercase;

                                    &::after {
                                        content: "";
                                        position: absolute;
                                        display: block;
                                        top: 106%;
                                        left: 0;
                                        right: 0;
                                        height: 1px;
                                        background-color: #000;
                                        transition: left .2s ease-in-out;
                                    }
                                }

                                &:hover span:after {
                                    left: 100%;
                                }
                            }
                        }
                    }
                }

                .elem_parrainage {
                    position: relative;
                    top: 2rem;

                    .bloc_parrainage_textarea {
                        .label_parrainage {
                            font-family: $primaryFont;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 16px;
                            display: flex;
                            align-items: center;
                            text-transform: uppercase;
                        }
                    }

                    .w-form-line {
                        margin-bottom: 1.7rem !important;

                        .w-input-container {
                            label.w-input.w-textarea.w-nonempty {
                                textarea.w-input-element {
                                    height: 10em !important;
                                    font-family: "Arial";
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 21px;
                                    color: #989898;
                                    padding-top: 1em !important;
                                }

                                p {
                                    display: none;
                                }
                            }
                        }

                        .w-submit-button,
                        button.w-submit-button {
                            margin-bottom: -5px;
                            width: 235px;
                            height: 46px !important;
                        }

                        &:last-of-type {
                            margin-bottom: -5px;
                        }
                    }
                }
            }

            .error_parrainage {
                position: relative;
                bottom: 341px;
            }

            .offre {
                width: 576px;
                position: relative;

                span {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 12px;
                    position: absolute;
                    bottom: 350px;
                }
            }

            .parrainer_tab {
                padding-top: 52px;
                padding-bottom: 9px;
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                display: flex;
                align-items: center;
                text-transform: uppercase;
            }

            .text_parrainage {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
            }

            #bloc_tableau_parrainage {
                margin-top: 25px;
                text-align: justify;
                min-height: 6rem;

                #orders_by_type {
                    color: $white;
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 35px;
                    max-width: 1280px;
                    width: 100%;
                }

                #no_filleul {
                    margin-top: 20px;
                    padding: 0 15px;
                }

                thead {
                    tr {
                        th {
                            max-width: 1280px;
                            width: 259px;
                            color: $secondaryColor;
                            background-color: $black;
                            font-weight: 400;
                            font-size: 13px;
                            padding: 10px 17px;
                            line-height: 17px;

                            &:nth-child(2),
                            &:nth-child(5) {
                                width: 17.5%;
                            }

                            &:nth-child(3) {
                                width: 23%;
                            }

                            &:nth-child(4) {
                                width: 16.8%;
                            }

                            &:last-child {
                                width: 5%;
                                text-align: end;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        border-bottom: 1px solid $grey01;

                        td {
                            color: $black;
                            font-family: $primaryFont;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 52px;
                            padding: 0 17px;
                            vertical-align: middle;
                            width: 212px;
                            height: 52px;

                            &:nth-child(2) {
                                line-height: 21px;
                            }

                            &:last-child {
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }

    // page deconnecter
    .wrapper_moncompte {
        max-width: 1800px;

        .title_parrainage {
            padding-top: 3px;

            h1 {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 500;
                font-size: 50px;
                line-height: 60px;
                text-transform: uppercase;
                width: 797px;

                span {
                    font-style: italic;
                    font-weight: 400;
                    font-family: $secondaryFont;
                }
            }
        }

        .page_parrinage {
            display: flex;
            justify-content: space-between;
            margin-top: -60px;

            .left_content_parrainage {
                width: 58%;
                padding-top: 6.2rem;

                .subtitle_parrainage {
                    h2 {
                        font-family: $primaryFont;
                        font-weight: 500;
                        font-size: 40px;
                        line-height: 42px;

                        span {
                            font-style: italic;
                            font-weight: 400;
                            font-family: $secondaryFont;
                        }
                    }

                    p {
                        font-family: $primaryFont;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 16px;
                        margin-top: 31px;
                    }
                }

                .button {
                    margin-top: 42px;

                    .parraine,
                    .offrez {
                        width: 160px;
                        height: 46px;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 16px 32px 14px;
                        gap: 10px;
                        background-color: $black;
                        color: $white;
                        cursor: pointer;


                        span {
                            font-family: $primaryFont;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 16px;
                            display: flex;
                            align-items: center;
                            text-align: center;
                            text-transform: uppercase;
                        }

                        a {
                            text-decoration: none;
                            color: $white;
                        }

                        &:hover {
                            background-color: $white;
                            border: 1px solid $black;
                            transition: background-color .2s ease-out;

                            a {
                                color: $black;
                            }
                        }
                    }

                    .offrez {
                        width: 217px;
                    }
                }

                .header_parrainage {
                    display: flex;
                    margin-top: 38px;

                    h3 {
                        font-family: $primaryFont;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 16px;
                        display: flex;
                        align-items: center;
                        text-transform: uppercase;
                    }

                    p {
                        font-family: $primaryFont;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 16px;
                        margin-top: 9px;
                    }

                    .texte_1 {
                        width: 479px;
                    }

                    .texte_2 {
                        width: 509px;
                        margin-left: 80px;
                    }
                }

                .offre {
                    width: 576px;
                    margin-top: 16px;

                    span {
                        font-family: $primaryFont;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 12px;
                    }
                }
            }

            .rigth_content_parrainage {
                width: 25.6%;
                margin-left: 69px;
                margin-top: 60px;

                img {
                    width: 100%;
                }
            }
        }
    }
}