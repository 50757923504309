body.faq {

    // GLOBAL **********************************************/
    .w-submit-button {
        height: 3.57143em;
    }

    /*******************************************************/

    .main_ariane {
        .breadcrumbs {
            .breadcrumb {
                &:nth-child(2) {

                    a,
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    .faq_reponse {
        strong {
            font-weight: 600;
        }

        table {
            min-width: 35rem;

            tr {
                border: 1px solid #ccc;

                td {
                    padding: .5rem;
                    border: 1px solid #ccc;
                    min-width: 2rem;
                }
            }
        }
    }

    .faqWrapper {
        width: 100%;
        max-width: 1920px;
        margin-bottom: -30px;

        .flexAccount {
            h1 {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 500;
                font-size: 50px;
                line-height: 60px;
                text-transform: uppercase;
                max-width: 1800px;
                width: 98%;
                margin: 3px auto;
            }

            .subtitleFaq,
            .site_search,
            .theme_menu {
                display: none;
            }

            .wrapper_moncompte_main {
                width: 100%;
                max-width: 1920px;

                .faq_content {
                    width: 100%;

                    .theme_global {
                        display: flex;
                        position: relative;
                        margin-top: 2.03%;
                        width: 100%;

                        .theme {
                            position: relative;
                            cursor: pointer;

                            img {
                                width: 100%;
                            }

                            span {
                                position: absolute;
                                width: 100%;
                                color: $white;
                                top: 76%;
                                font-family: $primaryFont;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 24px; // Wide screen (>1920px) font-size
                                line-height: 1.2;
                                text-align: center;
                                text-transform: uppercase;
                                box-sizing: border-box;

                                // This is the right font-size, 24px is for wide screens but it's easier to declare it that way
                                @media screen and (max-width: 1920px) {
                                    font-size: 1.25vw;
                                }
                            }
                        }
                    }

                    #theme_page {
                        .theme_detail {
                            width: 98%;
                            max-width: 1440px;
                            margin: 34px auto 0;
                            position: relative;

                            .faq_element {
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 0;
                                overflow: hidden;

                                .faq_title {
                                    font-family: $primaryFont;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 40px;
                                    line-height: 48px;
                                    display: none;
                                    opacity: 0;
                                }

                                &.active {
                                    position: relative;
                                    height: auto;
                                    overflow: inherit;

                                    .faq_title {
                                        display: block !important;
                                        opacity: 1;
                                    }
                                }
                            }

                            .faq_questions {
                                display: none;
                            }

                            .faq_question {
                                padding: 22px 0 12px;
                                display: flex;
                                justify-content: space-between;
                                border-bottom: 1px solid $grey;
                                box-sizing: border-box;
                                cursor: pointer;

                                h3 {
                                    font-family: $primaryFont;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 13px;
                                    line-height: 16px;
                                }

                                &::after {
                                    transition: transform 0.3s ease-out;
                                    content: " ";
                                    display: block;
                                    width: 30px;
                                    height: 30px;
                                    background-image: url(../svg/spritesheet.svg);
                                    @include bgIconCoord(14, 6, 6);
                                    transform: rotate(90deg);
                                }

                                &.active {
                                    border-bottom: 0 none;

                                    &:after {
                                        transform: rotate(270deg);
                                    }
                                }
                            }

                            .faq_reponse {
                                display: none;
                                font-family: $primaryFont;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 16px;
                                width: 100%;
                                border-bottom: 1px solid $grey;
                                width: 86%;

                                strong {
                                    font-weight: 600;
                                }

                                table {
                                    min-width: 35rem;

                                    tr {
                                        border: 1px solid #ccc;

                                        td {
                                            padding: .5rem;
                                            border: 1px solid #ccc;
                                            min-width: 2rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}