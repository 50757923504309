/**
 * Fake search input on top of search results
 */
.topSearchResults {
    position: relative;
    justify-content: flex-start;
    align-items: baseline;
    width: 970px;
    height: 76px;
    margin: 5px auto 0;
    border-bottom: 1px solid #000;
    font-size: 50px;
    font-weight: 500;
    line-height: 1.3;
    text-transform: uppercase;
    color: #000;
    letter-spacing: -.13rem;

    .nbr_result {
        font-size: 24px;
        margin-left: .8rem;
        letter-spacing: 0;
    }

    &:before {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

.dynasearchwrapper {

    /**
     * Filter colors
     */
    .filter_wrapper#filter_color {
        .form_itm.check.color {
            width: 105px;
        }

        input[type=checkbox] {
            +label {
                width: 16px;
                height: 16px;
                border: 1px solid #000;
                border-radius: 50%;
                margin-right: 16px;
                box-shadow: inset 0 0 0 0 #fff;
                transition: all .2s ease-in-out;

                span {
                    position: relative;
                    line-height: 1.2;
                    margin-left: 32px;

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 0;
                        height: 1px;
                        background: #000;
                        transition: width .2s ease-in-out;
                    }
                }
            }

            +label:hover,
            &:checked+label {
                span:after {
                    width: 100%;
                }
            }
        }
    }

    /**
     * Filter price range
     */
    .slider-range-container {
        width: 80%;
    }

    #sort_options {
        input[type="radio"] {
            display: none;

            +label {
                position: relative;
                font-size: 13px;
                font-weight: 500;
                color: #000;
                text-transform: uppercase;
                text-decoration: none;
                white-space: nowrap;
                margin-bottom: 16px;

                &:hover {
                    cursor: pointer;
                }

                span {
                    position: relative;

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 0;
                        height: 1px;
                        background: #000;
                        transition: width .2s ease-in-out;
                    }
                }
            }

            &:checked+label span:after,
            +label:hover span:after {
                width: 100%;
            }
        }
    }

    /**
     * productvisu wrapper
     */
    .search_product {
        background-repeat: no-repeat;
        background-size: 100% auto;
    }

    a.block_lnk {
        max-width: 447px;
    }

    /**
     * Hide wishlist button
     */
    button.load_product {
        display: none;
    }

    #list_item {
        .productSwiper.doubleCol {
            max-width: 896px;

            .swiper-lazy-preloader span { 
                display:none;
            }
    
            
        }

        .item {
            &:nth-child(22n + 11),
            &:nth-child(22n + 20) {
                .productSwiper {
                    .productBigPictures2 {
                        .swiper-slide {
                            max-width: 896px;
            
                            a.block_lnk {
                                justify-content: center; 
                                padding-top: 0 !important;
                                max-width: 100%;

                                > div {
                                    display: flex;
                                }
    
                                .imgProd {
                                    position: inherit;
    
                                    + .imgProd {
                                        opacity: 1;
                                        position: inherit;
                                    }
                                }
                            }
                        }
                    }                    
                }
                
                .productSwiper:has(.productBigPictures2) {
                    .blackarrow {
                        display: none;
                    }
                }
            }

            .imgWrapper {
                height: 100%;

                .productSwiper {
                    height: 100%;
                }
            }
        }

        .wrap_rolloverproduit {
            .productColorFieldset {
                .form_itm.color {
                    label {
                        background-size: unset;
                        background-position: center;
                    }
                }
            }
        }
    }
    
}

/**
 * Price range slider (different from category)
 */
.vue-slider {
    width: 100% !important;
    padding: 7px 0px 0px !important;
}

#filter_price {
    .selected_options {
        display: flex;
        align-items: flex-start;
        width: 210px;

        .slider-range-container {
            margin: 0 10px;
        }

        .vue-slider-dot-tooltip.vue-slider-dot-tooltip-bottom.vue-slider-dot-tooltip-show {
            position: unset;
            transform: none;
            width: 10%;
            display: flex;
            justify-content: flex-start;

            &:first-of-type {
                justify-content: flex-end;
                margin-left: 5%;
            }
        }
    }
}

.slider-range-container .vue-slider-rail .vue-slider-dot {
    border-radius: 0;
    background: #000;
    cursor: pointer;
    opacity: 0;

    .vue-slider-dot-tooltip-bottom {
        transform: translateY(50%);
    }
}

.slider-range-container .vue-slider-rail .vue-slider-process {
    height: 3px !important;
    top: -1px !important;
}

.slider-range-container .vue-slider-rail {
    width: 100% !important;
}

.vue-slider-dot-tooltip-text {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    &:after {
        content: ' €';
    }
}

/**
 * No results search
 */
body {
    .no_results_wrapper {
        width: 98%;
        max-width: 1800px;
        margin: 0 auto;
        min-height: 500px;
    }

    .searchResults,
    .no_results_wrapper {
        padding-top: 115px;
    }
}

.no_result_tips {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 98%;
    max-width: 1440px;
    margin: 80px auto;

    @media screen and(max-width: 1400px) {
        justify-content: space-between;
    }

    .title_no_result {
        font-size: 24px;
        font-weight: 500;
        line-height: 29px;

        span {
            display: block;
        }
    }

    .research_tips {
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        margin: 7px 0 0 13.5%;

        @media screen and(max-width: 1400px) {
            margin: 7px 0 0 0;
        }

        ul {
            list-style-type: none;
            list-style-position: outside;
            margin-left: 20px;

            li {
                position: relative;

                &:before {
                    content: '•';
                    position: absolute;
                    left: -13px;
                }
            }
        }
    }
}

// Enable click on product video after search 
#site_global_wrap {
    .dynasearchwrapper {
        #search_page {
            .page_rayon.searchResults {
                #content_obj {
                    .imgWrapper {
                        .link_product_iframe {
                            width: 100% !important;
                            height: 100% !important;
                            position: absolute;
                            top: 0;

                            iframe {
                                z-index: -1;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Enable click on product video after search 