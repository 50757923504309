.w-btn-wrapper,
.form_submit,
.btn_container {
    position: relative;
    height: 46px;

    button,
    .button {
        width: 100%;
        height: 100%;
    }
}

.w-submit-button,
button.w-submit-button,
.button.dark,
.button.primary {
    height: 100%;
    background: $primaryColor;
    border: 1px solid $primaryColor;
    font-family: $primaryFont;
    font-size: 13px;
    font-weight: 500;
    color: $secondaryColor;
    text-transform: uppercase;
    text-decoration: none;
    box-sizing: border-box;
    line-height: 0.9;

    &:not(.w-loader):hover {
        background: $secondaryColor;
        color: $primaryColor;
        cursor: pointer;
        transition: background-color 0.5s ease-out;
    }

    &.w-loader {
        background: $secondaryColor url(../svg/three_dots_black.svg) no-repeat center;
        background-size: auto 22%;
        border: 1px solid $primaryColor;
        cursor: default;
    }

    + .loader {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: -5;
        padding: 0;
        background: $secondaryColor url(../svg/three_dots_black.svg) no-repeat center;
        background-size: auto 22%;
        border: 1px solid $primaryColor;
        font-size: 0;
        cursor: default;

        &.loading {
            opacity: 1;
            z-index: 2;
        }
    }
}

.button.light,
.button.secondary,
button.w-submit-button.w-secondary-btn {
    background: $secondaryColor;
    border: 1px solid $primaryColor;
    font-family: $primaryFont;
    font-size: 13px;
    font-weight: 500;
    color: $primaryColor;
    text-transform: uppercase;
    text-decoration: none;
    box-sizing: border-box;
    line-height: 0.9;

    &:hover {
        background: $primaryColor;
        color: $secondaryColor;
        cursor: pointer;
    }

    &.w-loader {
        background: $primaryColor url(../svg/three_dots.svg) no-repeat center;
        background-size: auto 22%;
        border: 1px solid $primaryColor;
        cursor: default;
    }

    + .loader {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: -5;
        padding: 0;
        background: $primaryColor url(../svg/three_dots.svg) no-repeat center;
        background-size: auto 22%;
        border: 1px solid $primaryColor;
        font-size: 0;
        cursor: default;

        &.loading {
            opacity: 1;
            z-index: 2;
        }
    }
}
