.choix_type_adresse {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1.6rem;

    .type_adresse.actif {
        color: #ffffff;
        background: #000000;
    }

    .type_adresse {
        padding: 3px 8px 4px;
        border: 1px solid #000000;
        font-weight: 400;
        font-size: 14px;
        font-family: Graphik;
        text-transform: uppercase;
        color: #1b1c1e;
        display: inline-block;
        margin-right: 24px;
        cursor: pointer;
    }

}

.w-form-line {
    &.addrType {
        margin-bottom: 2.8rem;
    }

    &.addrCivility {
        margin-bottom: 1.8rem;
    }

    &.addrSubmit {
        width: 118px !important;
        height: 46px;
    }
}


.w-input-group {
    margin-bottom: 0px;

    .w-group-label {
        text-transform: uppercase;
    }

    .w-input {
        img.valid_form {
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
        }
    }
}


.form_submit {
    //width: 213px;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    background: #FFFFFF;

    a {
        text-decoration: none;
        color: #000000;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: default;
        background-image: url(../svg/three_dots.svg);
        background-repeat: no-repeat;
        background-size: auto 30%;
        background-position: center;
    }
}


.wrapper_mes_adresses {
    display: block;

    .conteneur_adresse {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;

        .adresse {
            display: flex;
            align-items: flex-start;
            min-width: 248px;
            margin-bottom: 42px;
            margin-right: 20px;

            .addr_label {
                display: flex;
                text-transform: uppercase;
                font-family: Graphik;
                font-weight: 500;
                font-size: 13px;
                padding-bottom: 16px;
            }

            .infos {
                font-weight: 400;
                font-size: 13px;
                line-height: 1.2;
                padding-bottom: 16px;

                .address span {
                    letter-spacing: -.01rem;
                }
            }

            .addr_titre {
                padding-right: 2px;
            }

            .modlinks_wrapper {
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;

                a {
                    color: #000000;
                    padding-right: 20px;
                }

            }
        }
    }

    .form_submit {
        width: 214px;
        height: 46px;
    }
}