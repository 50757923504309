.wrapper_content_mes_infos {
    width: 98%;
    max-width: 1280px;
    margin: 0 auto;
    box-sizing: border-box;

    .update_success {
        padding-bottom: 20px;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: $green;
    }

    /*     .wrapper_form {
        margin-bottom: 30px;
    } */

    .w-radio-input + .w-radio-input {
        margin-left: 31px;
    }

    .w-form-line:first-of-type {
        margin-bottom: 1.7rem;
    }

    .w-form-line {
        margin-bottom: 1.5rem;

        span {
            font-weight: 400;
            font-size: 14px;
        }

        .w-tel-input {
            span {
                padding-left: 20px;
            }

            .w-input-element {
                padding-left: 100px;
            }
        }

        .w-date-input,
        .w-email-input,
        .w-email-input {
            span {
                font-weight: 300;
                font-size: 12px;
            }
        }

        .w-date-input {
            span {
                font-weight: 400;
                font-size: 12px;
                padding-left: 2px;
                //letter-spacing: .08rem;
            }
        }

        .w-input-element:not([type="radio"]):not([type="checkbox"]) {
            font-weight: 300;
            font-size: 0.82rem;
        }
    }

    .w-input-container + .w-input-container {
        margin-left: 1.5rem;
    }

    .w-submit {
        .w-submit-button,
        button.w-submit-button {
            width: 118px;
            height: 46px;
            background-color: #000000;
            border: 1px solid #000000;
            color: $white;
            font-family: Graphik, sans-serif;
            font-size: 13px;
            font-weight: 500;
            margin-top: 16px;

            &:hover {
                color: #fff;
            }

            .w-loader {
                padding-top: 15px;
            }
        }

        .w-submit-button.w-loader,
        button.w-submit-button.w-loader {
            background: $black url(../svg/three_dots.svg) no-repeat center;
            background-size: auto 22%;
        }
    }

    input[type="tel"] {
        padding-left: 5.2rem !important;
    }
}

body.customer {
    .reinsuranceWrapper {
        padding: 40px 0 33px;
    }

    .flexAccount .form_submit a.button.primary {
        width: 214px;
        height: 46px;
        background: #ffffff;
        border: 1px solid #000000;
        color: #000;
    }

    #adresseForm {
        .elem_global .w-input-group {
            margin-bottom: 1.875rem;
        }

        .w-input.w-tel-input {
            .w-input-label {
                left: 5.2em;
            }

            &.w-nonempty {
                .w-input-label {
                    left: 6.1em;
                }
            }

            input[type="tel"].w-input-element {
                padding-left: 5.2em;
            }
        }

        fieldset.w-input-group {
            margin-bottom: 1.875rem;
        }

        .addrPhone {
            align-items: flex-start;
            font-family: Arial;
            font-size: 0.8rem;
        }
    }
}

.w-tel-input img.valid_form {
    display: none;
}

.selected-flag {
    width: 2.6rem;
    padding: 0 0.5rem;
    border: none;
    background-color: #fff;
    margin-left: 6px;
    border-right: 1px solid #989898;

    &::before {
        display: block;
        //width: 1.5rem;
        width: 3rem;
    }

    &::after {
        margin-left: 0.5rem;
        font-weight: 400;
        font-size: 12px;
    }
}

body.customer {
    .wrapper_content_mes_infos {
        .w-info-form.w-login-form {
            padding-bottom: 0;
            .w-input-container {
                font-family: Arial;
                font-size: 0.8rem;

                + .w-input-container {
                    .w-input.w-tel-input{
                        margin-bottom: 4px;
                    }

                    > span {
                        font-weight: 400;
                        font-size: 12px;
                        color: #868686;
                    }
                }

                .errormsgadd {
                    display: none;
                }
            }

            .w-has-tel {
                align-items: flex-start;
            }
        }
    }

    .wrapper_panier {
        .edit_adresse {
            .left_side {
                @media screen and (max-width: 1024px) {
                    margin-bottom: 30px;
                }
            }
        }
    }
}

form.returnFormWrapper{
    .error_retours {

        font-size: 12px;
        display: none;
        margin: 10px 0;
        &.actif {
            display: block;
        }
    }
}

.product_return_wrapper {
    &.none {
        opacity: 0.5;
        pointer-events: none;
    }
}
.selectWrapper.js-select-motif {
    &.none {
        opacity: 0.5;
        pointer-events: none;
    }
}
