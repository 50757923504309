.wrapper_menu {
    display: flex;
    align-items: center;
    height: 100%;
}

.menu_categ_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .main_menu_itm {
        margin-left: 58px;

        @media screen and(max-width: 1270px) {
            margin-left: 42px;
        }

        @media screen and(max-width: 912px) {
            margin-left: 32px;
        }

        &:first-child {
            margin-left: 0;
        }
    }

    #menu_boutiques {
        display: none;
    }
}

.main_menu_itm {
    position: relative;
    height: 100%;

    a.mainLinkMenu {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 100%;
        font-size: 14px;
        color: $black;
        text-transform: uppercase;
        text-decoration: none;
        padding-top: 36px;
        box-sizing: border-box;

        &.promote {
            font-weight: bold;
        }

        @media screen and (max-width: 912px){
            font-size: 13px;
        }

        span {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                top: 134%;
                left: 0;
                display: block;
                width: 100%;
                height: 1px;
                background-color: #000;
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
            }
        }
    }

    .ss_menu_categ_wrapper {
        position: absolute;
        z-index: 10;
        top: 100%;
        left: 0;
        height: 0;
        overflow: hidden;
        background-color: #fff;
        transition: height 0s ease-in-out;

        &.opened {
            height: auto;
        }
    }

    &:hover {
        a.mainLinkMenu span:after {
            opacity: 1;
        }

        .ss_menu_categ_wrapper {
            height: auto;
        }
    }

    &.current a.mainLinkMenu span:after {
        opacity: 1;
    }

    .wrap_uls {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0 24px 10px 40px;
        box-sizing: border-box;

        @media screen and(max-width: 1600px) {
            padding: 0 24px 24px;
        }

        .ss_cat + .ss_cat {
            margin: 0 0 0 120px;

            @media screen and(max-width: 1600px) {
                margin: 0 0 0 75px;
            }

            @media screen and(max-width: 1200px) {
                margin: 0 0 0 45px;
            }
        }

        .ss_cat {
            &.not-dynamic {
                display: flex;
                flex-direction: column;

                .ss_cat_name_wrapper {

                    &:first-child {
                        order: 2;
                    }
                    
                    &:nth-of-type(2) {
                        order: 4;
                    }

                    &:last-child {
                        order: 3;

                    }

                    .ss_cat_name {
                        position: relative;

                        &.silk::after {
                            display: none;
                        }

                        &:hover {
                            text-decoration: underline;
                            text-underline-offset: 2px;

                            &::after {
                                left: initial;
                                background-color: initial;
                            }
                        }

                        &.actif {

                            &::after {
                                transform: rotate(270deg);

                                @media screen and (max-width: 1024px) {
                                    top: 2px;
                                }
                            }
                        }

                        &::after {
                            content: "";
                            display: block;
                            width: 8px;
                            height: 8px;
                            background-image: url(../svg/spritesheet.svg);
                            @include bgIconCoord(25, 6, 6);
                            transform: rotate(180deg);
                            transition: transform 0.3s ease-in-out;
                            margin: 3px 0 0 8px;

                            @media screen and (max-width: 1024px) {
                                top: 2px;
                            }
                        }
                    }

                    .cat_list {
                        display: none;

                        .ss_cat_item {
                            display: block;
                            color: $black;
                            font-family: $primaryFont;
                            font-size: clamp(12px,fs-vw(14,1920),14px);
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            text-transform: uppercase;
                            text-decoration: none;
                            white-space: nowrap;

                            &:last-child {
                                margin-bottom: 6px;
                            }
                        }
                    }
                }
            }
        }

        .cat_name {
            display: block;
            font-size: clamp(10px,fs-vw(12,1920),12px);
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: 24px;
            //point recettage x148
           &:nth-child(1){
            white-space: nowrap;
           }
        }
    }

    &#menu_univers .ss_menu_categ_wrapper {
        display: flex;

        .wrap_uls {
            padding: 0 50px 24px 40px;
        }
    }

    a.ss_cat_name {
        position: relative;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        height: clamp(17px,fs-vw(19,1920),19px);
        font-size: clamp(17px,fs-vw(19,1920),19px);
        font-weight: 500;
        text-transform: uppercase;
        color: #000;
        white-space: nowrap;
        line-height: 1;
        margin-bottom: clamp(4px,fs-vw(6,1920),6px);
        text-decoration: none;
        box-sizing: border-box;

        &:hover {
            font-family: $secondaryFont;
            font-style: italic;
            font-weight: 400;
            padding-top: 2px;
            cursor: pointer;

            span:after {
                content: "";
                position: absolute;
                top: 95%;
                left: 0;
                display: block;
                width: 100%;
                height: 1px;
                background-color: #000;
            }
        }
    }

    .ss_cat.not-dynamic a.ss_cat_name:hover span::after {
        display: none;
    }
     
    .menu_visuel_wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 110px;
        margin-left: clamp(80px,fs-vw(110,1920),110px);

        a {
            position: relative;
            display: block;
            width: 341px;
            padding-top: calc(332 / 341 * 100%);
            overflow: hidden;

            @media screen and(max-width: 1440px) {
                width: 300px;
            }

            @media screen and(max-width: 1200px) {
                width: 200px;
            }

            + a {
                margin-left: 10px;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
            }
        }

        .visuel_title {
            display: none;
        }
    }

    // Change menu behavior at early stage
    @media screen and (max-width: 1920px) {
        position: initial;

        .ss_menu_categ_wrapper {
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }
}

// A décommenter quand le menu en dur "Matières" ne sera plus affiché
// recettage Desktop FR x130 x131
/*
@media screen and(min-width: 1025px) and (max-width: 1131px){
    #menu_collection{
        .ss_menu_categ_wrapper.submenu_wrapper{
            .wrap_uls{
                .ss_cat{
                    &:nth-of-type(1){
                        max-width: 230.297px;
                    }

                    &:nth-of-type(2){
                        margin: 0 0 0 0px;
                        margin-left: 0;
                    }
                }

                .menu_visuel_wrapper{
                    margin-left: 0;

                }
            }
        }
    }
}

@media screen and(min-width: 1132px) and (max-width: 1238px){
    #menu_collection{
        .ss_menu_categ_wrapper.submenu_wrapper{
            .wrap_uls{
                .ss_cat{
                    &:nth-of-type(1){
                        max-width: 230.297px;
                    }

                    &:nth-of-type(2){
                        margin: 0 0 0 12.5px;
                    }
                }                
            }
        }
    }
}

@media screen and(min-width: 1239px) and (max-width: 1344px){
    #menu_collection{
        .ss_menu_categ_wrapper.submenu_wrapper{
            .wrap_uls{
                .ss_cat{
                    &:nth-of-type(1){
                        max-width: 230.297px;
                    }

                    &:nth-of-type(2){
                        margin: 0 0 0 25px;
                    }
                }               
            }
        }
    }
}

@media screen and(min-width: 1345px) and (max-width: 1449px){
    #menu_collection{
        .ss_menu_categ_wrapper.submenu_wrapper{
            .wrap_uls{
                .ss_cat{
                    &:nth-of-type(1){
                        max-width: 230.297px;
                    }                    
                }

                .menu_visuel_wrapper {
                    margin: 0 0 0 45.75px;
                }
            }
        }
    }
}
*/
// recettage Desktop FR x130 x131 END