/**
MAIN SPRITESHEET USING A SINGLE CLASS
exemple :
<div class="bgSprite bfr search"></div> will display the search icon in a :before content
 */

.bgSprite {
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
        content: '';
        width: 20px;
        height: 20px;
    }

    @include spriter('../svg/spritesheet.svg', 6, 6, (1: 'search', 2: 'wishlist', 3: 'account', 4: 'basket', 5: 'cross', 6:'facebook', 7: 'instagram', 8: 'delivery', 9: 'returns', 10: 'sav', 11: 'secured', 12: 'instagram_bg', 13: 'facebook_bg', 14: 'blackarrow', 22: 'wishfull'));
}