.w-form-line * {
    box-shadow: none !important;
    outline: none;
}

.w-input-element:not([type=radio]):not([type=checkbox]),
input[type=email].w-input-element,  // TODO:
input[type=password].w-input-element,   // Get rid
input[type=text].w-input-element,   // of these
textarea.w-input-element {  // override rules
    height: 48px;
    border-radius: 0;
    border: 1px solid $greyBorder;
    padding: 0 1.1em;
    box-sizing: border-box;
}

.w-input-label {
    left: 1.1em;
    font-size: 14px;
    color: $inputGrey;
}

.w-line-label {
    font-family: $primaryFont;
    font-size: 13px;
    color: $black;
    letter-spacing: .01rem;
}

.w-checkbox-input .w-input-element+.w-input-label,
.w-radio-input .w-input-element+.w-input-label {
    font-size: 14px;
    color: $black;
}

.w-checkbox-input+.w-checkbox-input,
.w-checkbox-input+.w-radio-input,
.w-radio-input+.w-checkbox-input,
.w-radio-input+.w-radio-input {
    margin-left: 23px;
}

.w-checkbox-input .w-input-element+.w-input-label span,
.w-radio-input .w-input-element+.w-input-label span {
    margin-left: 9px;
}

.w-checkbox-input .w-input-element+.w-input-label::before,
.w-radio-input .w-input-element+.w-input-label::before {
    width: 15px;
    height: 15px;
    border: 1px solid $greyBorder;
    box-sizing: border-box;
}

.w-visibility-toggle {
    border: 0;
}

.w-nonempty .w-input-label {
    left: 1.3em;
}

.w-radio-group.w-has-error .w-input-element+.w-input-label,
.w-input-note.w-input-error {
    color: red;
}

.w-radio-group.w-has-error .w-input-element+.w-input-label::before {
    border-color: red;
    box-shadow: none;
}

img#iconValidclientTel,
img#iconErrtelephone {
    display: none !important;
}

img.valid_form {
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);
}