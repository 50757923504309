body.guide_taille,
body.size_guide {
    .guide-taille {
        max-width: 1810px;
        width: 98%;
        margin: 0 auto;

        h1 {
            font-family: $primaryFont;
            font-style: normal;
            font-weight: 500;
            font-size: 50px;
            line-height: 60px;
            text-transform: uppercase;
        }
    }

    .top-content {
        display: flex;
        padding-top: 48px;
        max-width: 1467px;
        width: 100%;
        margin: 0 auto;

        .left-content {
            img {
                margin-left: 12px;
            }
        }

        .right-content {
            padding-left: 82px;
            margin-top: 18px;

            @media only screen and (max-width: 1024px) {
                padding-left: 2px;
            }

            h2 {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 500;
                font-size: 40px;
                line-height: 48px;
                padding-bottom: 47px;
            }
        }

        .block {
            margin-top: -33px;

            .block-texte {
                margin-left: 40px;
                padding-top: 29px;
                margin-right: 115px;

                h3 {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    text-transform: uppercase;
                    margin-bottom: 2px;
                }

                span {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                }
            }
        }

        .function {
            margin-top: 43px;
            padding-left: 38px;

            p {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                margin-right: 115px;
            }
        }
    }

    .taille {
        display: flex;
        max-width: 1600px;
        width: 100%;
        margin: 0 auto;

        @media only screen and (max-width: 1024px) {
            align-items: center;
        }

        .taille-content {
            padding-top: 61px;

            img {
                width: 100%;
                margin-left: 12px;
            }
        }

        .table-wrapper {
            margin-left: 52px;
            margin-top: 92px;
            margin-right: 10px;

            .title {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 29px;
                text-transform: uppercase;
                padding-bottom: 8px;
            }

            .reg1 {
                padding-top: 31px;
            }

            .poncho1 {
                padding-top: 33px;
            }

            .pant1 {
                padding-top: 36px;
            }

            .pant {
                height: 96px !important;
            }

            .sizeguide {
                width: 870px;
                height: 136px;

                @media only screen and (max-width: 1024px) {
                    width: 675px;
                }

                thead {
                    background-color: black;
                    color: white;
                    height: 32px;

                    th {
                        height: 1.23em;
                        width: 18em;
                        line-height: 17px;
                        font-size: 13px;
                        font-weight: 500;
                        padding: 0.61em 0 0 0;
                        text-transform: uppercase;

                        &:first-child {
                            width: 286px;
                        }

                        &:not(:first-child) {
                            width: 194px;
                            text-align: center;
                        }
                    }
                }

                tbody {
                    td {
                        border-collapse: collapse;
                        border: 1px solid #b9b9b9;
                        height: 17px;
                        padding: 8px 0px;
                        font-size: 13px;

                        &:first-child {
                            width: 286px;
                        }

                        &:not(:first-child) {
                            width: 194px;
                            text-align: center;
                        }

                        .header {
                            margin-left: 17px;
                            font-size: 13px;
                            line-height: 16px;
                            font-family: "Graphik";
                            font-style: normal;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }
}