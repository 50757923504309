/**
 * Subscription form
 */
.w-newspreferences-form {
    .w-form-line {
        &:not(.optin-select-line),
        &:not(.w-submit) {
            margin-bottom: 41px;
        }

        &.optin-select-line {
            margin-bottom: 27px;
        }

        &:nth-child(5) {
            margin-bottom: 43px;
        }

        &.w-submit {
            max-width: 118px;
            height: 46px;
            margin: 14px 0 40px;

            .w-submit-button.w-loader,
            button.w-submit-button.w-loader {
                background: #000 url(../svg/three_dots.svg) no-repeat center;
                background-size: auto 22%;
            }
        }
    }

    .w-input-element:not([type=radio]):not([type=checkbox]),
    input[type=email].w-input-element {
        width: 650px;
    }

    .w-checkbox-input {
        height: auto;

        .w-input-element {
            appearance: none;
            z-index: 2;
            top: 1px;
            left: 1px;
            width: 13px;
            height: 13px;
            background: #fff;
            border-radius: 50%;
            border: 0;
            margin: 0;
            opacity: 1;
            transition: left .2s ease-in-out;

            +.w-input-label {
                align-items: flex-start;
                font-family: $primaryFont;

                &:before {
                    width: 26px;
                    height: 15px;
                    background-color: #E5E5E5;
                    border-radius: 72px;
                    box-shadow: none;
                    border: none;
                    transition: background-color .2s ease-in-out ;
                }

                span.multiline {
                    font-size: 13px;
                    margin: 0 0 0 17px;

                    strong {
                        font-weight: 500;
                        text-transform: uppercase;
                        margin-bottom: 11px;
                    }

                    span {
                        font-size: 13px;
                    }
                }
            }

            &:checked {
                left: 12px;

                +.w-input-label:before {
                    background-color: #707070;
                }
            }
        }
    }
}

.content_newsletter {
    .update_failed,
    .update_success {
        font-size: 13px;
        margin-bottom: 15px;
    }

    .update_failed {
        color: red;
    }

    .update_success {
        color: green;
    }
}

.not_connected .content_newsletter {
    margin-top: 45px;
}