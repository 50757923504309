/**
 * Main cookies acceptance (Bottom site lightbox)
 */
#cookiesLightbox {
    font-family: $primaryFont;
    font-size: 13px;
    padding: 39px 0 43px;

    section,
    .w-form-line.w-submit {
        width: 98%;
        max-width: 1800px;
    }

    section {
        padding-right: 470px;
        box-sizing: border-box;

        @media screen and(max-width: 1600px) {
            padding-right: 350px;
        }
    }

    section:first-of-type {
        margin-bottom: 27px;
        line-height: 1.2;

        span{
            font-size: 24px;
            font-weight: 500;
            line-height: 1;
        }

        .cookie_text{
            margin-top: 26px;

            span{
                font-size: 13px;
                font-weight: 400;
            }
        }
    }

    a {
        color: $primaryColor;
    }

    .toggleCookiePrefs {
        display: block !important;
        position: relative;
        width: 100%;
        background: $secondaryColor;
        border: 1px solid $primaryColor;
        font-family: $primaryFont;
        font-size: 13px !important;
        font-weight: 500;
        text-transform: uppercase;
        text-decoration: none;
        box-sizing: border-box;
        line-height: .9;
        margin-top: 0 !important;
        transition: background-color .2s ease-out;

        &:after {
            position: absolute;
            top: 95%;
            left: 0;
            width: 100%;
            height: 1px;
            border: 0;
            padding: 0;
            margin: 0;
            transform: none;
        }

        &.opened:after {
            margin: 0;
            transform: none;
        }

        &:hover {
            background-color: $primaryColor;
            color: $secondaryColor;
          }
    }

    .w-input-label {
        color: $primaryColor;
    }

    .w-form-line.w-submit {
        flex-direction: column-reverse;
        align-items: flex-end;
        margin: -98px auto 98px;
        height: auto;

        .w-btn-wrapper {
            position: absolute;
            z-index: 3;
            width: 270px;

            &:first-child {
                margin-top: 54px;

                .w-submit-button,
                button.w-submit-button {
                    background: $secondaryColor;
                    color: $primaryColor;

                    &:not(.w-loader):hover {
                        background: $primaryColor;
                        color: $secondaryColor;
                    }

                    &.w-loader {
                        background: $primaryColor url(../svg/three_dots.svg) no-repeat center;
                        background-size: auto 22%;
                    }
                }
            }

            &:last-child {
                margin-left: 0;
            }
        }
    }
}

#cookiesLightbox,
#cookiesLightbox.notAccepted {
    .cookiesLightbox_container {
        display: grid;
        grid-template-columns: 1fr 3.31fr 1fr;
        grid-template-rows: auto auto auto;
    }

    .cookiesLightbox_content {
        grid-column: 1 / span 2;
        grid-row: 1;
        padding-right: 300px;
        margin-bottom: 11px;
        margin-left: 3.3%;

        @media screen and (max-width: 1669px) {
            margin-left: 1.3%;
        }
    }

    .cookiesLightbox_accept_btn {
        grid-column: 3;
        grid-row: 1;
        margin-top: 1.55rem;
        margin-right: 5.5rem;

        #js_cookie_accept {
            width: 270px;

            span {
                font-size: 12px;
                font-family: $primaryFont;
                font-weight: 500;
                line-height: .9rem;
            }
        }

        button.w-submit-button.w-loader {
            width: 270px;
        }

        @media screen and (max-width: 1669px) {
            margin-top: 3.55rem;
        }

        @media screen and (max-width: 1294px) {
            margin-top: 4.55rem;
        }

        @media screen and (max-width: 1235px) {
            margin-top: 5.5rem;
        }

        @media screen and (max-width: 1103px) {
            margin-top: 6.5rem;
        }

        @media screen and (max-width: 1069px) {
            margin-top: 7.5rem;
        }

        @media screen and (max-width: 1037px) {
            margin-top: 9rem;
        }
    }

    .moveDownAccept {
        grid-row: 3;
        margin-top: 0.6rem;
    }

    .cookiesLightbox_refuse_btn {
        grid-column: 1 /span 2;
        grid-row: 2;
        margin-left: 1.04rem;
        margin-left: 3.3%;

        @media screen and (max-width: 1669px) {
            margin-left: 1.3%;
        }

        #js_cookie_refuse {
            background-color: transparent;
            border: transparent;
            width: auto;

            span {
                font-size: 12px;
                font-family: $primaryFont;
                line-height: 18px;
                font-weight: 200;
                font-style: italic;
                text-transform: none;
                color: $tertiary;
                text-decoration: underline;
            }

            &.toggleCookiePrefs:after {
                content: none;
            }
        }

        button.w-submit-button.w-loader.loading {
            display: none;
        }
    }

    .cookiesLightbox_preferences {
        grid-column: 1 / span 2;
        grid-row: 3;
        width: 98%;
        padding-right: 245px;
        margin-left: 3.3%;

        @media screen and (max-width: 1669px) {
            margin-left: 1.3%;
        }

        .cookiesInfos {
            margin: 0;

            #cookiePref02,
            #cookiePref03 {
                .icon {
                    position: relative;
                    top: 0.35rem;
                    display: inline-block; 
                }
            }
        }
    }

    .cookiesLightbox_custom_btn {
        margin: auto;
        grid-column: 3;
        grid-row: 2;
        margin-top: -.96rem;

        button.w-submit-button {
            color: $black;
        }

        #jsCookieCustom {
            width: 270px;

            span {
               font-size: 12px;
                font-family: $primaryFont;
                font-weight: 500;
                line-height: .9rem;
            }

            &.toggleCookiePrefs:after {
                content: none;
            }

            &:hover {
                span {
                    color: $white;
                }
            }
        }

        button.w-submit-button.w-loader.loading {
            width: 270px;
        }

        button.w-submit-button.w-loader {
            width: 270px;
        }
    }

    .moveDownCustom {
        grid-row: 3;
        margin-top: 4rem;
    }
}

section {
    span#js_cookie_refuse.refuse-btn {
        span {
            font-family: $primaryFont;
            font-size: 12px;
            font-weight: 400;
            cursor: pointer;
            color: $tertiary;
            text-decoration: underline; 
        }
    }
}