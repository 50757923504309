.newlogin_page {
    position: relative;
    width: 100%;
    max-width: 1800px;
    margin: 0 auto;
    padding-top: 0;

    &.bandeau_connexion_style {
        margin: 10px auto 0;
    }

    .wrapper_bandeau_connexion {
        position: relative;
        width: 100%;

        img {
            width: 100%;
            height: auto;
            vertical-align: top;

            +h1 {
                display: none;
            }
        }

        .bandeau_connexion_title {
            position: absolute;
            top: 49%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 50px;
            font-weight: 500;
            color: #fff;
            line-height: 1;
            text-transform: uppercase;
            letter-spacing: initial;
            white-space: nowrap;
            margin: 0;
        }

    }

    .bandeau_titre h1 {
        position: absolute;
        top: 49%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 50px;
        font-weight: 500;
        color: #fff;
        line-height: 1;
        letter-spacing: initial;
        white-space: nowrap;
        margin: 0;
    }

    .login_wrapper {

        p {
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
        }
    }

    .login_bloc {
        width: 600px;
        align-items: flex-start;
        padding-bottom: 2px;

        &:first-of-type {
            border-bottom: 1px solid #989898;

            .title {
                margin: 24px 0 27px;
            }
        }

        &:last-of-type {
            padding-top: 22px;

            .title:not(.my_account) {
                margin-bottom: 17px;

                .subtitle {
                    line-height: 1.25;
                }
            }
        }

        .title {
            width: 100%;
            text-align: left;

            h2,
            h3,
            &.my_account .subtitle,
            h4 {
                font-family: $primaryFont;
            }

            &.my_account {
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                padding-bottom: calc(200 / 1800 * 100%);
                margin: 0;

                h2 {
                    position: absolute;
                    top: 49%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 50px;
                    font-weight: 500;
                    color: #fff;
                    line-height: 1;
                    letter-spacing: initial;
                    white-space: nowrap;
                    margin: 0;
                    display: none;
                }

                .subtitle {
                    display: none;
                }
            }

            h2,
            h3 {
                font-size: 16px;
                font-weight: 500;
                letter-spacing: initial;
                margin: 43px 0 12px 0px;
            }

            h4 {
                font-size: 16px;
                font-weight: 500;
                letter-spacing: initial;
                margin-bottom: 11px;
            }

            &:not(.my_account) {
                .subtitle {
                    font-size: 13px;

                    span {
                        display: block;
                    }
                }
            }
        }

        .w-lostpass-form {
            .w-form-line.w-submit {
                max-width: none;

                .w-btn-wrapper {
                    max-width: 199px;
                }
            }

            .mdpSentEmail {
                font-size: 13px;
                color: green;
            }
        }

        .content_form.sponsor {
            margin: 10px 0 1.5rem;

            .wrapper_link_sponsor {
                margin-bottom: 0.5rem;

                .link_sponsor {
                    position: relative;
                    font-size: 13px;
                    font-weight: 500;
                    text-transform: uppercase;
                    border-bottom: 0;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 105%;
                        left: 0;
                        right: 0;
                        height: 1px;
                        background: #000;
                        transition: left .2s ease-in-out;
                    }

                    &:hover:after,
                    &:focus:after {
                        left: 100%;
                    }
                }
            }

            .wrapper_input_sponsor {
                >.w-input-container {
                    margin-top: 20px;
                }
            }

            .en_savoir_plus_cta {
                font-size: 13px;
                line-height: 16px;
                text-decoration-line: underline;
                color: $black;
            }
        }
    }

    .w-newlogin-form {
        width: 100%;

        .w-form-line {
            &.email-form-line {
                margin-bottom: 11px;
            }

            &.pwd-form-line {
                margin: 13px 0 25px;
            }

            &.w-submit {
                align-self: flex-start;
                margin-bottom: 12px;
                max-width: 199px;
                height: 46px;
            }
        }

        .w-lines-group {
            margin-bottom: 38px;
        }

        .w-fieldset-label {
            margin: 0;
            font-family: $primaryFont;
            font-size: 13px;
        }

        .optin_container.column {
            flex-direction: row;
            justify-content: flex-start;
            align-items: baseline;
            margin-top: 11px;

            &>.w-form-line {
                width: auto;

                .w-line-label {
                    min-width: auto;
                    margin-right: 7px;
                }
            }

            .w-form-line+.w-form-line {
                margin-left: 50px;
            }

            .knowMoreOptin {
                margin-left: 40px;

                .info_site a {
                    font-size: 13px;
                    color: #000;
                    text-decoration: underline;
                    border-bottom: none;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        .optin_container .w-input-note.w-input-error {
            text-align: left;
        }

        .w-pwd-reset {
            font-size: 13px;
            color: #000;
            text-decoration: underline;
            border-bottom: none;
            margin: 0 0 11px;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

body.en {
    .newlogin_page .w-newlogin-form .optin_container.column>.w-form-line .w-line-label:after {
        content: ': ';
    }
}

.login_bloc .w-client-form.w-lostpass-form {
    align-items: flex-start;
}

.w-oneclick-connect {
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .w-social-btn {
        width: 36px;
        height: 36px;
        background: linear-gradient(180deg, #F7F7F7 0%, #E6E6E6 100%);
        border: 0;

        &>div {
            width: 24px;
            height: 24px;
        }

        +.w-social-btn {
            margin-left: 24px;
        }
    }

    #googleConnect {
        width: 36px;
        height: 36px;
    }
}

body.body_login {
    .login_politique_confidentialite_msg {
        display: block !important;
        width: 100%;
        font-size: 10px;
        margin-top: 40px;

        a {
            color: #000;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .main_content {
        max-width: 1920px;
        width: 98%;
        margin: 0 auto;
    }

    @media screen and (max-width: 1024px) {
        .main_ariane {
            width: 100%;
        }
    }
}

/**
 * Reset/Create password
 */
.wrapper_moncompte.pwdForget {
    width: 540px;
    margin: 3rem auto;

    .title {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    .w-form-line.w-submit {
        width: 250px;
    }
}