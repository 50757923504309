/**
 * Cover module
 */
#coverSwiper {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto 79px;

    .ill_img {
        position: relative;
        display: block;
        width: 100%;
        height: 0;
        padding-top: calc(1040 / 1920 * 100%);

        img {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
        }
    }

    .coverImg {
        width: 100%;
        height: auto;
        vertical-align: top;
    }

    .slide_video {
        width: 100%;
        padding-top: calc(1080 / 1920 * 100%);

        >a {
            position: inherit !important;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .home-module-contents {
        position: absolute;
        z-index: 5;

        &.align-left {
            left: 12.5%;
            text-align: left;
        }

        &.align-center {
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
        }

        &.align-right {
            right: 12.5%;
            text-align: right;
        }

        &.align-top {
            top: 8%;
        }

        &.align-middle {
            top: 49.5%;
            transform: translateY(-50%);
        }

        &.align-middle.align-center {
            top: 49.5%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &.align-bottom {
            bottom: 8%;
        }
    }

    .home-module-title {
        font-size: 70px;
        font-weight: 500;
        line-height: 1.2;
    }

    .home-module-subtitle {
        font-size: 13px;
        font-weight: 400;
        line-height: 1.2;
        margin-top: 18px;
        margin-bottom: 48px;
    }

    .home-module-cta {
        position: relative;
        font-size: 16px;
        font-weight: 500;

        span {
            display: block;
            height: 1px;
            position: absolute;
            top: 110%;
            left: 0;
            right: 0;
            transition: left 0.2s ease-in-out;
        }

        &:hover span {
            left: 100%;
        }
    }

    .coverSwiperPrev,
    .coverSwiperNext {
        position: absolute;
        z-index: 10;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;

        &:before {
            width: 30px;
            height: 30px;
        }

        &.swiper-button-disabled {
            opacity: 0.2;
            cursor: default;
        }

        display: none;
    }

    .coverSwiperPrev {
        left: 1.5%;
        transform: translateY(-50%) rotate(180deg);
    }

    .coverSwiperNext {
        right: 1.5%;
    }
}

/**
 * 4 images
 */
.home-module.four-images {
    width: 98%;
    max-width: 1800px;
    margin: 0 auto 79px;

    @media screen and(max-width: 1200px) {
        width: 95.2%;
    }

    .homeFourImage {
        .swiper-wrapper {
            justify-content: space-between;
        }

        .swiper-slide {
            width: 24%;
            max-width: 390px;

            a {
                position: relative;
                display: block;
                text-decoration: none;
            }

            img {
                width: 100%;
                height: auto;
                vertical-align: top;
            }

            &:after {
                content: "";
                display: block;
                position: absolute;
                z-index: 5;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 50%;
                background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, #000000 100%);
                opacity: 0.6;
            }
        }
    }

    .homeFourImagePrev,
    .homeFourImageNext {
        position: absolute;
        z-index: 10;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;

        &:before {
            width: 30px;
            height: 30px;
        }

        &.swiper-button-disabled {
            display: none;
        }
    }

    .homeFourImagePrev {
        left: 0.8rem;

        &:before {
            transform: rotate(180deg);
        }
    }

    .homeFourImageNext {
        right: 0.8rem;
    }

    .home-module-contents {
        position: absolute;
        z-index: 10;
        bottom: 0;
        left: 0;
        padding: 0 0 37px 32px;
        box-sizing: border-box;

        a {
            position: relative;
            width: fit-content;
        }
    }

    .home-module-title {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 13px;

        @media screen and (max-width: 1430px) {
            font-size: 1.65vw;
        }
    }

    .home-module-cta {
        font-size: 16px;
        font-weight: 500;

        span {
            display: block;
            height: 1px;
            position: absolute;
            top: 130%;
            left: 0;
            right: 0;
            transition: left 0.2s ease-in-out;
        }

        &:hover span {
            left: 100%;
        }
    }

    // FOUR-IMAGES VIDEOS SPECIFICITY
    .slide_video {
            position: relative;
            max-width: 390px;
            margin: 0 1rem;
            width: 100%;
            padding-bottom: calc((233 / 640)* 100%);
        

        iframe {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

// VIDEOS GENERAL
.slide_video {
    display: block;

    >a {
        position: absolute;
        display: block;
        height: 100%;
        top: 0;
        left: 0;
        width: 100%;
    }

    .vimeo_video,
    .youtube_video,
    iframe:not(.vimeo_video):not(.youtube_video):not(.daily_video) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .block_redirect {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        top: 0;
        background: transparent;
    }
}

/**
 * TG module
 */
.home-module.push-product-module {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto 79px;
    margin-top: 4.81rem;

    .pushModuleTitle,
    .pushModuleSubtitle {
        width: 98%;
        max-width: 1810px;
        margin: 0 auto;
        font-size: clamp(40px, fs-vw(50, 1920), 50px);
        font-weight: 500;
        text-transform: uppercase;

        @media screen and (max-width:1024px) {
            width: 99%;
        }
    }
}

/* Special case for CMS page */
.cms_page_content .home-module.push-product-module {
    margin: 4.81rem auto 20px;
}

.pushProductHomeWrapper {
    width: 100%;
    max-width: 1920px;
    margin: 28px auto 0;
    overflow: hidden;

    @media screen and(max-width: 1200px) {
        .pushProductHome {
            margin: 0 auto;
            overflow: visible;
        }
    }

    a.block_lnk {
        max-width: 100%;
        width: 100%;
        padding-top: calc(640 / 480 * 100%);
    }

    .item {
        padding-bottom: 35px;

        a.blockInfosProd {
            padding-left: 24px;
            box-sizing: border-box;

            .wrapper_description {
                .price_n_eclat_rect_wrapper {
                    display: flex;
                    align-items: flex-start;

                    .eclat_rect {
                        margin-left: 11px;
                        margin-top: 7px;
                    }
                }
            }
        }
    }

    .wrap_rolloverproduit .productColorFieldset {
        padding-left: 23px;
        box-sizing: border-box;

        input[type="radio"]+label {
            margin-right: 6px;
        }
    }

    .tgHomePrev,
    .tgHomeNext {
        position: absolute;
        z-index: 5;
        top: 50%;
        padding: 0 1rem;
        cursor: pointer;

        &:before {
            width: 30px;
            height: 30px;
            margin-top: -70px;
        }

        &.swiper-button-disabled {
            display: none;
        }
    }

    .tgHomePrev {
        left: 0;

        &:before {
            transform: rotate(180deg);
        }
    }

    .tgHomeNext {
        right: 0;
    }

    .pushProductHome {
        .noSizeAvailable {
            background: $white;
            text-align: center;

            p#size_is_not_available {
                margin-top: 24px;
                font-size: 0.9rem;
            }
        }

        .wrap_rolloverproduit {
            .btnAddBasketWrapper {
                min-height: 64px;
                margin-top: unset;
            }
        }
    }
}

/**
 * Text module
 */
.home-module.text-module {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 98%;
    max-width: 1800px;
    margin: 0 auto 70px;

    @media screen and(max-width: 1200px) {
        width: 95.2%;
        max-width: 960px;
    }

    .home-module-text-left,
    .home-module-text-right {
        width: 49%;
        font-size: 40px;
        font-weight: 500;
        line-height: 48px;
        box-sizing: border-box;

        .text {
            margin-bottom: 25px;
        }

        strong {
            font-weight: 500;
        }
    }

    .home-module-text-right {
        padding-left: 44px;
    }

    .home-module-cta {
        position: relative;
        font-family: $secondaryFont;
        font-style: italic;
        font-size: 24px;
        text-decoration: none;

        span {
            display: block;
            height: 1px;
            position: absolute;
            top: 86%;
            left: 0;
            right: 0;
            transition: left 0.2s ease-in-out;
        }

        &:hover span {
            left: 100%;
        }
    }
}

/**
 * Module 2 images
 */
.home-module.image-image {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 98%;
    max-width: 1800px;
    margin: 0 auto 78px;

    @media screen and(max-width: 1200px) {
        width: 95.2%;
        max-width: 960px;
    }

    .home-module-part-left,
    .home-module-part-right {
        position: relative;
        width: 49%;
        max-width: 820px;
    }

    .ill_img {
        position: relative;
        display: block;
        width: 100%;
        padding-bottom: calc(520 / 820 * 100%);

        img {
            position: absolute;
            z-index: 5;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
        }
    }

    .home-module-content {
        position: absolute;
        z-index: 10;
        bottom: 0;
        left: 0;
        padding: 0 0 37px 32px;
    }

    .home-module-image-image-title {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 12px;
    }

    .home-module-cta {
        position: relative;
        font-size: 16px;
        font-weight: 500;
        text-decoration: none;

        span {
            display: block;
            height: 1px;
            position: absolute;
            top: 115%;
            left: 0;
            right: 0;
            transition: left 0.2s ease-in-out;
        }

        &:hover span {
            left: 100%;
        }
    }

    .slide_video {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 63.4%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            margin: auto;
        }
    }
}

/**
 * Social module
 */
.home-module.social-module {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto -30px;
    overflow: hidden;

    .home-module-title {
        width: 98%;
        max-width: 1800px;
        margin: 0 auto;
        font-size: 40px;
        font-weight: 500;

        @media screen and(max-width: 1200px) {
            width: 95.2%;
            max-width: 960px;
        }
    }

    .wrapperSliderHomeInsta {
        position: relative;
        width: 100%;
        max-width: 1800px;
        margin: 26px auto 0;
        padding-top: calc(450 / 450 * 25%);
        transform: translateX(60px);
        overflow: hidden;

        @media screen and(max-width: 1800px) {
            width: 99%;
            margin: 26px 0 0 0;
            transform: none;
            margin-left: 1%;
        }

        @media screen and(max-width: 1200px) {
            width: 97.2%;
            margin-left: 2.8%;
            padding-top: calc(450 / 450 * 45%);
        }
    }

    #sliderHomeInsta {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    .swiper-slide {
        max-width: 450px;

        a {
            position: relative;
            display: block;
            width: 100%;
            padding-top: calc(450 / 450 * 100%);
            overflow: hidden;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
        }

        video {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: auto;
            transform: translateY(-50%);
        }
    }

    .instaPrev,
    .instaNext {
        position: absolute;
        z-index: 10;
        top: 53%;
        transform: translateY(-50%);
        cursor: pointer;

        &:before {
            width: 30px;
            height: 30px;
        }

        &.swiper-button-disabled {
            display: none;
        }
    }

    .instaPrev {
        left: 0.8rem;

        &:before {
            transform: rotate(180deg);
        }
    }

    .instaNext {
        right: 0.8rem;
    }
}

/**
 * Inactive page
 */
.home-module.image-signup-module {
    width: 100%;
    max-width: 1800px;
    margin: 16px auto 0;

    .ill_img {
        position: relative;
        display: block;
        width: 100%;
        padding-top: calc(200 / 1800 * 100%);

        img {
            position: absolute;
            z-index: 5;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
        }
    }

    .home-module-title,
    .home-module-text,
    .w-newsletter-form {
        width: 600px;
        margin: 0 auto;
    }

    .home-module-title {
        font-size: 16px;
        font-weight: 500;
        margin: 24px auto 11px;
    }

    .home-module-text {
        font-size: 13px;
        line-height: 1.2;
    }

    .w-newsletter-form {
        margin-top: 26px;

        .w-input-element:not([type="radio"]):not([type="checkbox"]),
        input[type="email"].w-input-element {
            border-radius: 0;
        }

        .w-form-line {
            &:not(.w-submit) {
                margin-bottom: 40px;
            }

            &.w-submit {
                position: relative;
                width: 136px;
                height: 46px;

                .w-submit-button {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .slide_video {
        position: relative;
        padding-bottom: calc((200 / 1680)* 100%);

        iframe {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

/* HOME PAGE ADV */
body.cms_page,
body.cms_page_new {
    width: 100%;
    max-width: 1920px;
    min-width: 1007px;
    margin: 0 auto;

    .main_ariane {
        max-width: 1800px;
    }

    img {
        width: 100%;
        height: auto;
    }

    #cms_page_btn_back,
    #cms_page_title {
        display: none;
    }

    .cms-page-module.text-image.text-image-1 {
        // Add a margin bottom here, only if this module directly preceeds a title module.
        &:has(+ .cms-page-module-body.line-text.module-title) {
            margin-bottom: 25px;
        }

        .slide_video {
            padding-top: 32.25% !important;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        .cms-page-module-body {
            width: 100%;
            height: auto;

            &:first-child {
                margin-top: 1rem;
            }

            section.cms-title-text {
                max-width: 1800px;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                margin: 0 auto;

                @media screen and (max-width: 1582px) {
                    width: 87.3%;
                }

                @media screen and (max-width: 1419px) {
                    width: 86.8%;
                }

                @media screen and (max-width: 1095px) {
                    width: 86.1%;
                }

                @media screen and (max-width: 1024px) {
                    width: 85.8%;
                }

                .cms-page-module-title {
                    line-height: 1.5rem;

                    a {
                        color: $black;
                        text-decoration: underline;
                    }
                }

                h2 {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 30px;
                }

                .cms-page-module-text {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 18px;

                    a {
                        color: $black;
                        text-decoration: underline;
                    }

                    h1 {
                        font-family: $primaryFont;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 30px;
                        line-height: 35px;
                        text-transform: uppercase;

                        &+h1 {
                            line-height: 54px;
                        }
                    }
                }

                .cms-page-module-text {
                    margin-top: 2.4rem;

                    span a {
                        color: $black;
                        text-decoration: underline;
                    }
                }
            }

            .cms-page-columns-container {
                margin-top: 3rem;

                .cms-page-link img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    .cms-page-module.image.image-1 {
        width: 76.675%;
        margin: 4.892rem auto 0;
        display: grid;
        column-gap: 0;
        grid-template-columns: 50% 50%;
        grid-template-areas:
            "big_img sub_img"
            "big_img sub_img"
            "big_img sub_img";

        >.slide_video {
            display: block;
            padding-bottom: calc((1000 / 690)* 100%);
            position: relative;
        }

        iframe {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

        .cms-page-module-img-ctn {
            grid-area: big_img;

            img {
                position: relative;
                left: 1.165rem;
                width: 94.7%;
                height: auto;
            }
        }

        .cms-page-module.image.sub-image-1 {
            position: relative;
            top: 0px;
            left: 1.15rem;
            grid-area: sub_img;
            height: 100%;
            max-height: 1000px;
            overflow-y: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;

            a {
                color: $black;
                text-decoration: underline;
            }

            &::-webkit-scrollbar {
                display: none;
            }

            h2 {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 600;
                font-size: 30px;
                line-height: 40px;
                margin-bottom: 1rem;
            }

            span {
                font-family: $primaryFont !important;
            }

            p {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                margin-bottom: 0.6rem;
            }

            img {
                width: 32%;
            }
            
            .cms-image-video-ctn {
                display: flex;
                gap: 4px;

                // When the image has a link
                .cms-page-module-link {
                    width: 32%;
                    
                    .cms-page-module-img {
                        width: 100%;
                    }
                }
                
                .slide_video {
                    position: relative;
                    width: 100%;
                    padding-bottom: calc((100 / 234)* 100%);
                    margin: 10px 5px;
                }
            }
        }
    }

    .cms-page-module.text-image-3 {
        padding-left: 1.1rem;
        margin-top: 4.625rem;
        margin-bottom: 4.95rem;

        .cms-page-columns-container .cms-page-column.column_1 {
            display: flex;

            .cms-page-module-contents {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-end;
                align-content: flex-end;
                justify-content: center;
                width: 41%;
                padding: 2.905rem 0 0 0.2rem;

                h1,
                h2,
                p.cms-page-module-text {
                    width: 75%;
                    font-family: $primaryFont;
                    font-weight: 600;
                    font-size: 30px;

                    a,
                    span a {
                        color: $black;
                        text-decoration: underline;
                    }
                }

                h1,
                h2 {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 40px;
                    position: relative;
                    top: 0.175rem;
                    left: 3.5px;

                    @media screen and(max-width: 1441px) {
                        font-size: 35px;
                        line-height: 43px;
                    }

                    @media screen and(max-width: 1336px) {
                        font-size: 30px;
                        line-height: 38px;
                    }
                }
            }

            .ill_img.imgLoaded {
                width: 59%;

                img {
                    width: 90.15%;
                    height: auto;
                    position: relative;
                    left: 0.64%;
                    top: 0.5%;
                }
            }
        }

        .slide_video {
            width: 51%;
            padding-top: 0 !important;
            padding-bottom: calc((346 / 1000)* 100%);
            height: 100%;

            iframe.daily_video {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    .cms-page-module.images-textes-x3 {
        max-width: 1800px;
        width: 98%;
        margin: 5.2rem auto;

        .gondole_x3.swiper-container {
            width: 100%;
            margin: 0 auto;
            display: grid;
            column-gap: 1.55%;
            grid-template-columns: 50% 23.5% 23.5%;
            grid-template-areas:
                "big_img sub_img_one sub_img_two"
                "big_img sub_img_three sub_img_three";

            .cms-page-column.swiper-slide {

                &:nth-child(1) {
                    grid-area: big_img;
                    display: block;
                    .slide_video {
                        padding-top: 0 !important;
                        padding-bottom: calc((1000 / 800)* 100%);
    
                        iframe {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                }

                &:nth-child(2) {
                    grid-area: sub_img_one;
                    position: relative;
                    left: 5px;

                    .slide_video {
                        padding-top: 0 !important;
                        padding-bottom: calc((550 / 400)* 100%);

                        iframe {
                            max-width: 100%;
                            max-height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                }

                &:nth-child(3) {
                    grid-area: sub_img_two;
                    position: relative;
                    .slide_video {
                        padding-top: 0 !important;
                        padding-bottom: calc((550 / 400)* 100%);
                        
                        iframe {
                            max-width: 100%;
                            max-height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                }

                
            }

            .contents {
                grid-area: sub_img_three;
                margin-top: -1.8rem;
                margin-left: 5px;

                @media screen and(max-width: 1441px) {
                    margin-top: 2.5rem;
                }

                @media screen and(max-width: 1336px) {
                    margin-top: 2rem;
                }

                .mainTitle {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    text-transform: uppercase;

                    a {
                        color: $black;
                        text-decoration: underline;
                    }
                }

                .cms-page-module-text {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 35px;
                    width: 100%;
                    margin-top: 20px;
                    margin-bottom: 55px;
                    
                    a {
                        color: $black;
                        text-decoration: underline;
                    }

                    @media screen and(max-width: 1510px) {
                        margin-bottom: 35px;
                    }

                    @media screen and(max-width: 1281px) {
                        margin-bottom: 25px;
                    }
                }

                .cms-page-module-cta {
                    font-family: $secondaryFont;
                    font-style: italic;
                    font-weight: 400;
                    font-size: 24px;
                    position: relative;
                    width: fit-content;
                    top: -0.65rem;
                    cursor: pointer;

                    a {
                        text-decoration: none;
                        color: $black;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        display: block;
                        top: 100%;
                        left: 0;
                        right: 0;
                        height: 1px;
                        background-color: $black;
                        transition: left 0.2s ease-in-out;
                    }

                    &:hover:after {
                        left: 100%;
                    }
                }
            }
        }

        .swiper-button-prev.x3,
        .swiper-button-next.x3 {
            width: 26px;
            height: 26px;
        }
    }

    .cms-module.four-images {
        width: 76.7%;
        margin: 10.05rem auto 0;
        display: grid;
        column-gap: 40px;
        row-gap: 40px;
        grid-template-areas:
            'cmscolumns1 cmscolumns2'
            'cmscolumns3 cmscolumns4';

        .vgtWrapper {
            .cms-module-bg {
                width: 100%;
            }

            &:nth-of-type(1) {
                grid-area: cmscolumns1;
                max-width: 800px;
                margin-left: auto;
            }

            &:nth-of-type(2) {
                grid-area: cmscolumns2;
                max-width: 600px;
            }

            &:nth-of-type(3) {
                grid-area: cmscolumns3;
                max-width: 600px;
                margin-top: -28.5%;
                margin-left: auto;
                
                .cms-module-bg {
                    display: block;
                    margin-left: auto;

                    @media screen and (min-width: 1500px) {
                        width: 90.7%;
                    }
                }
            }

            &:nth-of-type(4) {
                grid-area: cmscolumns4;
                max-width: 450px;
            }
        }

        .vgtWrapper_ctn {
            width: 54.8%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;

            .vgtWrapper {
                &:nth-child(2) {
                    margin-top: 2.45rem;
                    width: 75%;
                }
            }
        }

        .vgtWrapper_ctn:nth-child(2) {
            width: 41.12%;
            padding-left: 1.1rem;
            justify-content: flex-start;
            // SW-7997
            // .vgtWrapper {
            //     &:nth-child(1) {
            //         margin-top: 5.05rem;
            //     }
            // }
        }
    }

    .pushProductHomeWrappers.swiper-container {
        width: 100%;
        max-width: 100%;

        .swiper-slide {
            img {
                width: 100%;
                height: auto;
            }

            &.item .imgWrapper {
                margin-bottom: 16px;
            }

            &.item a.blockInfosProd {
                padding-left: 30px;
            }
        }

        .item a.blockInfosProd {
            padding-left: 26px;
            padding-top: 5px;
        }

        .wrap_rolloverproduit .productColorFieldset {
            bottom: -80px;
        }
    }

    .pushProductHomeWrapper {
        .swiper-slide img {
            width: 100%;
            height: auto;
        }
    }
}

body.homepage {
    .pushProductHomeWrapper {
        .imgWrapper {
            a.block_lnk {
                @media screen and (min-width: 1022px) and (max-width: 1200px) {
                    padding-top: calc(522 / 480 * 100%);
                }
            }

            .wrap_rolloverproduit {
                @media screen and (min-width: 1024px) and (max-width: 1200px) {
                    bottom: 0;
                    width: 100%;
                }
            }
        }
    }
}