/**
 * Header general styles
 */
header#site_head_wrap {
    position: fixed;
    z-index: 60; // Above shad's z-index
    top: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;

    &:hover,
    &.noTrans {
        background-color: white;
    }

    &.sticky {
        background-color: white;

        &:hover,
        &.noTrans {
            background-color: white;
        }
    }
}

/**
 * Pre-header
 */
.header_mention {
    display: none;
    position: relative;
    width: 100%;
    height: $pre-header-height;
    background: $black;

    a {
        text-decoration: none;
    }

    .closeBtn {
        position: absolute;
        z-index: 1;
        top: 50%;
        right: 1em;
        transform: translateY(-50%);

        &:before,
        &:after {
            background-color: $white;
        }
    }

    .promo_swiper {
        height: 100%;
    }

    .wrap_preheadertxt {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: 13px;

        p {
            margin: -0.1rem 0 0 0;

            a {
                text-decoration: none;
                color: $white;
            }
        }
    }
}

/**
 * Main header
 */
.head_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 98%;
    max-width: 1800px;
    height: $header-height;
    margin: 0 auto;
}

/**
 * Logo
 */
a.logo {
    display: block;
    width: 150px;
    height: 41px;
    background: transparent url(../svg/notshy.svg) no-repeat center;
    background-size: contain;
    font-size: 0;
}

/**
 * Header right part general styles
 */
.headerRightPart {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: $header-height;

    .block_top_item {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 10.5px;

        &.search {
            padding: 0;

            #trigger_recherche {
                padding: 0 12px 0 0;
                cursor: pointer;
            }
        }

        &#cart_top {
            padding: 0 0 0 10px;
            margin-right: -2px;
        }

        &.hover {
            .bgSprite:after {
                width: 16px;
            }
        }
    }

    .bgSprite,
    .currentLang {
        position: relative;
        cursor: pointer;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 120%;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 1px;
            background: $black;
            transition: width 0.2s ease-in-out;
        }

        &.search:hover:after {
            width: 16px;
        }
    }

    .currentLang {
        display: flex;
        align-items: center;
        height: 20px;

        &:hover:after {
            width: 16px;
        }
    }
}

/**
 * Switch lang
 */
.switchLangWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    font-size: 14px;

    .switchLang {
        display: none;
        position: absolute;
        top: 74%;
        align-items: center;

        a {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 14px;
            color: $black;
            text-decoration: none;
            position: relative;

            &.lang_de {
                &:before {
                    background: transparent url("../../img/flag_de.png") center center no-repeat;
                    background-size: 100% auto;
                }
            }
        }
    }

    &.opened .currentLang:after {
        width: 16px;
    }
}

/**
 * Right part rollovers general styles
 */
body {

    #topLogin,
    #show_top_wish,
    #show_top_cart {
        position: fixed;
        z-index: -1;
        top: 0;
        right: 0;
        min-width: 488px;
        height: 100vh;
        background-color: $white;
        transform: translateX(100%);
        transition: all 0.3s ease-out;
    }

    .basket_gondole {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;

        .title_gondole {
            margin: 10px 0;
            padding-left: 64px;
            font-family: $primaryFont;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
            color: $black;
        }

        .swiper-container {
            width: 100%;
            height: 100%;

            .swiper-slide {
                position: relative;
                max-width: 149px;

                .product_name {
                    display: block;
                    width: 90%;
                    margin-bottom: 8px;
                    padding: 0 10px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: $black;
                    font-family: $primaryFont;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .product_name_split {
                    display: none;
                }

                .pricetag {
                    color: $black;
                    font-family: $primaryFont;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    padding: 0 5px 0 10px;

                    &.crossed {
                        font-size: 14px;
                        color: $tertiary;
                        text-decoration: line-through;
                        margin-right: 8px;
                        padding: 0 0 0 10px;
                        margin: 0;
                    }

                    &.price-discount {
                        padding: 0 5px 0 4px;
                    }
                }

                .achat_express_gondole {
                    position: absolute;
                    bottom: 3px;
                    right: 10px;
                    left: initial;
                    width: 20px;

                    .add_to_basket {
                        width: 21px;
                        height: 15px;

                        &:after {
                            display: none;
                        }
                    }
                }

                img {
                    height: auto;
                    width: 100%;
                }

                a {
                    text-decoration: none;
                }
            }


        }

        .swiper-hover-prev,
        .swiper-hover-next {
            width: 18px;
            height: 18px;
            background-image: url(../svg/spritesheet.svg);
            @include bgIconCoord(14, 6, 6);
            cursor: pointer;
        }

        .swiper-hover-prev {
            display: none;
        }

        .swiper-hover-next {
            position: absolute;
            right: -20px;
            top: 45%;
            transform: rotate(180deg) translateY(-50%);
            z-index: 10;
        }

        .swiper-button-disabled {
            opacity: .2;
        }

        @media screen and (max-width: 1366px) {
            display: none;
        }


    }

    &.bandeauDelivery {
        .header_mention {
            display: block;
        }

        #topLogin,
        #show_top_wish,
        #show_top_cart {
            top: $pre-header-height;
        }
    }
}

.my_account.is_logged.hover #topLogin,
#wishlist_top #show_top_wish.appear,
#cart_top.hasItem:hover #show_top_cart,
#cart_top #show_top_cart.appear {
    transform: translateX(0);
}

#wishlist_top,
#cart_top {
    i.monpanier {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: Arial, Helvetica, sans-serif;
        font-size: 9px;
        font-style: normal;
        color: $black;
        text-decoration: none;
    }
}

/**
 * Wishlist + Account common styles
 */
#show_top_wish,
#show_top_cart {
    padding: $header-height 0 0 0;
    box-sizing: border-box;

    .rollover_title {
        height: 4%;
        padding: 0 0 10px 64px;

        .title {
            font-size: 24px;
            font-weight: 500;
            text-transform: uppercase;
            line-height: 1;
        }

        a {
            color: $black;
            text-decoration: none;
        }
    }

    .no_product {
        padding-left: 64px;
    }

    #cart_list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        max-width: 374px;
        height: fit-content;
        max-height: 45%;
        padding: 0 10px 0 64px;

        @media screen and (max-width: 1600px) {
            max-height: 40%;
        }

        @media screen and (max-width: 1366px) {
            max-height: 60%;
        }

        @media screen and (min-height: 1100px) {
            max-height: 60%;
        }
    }

    #cart_list.scroll {
        overflow-y: scroll;
    }

    /* width */
    #cart_list.scroll::-webkit-scrollbar {
        width: 3px;
    }

    /* Track */
    #cart_list.scroll::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    #cart_list.scroll::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    #cart_list.scroll::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .product-link {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 15px;
        padding-right: 10px;
        box-sizing: border-box;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .cart_thumb_holder,
    .cart_detail_box,
    .cart_product {
        height: 160px;

        @media screen and (max-width: 1366px) {
            height: 140px;
        }
    }

    .cart_thumb_holder {
        max-width: 120px;
        width: 35%;

        img {
            width: 100%;
            height: auto;
            vertical-align: top;
        }

        @media screen and (max-width: 1366px) {
            width: 30%;
        }
    }

    .cart_detail_box {
        position: relative;
        width: 65%;
        margin-left: 16px;

        a {
            color: $black;
            text-decoration: none;
        }

        @media screen and (max-width: 1366px) {
            width: 70%;
        }
    }

    .wrap_top_info {
        margin-top: 16px;
    }

    .title {
        font-size: 13px;
        font-weight: 500;
        line-height: 1;
    }

    .wrapper_price {
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
        margin: 10px 0 12px;
    }

    .sub_taille,
    .sub_couleur,
    .sub_quantity {
        font-size: 13px;
        font-weight: 400;
        line-height: 1.2;
    }

    .bask_action {
        font-size: 13px;
        margin-top: 20px;

        #topBasketToWish,
        #topWishTobasket {
            position: absolute;
            bottom: 0px;
            left: -2px;
        }

        #topBasketToWish,
        #top_cart_to_wish {
            &.existToWishlistButton {
                background-position: 80% 60%;
            }
        }

        .bgSprite {
            justify-content: flex-start;

            &:before {
                margin-right: 8px;
            }

            &:after {
                display: none;
            }

            span {
                text-decoration: underline;
            }

            &:hover span {
                text-decoration: none;
            }
        }
    }

    .remove_top_wishlist,
    .remove_prod {
        position: absolute;
        top: 0px;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        font-size: 0;
        cursor: pointer;

        &:before,
        &:after {
            position: absolute;
            left: 7px;
            content: " ";
            height: 16px;
            width: 1px;
            background-color: $black;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }

    .wrap_tot_panier {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        padding: 10px 0 0 0;

        .cart_panier_total {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            height: 35px;
            padding: 0 42px 10px 64px;
            box-sizing: border-box;
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
        }

        .upselling {
            width: 331px;
        }

        .form_submit {
            width: 100%;
            box-sizing: border-box;
            padding-left: 64px;
        }

        .button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: fit-content;

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 45px;
                text-align: center;
            }
        }
    }

    &.noDisplay {
        display: none !important;
    }
}

#show_top_wish .cart_detail_box {
    width: 50%;
}

.smallbasketExpress {
    left: initial;
    right: -800px;
    top: 104px;
    width: 100%;
    max-width: 489px;
    height: calc(100vh - 104px);
    padding: 30px;
    box-sizing: border-box;
    transform: none;
    background: $white;

    .pin_see_product {
        display: flex;
        width: 100%;
        max-width: 374px;
        height: 45px;
        padding: 14px 32px;
        justify-content: center;
        align-items: center;
        margin-top: 4rem;
        background: $black;
        box-sizing: border-box;
        color: $white;
        font-family: $primaryFont;
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
        text-decoration: none;

        &:hover {
            background: $white;
            border: 1px solid $black;
            color: $black;
        }
    }

    &.actif {
        right: 0;

        .lightbox_content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }

        .swiper-slide {
            max-width: 24rem;
        }

        .item_price {
            margin-top: 5px;

            .pricetag {
                font-size: 13px;
                font-weight: 500;
            }
        }

        .productColorFieldset {

            input[type=radio]+label {
                width: 13px;
                height: 13px;
                border: 0.5px solid $tertiary;
            }

            input[type=radio]:checked+label {
                box-shadow: none;
                border-color: $black;
            }

            .prod_listes .choices_list {
                position: fixed;
                margin-top: -20px;
            }

        }

        .popup_title {
            text-align: left;
            width: 100%;
            max-width: 374px;
            margin-bottom: 1.2rem;
            color: $black;
            font-family: $primaryFont;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
        }

        .close_pop {
            height: 17px;
            width: 17px;
            position: absolute;
            right: 12%;
            top: 45px;
        }

        .bgSprite:after {
            display: none;
        }

        @media screen and (max-width: 1440px) {
            width: 30%;

            .popup_title {
                width: 75%;
            }

            .close_pop {
                top: 41px;
            }

            .swiper-slide {
                max-width: 19rem;
            }

            .pin_see_product {
                width: 75%;
            }
        }

        .price_n_eclat_rect_wrapper {
            display: flex;
            align-items: center;
            margin-top: 6px;

            .item_price {
                margin-top: 0;
            }

            .eclat_rect {
                margin-left: 10px;
            }
        }
    }

    .rollover_left.achat_express {
        max-height: 47px;

        .productSizeFieldset {
            min-height: 47px;
        }
    }

    .form_submit.bloc_add_color {
        background: transparent;
        width: 30px;

        .button.w-submit-button {
            background: transparent;
            border: none;
            position: relative;

            span {
                display: none;
            }

            &:after {
                content: "";
                position: absolute;
                top: 6px;
                right: 0;
                width: 14px;
                height: 18px;
                opacity: 1;
                background-image: url(../svg/bag_icon.svg);
                background-repeat: no-repeat;
                background-size: 100% auto;
                transition: all 0.2s ease-out;
            }

            &:hover {
                &:after {
                    opacity: .4;
                }
            }
        }
    }
}


/**
 * Wishlist menu
 */
#wishlist_top {
    #show_top_wish {
        transform: translateX(100%);
    }

    .alma_upselling_hover_panier {
        display: none;
    }

    &.hasItem.hover {
        #show_top_wish {
            transform: translateX(0);
        }
    }

    i.monpanier {
        top: 55%;
    }
}

/**
 * Cart menu
 */
#cart_top {
    i.monpanier {
        top: 65%;
    }
}

/**
 * Account menu
 */
.wrapper_menu_compte_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: calc(#{$header-height} + 40px);
    margin-left: 64px;
    font-weight: 500;
    text-transform: uppercase;

    a {
        position: relative;
        display: inline-flex;
        color: $black;
        text-decoration: none;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 110%;
            left: 0;
            width: 0;
            height: 1px;
            background: $black;
            transition: width 0.2s ease-in-out;
        }

        &:hover:after {
            width: 100%;
        }

        &.account_name {
            font-size: 24px;
            margin-bottom: 43px;
        }
    }

    .wrapper_menu_compte {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        a {
            font-size: 16px;
            margin-bottom: 19px;
        }
    }

    .btn_container {
        position: absolute;
        bottom: 64px;
        left: 64px;
        width: calc(100% - 128px);
        max-width: 374px;

        .button,
        .loader {
            width: 100%;
        }
    }
}

// Rollover panier
body {
    #cart_top {
        #show_top_cart {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            background-color: $white;
            max-width: 488px;
            height: calc(100vh - 104px);
            padding-top: 18px;
            position: absolute;
            top: 104px;
            right: 0;
            transform: translateX(100%);
            transition: transform 0.5s ease-in-out;

            @media screen and (max-width: 1440px) {
                height: calc(100vh - 110px);
                max-width: 488px;
                top: 110px;
            }

            // For large screens, bigger than 1920px
            @media screen and (min-width: 1921px) {
                max-width: unset;
            }

            .alma_upselling_hover_panier {
                display: none;
            }

            .wrap_tot_panier {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-end;
                width: 100%;
                height: 50%;
                margin-top: auto;

                // For large screens, bigger than 1920px
                @media screen and (min-width: 1921px) {
                    width: 488px;
                }

                .cart_panier_total {
                    order: -1;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                }

                .basket_gondole {
                    order: 2;

                    .swiper-hover-next,
                    .swiper-hover-prev {
                        display: none;
                    }
                }

                .form_submit {
                    order: 1;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: fit-content;
                    margin: 0;
                    padding-right: 42px;
                }

                .upselling.price {
                    // For large screens, bigger than 1920px
                    @media screen and (min-width: 1921px) {
                        max-width: 488px;
                        margin-left: auto;
                    }
                }

                @media screen and (max-width: 1366px) {
                    max-height: 40%;
                    padding-bottom: 10px;
                }
            }

            // When adding a product to cart, this container moves outside of .wrap_tot_panier
            .upselling.price {
                // For large screens, bigger than 1920px
                @media screen and (min-width: 1921px) {
                    margin-left: 100px;
                }
            }
        }
    }
}