/* PAGE TEXTES */
.wrapper_content_sat.page_text {
    width: 98%;
    max-width: 1800px;
    margin: 0 auto;

    .title span {
        font-family: $primaryFont;
        font-size: 50px;
        font-weight: 500;
        line-height: 60px;
        text-transform: uppercase;
    }

    .content_sat {
        margin-top: 32px;

        .row .content {
            &:first-child .sub_title {
                margin-top: 36px;
            }

            .sub_title {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                text-transform: uppercase;
                margin-bottom: 16px;
            }

            .content_inside {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 15.6px;
                color: $black;
            }
        }
    }
}

body.entretien .page_wrapper .main_content {
    min-width: 1024px;
    width: 100%;
    max-width: 1920px;
    min-width: 1007px;
    margin: 0 auto;
    color: $black;

    strong {
        font-weight: 500;
    }

    a {
        color: $black;
    }

    .active {
        text-decoration: underline!important;
    }
    
    .entretien_texte {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: $black;
        
        .title {
            font-family: $primaryFont;
            font-size: 35px;
            font-weight: 500;
            line-height: 43px;
            margin: -0.5rem 0 1.492rem;
        }
        
        p {
            font-family: $secondaryFont;
            font-size: 15px;
            line-height: 16px;
        }

        .menu_entretien {
            display: flex;
            column-gap: 5rem;
            font-family: $primaryFont;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 500;
            margin: 3.2rem 0;
            
            a {
                position: relative;
                text-decoration: none;

                &:after {
                    content: "";
                    width: 100%;
                    border-bottom: 1px solid #333;
                    position: absolute;
                    bottom: 1px;
                    left: 0;
                    opacity: 0;
                    transition: opacity 300ms ease;
                }

                &:hover:after {
                    opacity: 1;
                }
            }
        }
    }

    .entretien_banner {
        display: flex;
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;

        img {
            width: 100%;
            object-fit: cover;
        }
    }

    section.bloc {
        margin: 3rem 3.5rem;
    }

    .conseil {
        display: flex;

        .bloc_1,
        .bloc_2 {
            //width: 50%;
            font-family: $primaryFont;
        }

        .bloc_1 {
            width: 54.2%;

            p.conseil_title {
                font-size: 30px;
                font-weight: 500;
                vertical-align: middle;

                .conseil_num {
                    font-family: $secondaryFont;
                    font-size: 30px;
                    font-weight: 400;
                }
            }
        }

        .bloc_2 {
            display: flex;
            flex-direction: column;
            gap: 1.25rem;
            width: 46.8%;

            h2 {
                font-size: 16px;
                line-height: 19.2px;
                font-weight: 500;
            }

            p {
                font-size: 13px;
                font-weight: 400;
                line-height: 16px;
            }

            .bloc_img {
                display: flex;
                align-items: baseline;
                gap: 6.25rem;
                margin: 1.8rem 3.1rem;

                div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    span {
                        font-size: 12px;
                        text-transform: uppercase;
                        margin-top: 1rem;
                        width: max-content;
                    }
                }
            }
        }
    }

    .conseil_bloc2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 3rem;
        margin-left: 1rem;

        h2.title {
            font-size: 30px;
            font-weight: 500;
            line-height: 36px;
            margin-bottom: 1.4rem;
        }

        .subtitle {
            font-size: 16px;
            line-height: 19.2px;
            font-weight: 500;
            margin-bottom: 0.65rem;
        }

        .paragraph {
            font-size: 13px;
            line-height: 14.4px;
            font-weight: 400;
        }

        h3 {
            font-size: 13px;
            line-height: 14.4px;
            font-weight: 400;
        }

        p {
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;

            &.soustitre {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 0.8rem;
            }
        }


        .bloc_img {
            display: flex;
            align-items: baseline;
            justify-content: center;
            width: 90%;
            margin: 3.25rem 0 3.8rem 0;


            div {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 19px;

                p {
                    text-align: center;
                    font-size: 12px;
                    text-transform: uppercase;
                    line-height: 14.4px;
                }

                .icon_texte {
                    text-transform: inherit;
                    font-size: 13px;
                    line-height: 15.6px;
                }
            }

            
            .tri {
                max-width: 17%;
                margin-right: 7.5%;
                
                
                p:first-of-type {
                    max-width: 222px;
                }
            }

            .froid {
              max-width: 14%;
              margin-right: 5.7%;
            }

            .essorage {
              max-width: 18%;
              margin-right: 5.5%;
            }

            .sac {
              max-width: 11%;
              margin-right: 5.5%;

              a {
                text-decoration: underline;
                color: $black;
              }
            }

            .lessive {
              max-width: 15%;
            }
        }

        .soustitre {
            font-family: $primaryFont;
            font-size: 16px;
            line-height: 19.2px;
            margin-bottom: 10.7px;
            font-weight: 500;

            +p {
                text-align: center;
                line-height: 14px;
            }
        }
    }

    #lavage {
        .conseil .bloc_2 {
            p:last-of-type {
                max-width: 90%;
            }
        }
        
        .conseil_bloc2 {            
            .soustitre + h3 {
                font-size: 13px;
            }
        }
    }

    .entretien_banner2 {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 1920px;
        aspect-ratio: 192/37;
        background-image: url('../img/entretien/banner2.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top left;

        div {
            display: flex;
            flex-direction: column;
            gap: 29px;
            margin: 2rem 0 2rem 6.5rem;

            h2 {
                font-size: 30px;
                line-height: 35px;
                font-weight: 500;
            }

            p {
                font-family: $secondaryFont;
                font-size: 0.9375rem;
                font-weight: 500;
                line-height: 16px;
                width: 36%;
            }
        }
    }

    #sechage {
        .bloc_2 {
            gap: 3.05rem;

            p {
                font-size: 12px;
                font-weight: 400;
                line-height: 14.3px;
            }

            .first_bloc,
            .second_bloc {
                display: flex;
                flex-direction: column;
                gap: 1.23rem;

                p {
                    font-size: 13px;
                }
            }

            .first_bloc p a{
                text-decoration: none;
            }

            .second_bloc {
                ul {
                    margin-left: 2.3rem;

                    li {
                        font-size: 13px;
                        position: relative;
                        line-height: 19px;
    
                        &::before {
                            content: "●";
                            position: absolute;
                            left: -0.7rem;
                            font-size: 0.6em;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }

    #rangement {
        margin-top: 3.4rem;

        .bloc_1 {
            display: flex;
            flex-direction: column;
            gap: 3rem;
    
            .first_bloc {
                display: flex;
                flex-direction: column;
                gap: 1.23rem;
                margin-top: 0.5rem;

                h2 {
                    font-size: 16px;
                    line-height: 19.2px;
                    font-weight: 500;
                }

                p {
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 14px;
                    width: 90%;
                }
            }

            .second_bloc {
                display: flex;
                flex-direction: column;
                gap: 1.23rem;

                h2 {
                    margin-bottom: 0.2rem;
                    line-height: 19.2px;
                    font-weight: 500;
                }

                ul {
                    margin-left: 2.3rem;

                    li {
                        font-size: 13px;
                        position: relative;
                        line-height: 19px;
    
                        &::before {
                            content: "●";
                            position: absolute;
                            left: -0.7rem;
                            font-size: 0.6em;
                            vertical-align: middle;
                        }
                    }
                }


                p {
                    font-size: 13px;
                    line-height: 15px;
                }
            }
        }

        .bloc_2 {
            display: flex;
            flex-direction: row;
            gap: 3%;
            margin-top: 0.7rem;
            height: auto;
            width: 48.9%;

            a {
                height: 100%;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    #entretien {
        .bloc_2 {
            gap: 2.5rem;

            .first_bloc {
                display: flex;
                flex-direction: column;
                gap: 1.18rem;
    
                h2 {
                    margin-bottom: 0.1rem;
                }
    
                p {
                    font-size: 13px;
                    line-height: 15px;
                }
    
                ul {
                    margin-left: 2.3rem;
    
                    li {
                        font-size: 13px;
                        position: relative;
                        line-height: 15.5px;
        
                        &::before {
                            content: "●";
                            position: absolute;
                            left: -0.7rem;
                            font-size: 0.6em;
                            vertical-align: middle;
                        }
                    }
                }
            }
    
            .second_bloc {
                display: flex;
                flex-direction: column;
                gap: 1.23rem;
                margin-top: 0.5rem;
    
                h2 {
                    font-size: 16px;
                    line-height: 19.2px;
                    font-weight: 500;
                }
    
                p {
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 15px;
                }
            }
        }
    }

    #entretien_banner3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2.7rem;

        .bloc_img {
            display: flex;
            text-align: center;
            gap: 1.2rem;

            a {
                text-decoration: none;

                img {
                    margin-bottom: 0.9rem;
                    width: 100%;
                    
                    +p {
                        text-transform: uppercase;
                        font-size: 13px;
                    }
                }
            }
            
        }

        .cta a {
            font-family: $secondaryFont;
            font-style: italic;
            font-size: 19px;
        }

        .texte {
            text-align: center;
            font-size: 20px;
            line-height: 24px;
            margin: 1rem 0;
        }
    }

    #entretien_gondole {
        > h2 {
            margin: 3.2rem 3.5rem 1.5rem;
            font-size: 35px;
            text-transform: uppercase;
        }

        .product_associations_wrapper {
            position: relative;
            margin-top: 0;
            overflow: hidden;

            h3 {
                margin: 0;
            }

            .wrap_rolloverproduit .productColorFieldset {
                margin-left: 3px;
            }

            .tete_de_gondole {
                display: flex;
                width: calc(100%/5);

                .rollover_left.achat_express {
                    .prod_listes.right_element_prod .choices_list {
                        padding-left: 3px;
                    }
                }

                .productVisualMulti  {
                    .blackarrow  {
                        visibility: hidden;
                    }
                }

                .item {
                    width: 100% !important;
                    padding-bottom: 27px;

                    a.blockInfosProd {
                        margin-left: 0;
                        text-indent: 6px;

                        .price_n_eclat_rect_wrapper {
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;

                            .eclat_rect {
                                margin-left: 8px;
                            }
                        }
                    }
                }
            }
        }
    }

}

body.entretien.en {
    .conseil_bloc2 {
        .bloc_img {
            width: 93%;
            margin: 3.25rem 0 4.8rem 0;

            .tri {
                margin-right: 7.4%;

                p:first-of-type {
                    max-width: 224px;
                }
            }

            .froid {
                max-width: 15%;
                margin-right: 6%;
            }

            .essorage {
                max-width: 17%;
            }

            .sac {
                max-width: 12%;
                margin-right: 5.1%;
            }

            .lessive {
                max-width: 16%;
            }
        }
        .soustitre + h3 {
            width: 72%;
            line-height: 15.8px;
        }
    }

    .page_wrapper .main_content .entretien_banner2 div p {
        width: 35%;
    }

    #lavage {
        .conseil .bloc_2 {
            p:last-of-type {
                max-width: 95%;
            }
        }
    }

    #sechage .bloc_2 .first_bloc p {
        width: 100%;
        text-align: justify;
    }

    #entretien {
        .bloc_2 {
            .first_bloc {
                p:first-of-type {
                    text-align: justify;
                    line-height: 15px;
                }

                ul > li {
                    line-height: 20px;
                }
            }

            .second_bloc p {
                line-height: 16px;
            }
        }
    }

    #rangement {
        .bloc_1 .first_bloc p{
            line-height: 16px;

            p {
                width: 88%;
            }
        }
    }
}

.content_box {
    max-width: 1920px;
    width: 100%;
    background: $merino;

    .texte {
        max-width: 1800px;
        width: 98%;
        font-family: $primaryFont;
        font-style: normal;
        font-size: 40px;
        line-height: 48px;
        display: flex;
        justify-content: space-between;
        padding: 69px 0;
        margin: 0 auto;

        .brief {
            width: 649px;
            font-weight: 500;
        }

        p {
            width: 748px;
            font-weight: 500;
        }
    }
}

.produit {
    width: 100%;
    max-width: 1800px;
    padding: 79px 6.25% 0;
    margin: 0 auto;
    box-sizing: border-box;

    p {
        font-family: $secondaryFont;
        font-style: italic;
        font-weight: 400;
        font-size: 50px;
        line-height: 96px;
        text-align: center;
        margin-bottom: 54px;
    }

    .section_title {
        font-family: $secondaryFont;
        font-style: italic;
        font-weight: 400;
        font-size: clamp(20px, fs-vw(50, 1920), 50px);
        line-height: 96px;
        text-align: center;
        margin-bottom: 54px;
    }

    .produit_img {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 1618px;
        margin: 0 auto;

        .bloc {
            width: calc((100% - 388px) / 3);
            position: relative;

            img {
                width: 100%;
            }
        }

        a.link_plus {
            display: block;
            position: absolute;
            width: 30px;
            height: 30px;
            background-image: url(../svg/spritesheet.svg);
            @include bgIconCoord(22, 6, 6);

            .pastille {
                position: initial;
            }
        }

        .bloc_1 {
            a.link_plus {
                top: 36%;
                left: 59%;
            }

            span {
                position: absolute;
                font-weight: 500;
                font-size: 150px;
                top: -84px;
                left: -55px;
            }

            @media only screen and (max-width: 1500px) {
                span {
                    font-size: 130px;
                    top: -74px;
                    left: -52px;
                }
            }

            @media only screen and (max-width: 1265px) {
                span {
                    font-size: 109px;
                    top: -62px;
                    left: -44px;
                }
            }

            @media only screen and (max-width: 1024px) {
                span {
                    font-size: 74px;
                    top: -42px;
                    left: -28px;
                }
            }
        }

        .bloc_2 {
            a.link_plus {
                top: 19%;
                left: 51%;
            }

            span {
                position: absolute;
                width: 250px;
                bottom: -73px;
                left: -60px;
                font-weight: 500;
                font-size: 150px;
                line-height: 192px;
            }

            @media only screen and (max-width: 1500px) {
                span {
                    font-size: 130px;
                    bottom: -77px;
                    left: -50px;
                }
            }

            @media only screen and (max-width: 1265px) {
                span {
                    font-size: 109px;
                    bottom: -79px;
                    left: -45px;
                }
            }

            @media only screen and (max-width: 1024px) {
                span {
                    font-size: 74px;
                    bottom: -82px;
                    left: -31px;
                }
            }
        }

        .bloc_3 {
            a.link_plus {
                top: 64%;
                left: 28%;
            }

            span {
                position: absolute;
                font-weight: 500;
                font-size: 150px;
                top: -78px;
                left: -61px;
            }

            @media only screen and (max-width: 1500px) {
                span {
                    font-size: 130px;
                    top: -78px;
                    left: -50px;
                }
            }

            @media only screen and (max-width: 1265px) {
                span {
                    font-size: 109px;
                    top: -57px;
                    left: -42px;
                }
            }

            @media only screen and (max-width: 1024px) {
                span {
                    font-size: 74px;
                    top: -40px;
                    left: -28px;
                }
            }
        }
    }
}

.question {
    position: relative;
    width: 312px;
    font-family: $secondaryFont;
    font-style: italic;
    font-weight: 400;
    font-size: 50px;
    margin: 133px 0 0 41.3%;

    p {
        width: 328px;
        font-family: $secondaryFont;
        font-style: italic;
        font-weight: 400;
        font-size: 50px;
        text-align: center;
    }

    .button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 32px 14px;
        gap: 10px;
        width: 122px;
        height: 18px;
        margin: 43px 0 0 70px;
        background: $black;
        color: $white;
        text-decoration: none;

        span {
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: uppercase;
            font-family: $primaryFont;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
        }
    }
}

// PAGE RECYCLEZ
body.recycle,
body.recycling {
    max-width: 1920px;
    width: 98%;
    margin: 0 auto;

    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    .recycle_top {
        width: 100%;
        max-width: 1920px;
        margin: 60px auto 89px;

        .recycle {
            color: $primaryColor;
            text-align: center;
            font-family: $primaryFont;
            font-size: clamp(50px, 5.208vw, 100px);
            font-style: normal;
            font-weight: 500;
            line-height: 1;
            text-transform: uppercase;
            margin-bottom: 98px;

            .underline {
                position: relative;
                display: inline-block;

                &:first-child {
                    &:after {
                        content: "";
                        position: absolute;
                        bottom: -9px;
                        left: 6px;
                        display: block;
                        background: url(../svg/underline_1.svg) no-repeat center;
                        background-size: 100% 0.45vw;
                        width: 100%;
                        height: 7px;
                    }

                    @media screen and (max-width: 1024px) {
                        &:after {
                            bottom: -5px;
                            left: 2px;
                            max-width: 262px;
                            width: 100%;
                            background-size: contain;
                        }
                    }
                }

                &:last-child {
                    &:after {
                        content: "";
                        position: absolute;
                        bottom: -12px;
                        left: 6px;
                        display: block;
                        background: url(../svg/underline_2.svg) no-repeat center;
                        background-size: 100% 0.45vw;
                        width: 100%;
                        height: 7px;
                    }

                    @media screen and (max-width: 1024px) {
                        &:after {
                            bottom: -6px;
                            left: 3px;
                            max-width: 310px;
                            width: 100%;
                            background-size: contain;
                        }
                    }
                }
            }

        }
    }

    .recycle_message {
        font-family: $primaryFont;
        text-align: center;
        font-size: clamp(15px, fs-vw(30, 1920), 30px);
        font-style: normal;
        font-weight: 500;
        line-height: 36px;
        max-width: 1800px;
        width: 100%;
        margin: 0 auto;
        color: $primaryColor;

        .underline_two {
            position: relative;
            display: inline-block;

            &:after {
                content: "";
                position: absolute;
                bottom: -7px;
                left: 0;
                display: block;
                background: url(../svg/underline_3.svg) no-repeat center;
                background-size: 100%;
                width: 100%;
                height: 7px;
            }

            @media screen and (max-width: 1024px) {
                &:after {
                    bottom: 3px;
                }
            }
        }

        &.bottom {
            margin-bottom: 128px;
        }
    }

    .button_up {
        position: fixed;
        width: 24px;
        height: 24px;
        top: 92vh;
        right: 34px;
        opacity: 1;
        background-color: $black;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;

        &.show {
            opacity: 1;
            z-index: 3;
        }

        .btn_up {
            position: relative;
            cursor: pointer;
            width: 24px;
            height: 24px;
            background-color: $white;
            mask-image: url(../svg/spritesheet.svg);
            mask-repeat: no-repeat;
            mask-position: 18.5% 39.5%;
            mask-size: 500%;
            transform: rotate(270deg);
        }
    }

    .image {
        width: 98%;
        max-width: 1800px;
        margin: 77px auto 67px;

        img {
            width: 100%;
            height: auto;
            vertical-align: top;
        }
    }

    .recyclez {
        max-width: 1920px;
        margin: 0 auto 80px;
        box-sizing: border-box;
        background-color: $white;
        display: flex;
        justify-content: center;

        img {
            max-width: 100%;
            width: 100%;
        }
    }

    .decouvrir {
        width: 362px;
        margin: 65px auto 67px;

        .button {
            text-align: center;
            font-family: $primaryFont;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
            max-width: 362px;
            width: 100%;
            height: 46px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .how_it_works {
        max-width: 1800px;
        width: 100%;
        margin: 0 auto clamp(114px, 11.875vw, 228px);
        display: flex;
        justify-content: center;

        .container {
            &.left {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 47.5%;

                .title {
                    position: relative;
                    color: $black;
                    font-family: $primaryFont;
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1;
                    text-transform: uppercase;
                    display: inherit;
                    max-width: fit-content;
                    width: 100%;
                    margin-top: 22px;

                    &::after {
                        position: absolute;
                        top: 6px;
                        content: "";
                        display: block;
                        width: 100%;
                        height: 30px;
                        background-color: $fluo;
                        z-index: -1;
                    }
                }

                .text {
                    position: relative;
                    bottom: 29px;
                    left: -7px;
                    color: $black;
                    font-family: $primaryFont;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21.6px;
                    text-transform: uppercase;
                    max-width: 641px;

                    @media screen and (max-width: 1024px) {
                        padding-left: 1%;
                    }

                    .bold {
                        font-weight: 500;
                    }
                }
            }

            &.right {
                position: relative;
                top: 8px;
                width: 52.5%;

                .content {
                    color: $black;
                    font-family: $primaryFont;
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    display: flex;
                    margin-bottom: 60px;

                    &.one {
                        margin-bottom: 57px;
                    }

                    &.two {
                        margin-bottom: 39px;
                    }

                    .number {
                        width: 111px;
                        font-size: 50px;
                    }

                    .text {
                        position: relative;
                        top: 23px;
                        line-height: 36px;
                        width: 771px;
                    }

                    .underline_three {
                        position: relative;
                        display: inline-block;

                        &:after {
                            content: "";
                            position: absolute;
                            bottom: -5px;
                            left: 0;
                            display: block;
                            background: url(../svg/underline_4.svg) no-repeat center;
                            background-size: contain;
                            max-width: 138px;
                            width: 100%;
                            height: 7px;
                        }
                    }

                    .underline_four {
                        position: relative;
                        display: inline-block;

                        &:after {
                            content: "";
                            position: absolute;
                            bottom: -6px;
                            left: -10px;
                            display: block;
                            background: url(../svg/underline_5.svg) no-repeat center;
                            background-size: contain;
                            max-width: 215px;
                            width: 109%;
                            height: 43px;
                        }
                    }

                    .underline_five {
                        position: relative;
                        display: inline-block;

                        &:after {
                            content: "";
                            position: absolute;
                            bottom: -4px;
                            left: 0;
                            display: block;
                            background: url(../svg/underline_6.svg) no-repeat center;
                            background-size: contain;
                            max-width: 417px;
                            width: 100%;
                            height: 7px;
                        }
                    }

                    .underline_six {
                        position: relative;
                        display: inline-block;

                        &:after {
                            content: "";
                            position: absolute;
                            bottom: -4px;
                            left: 0;
                            display: block;
                            background: url(../svg/underline_7.svg) no-repeat center;
                            background-size: contain;
                            max-width: 355px;
                            width: 109%;
                            height: 7px;
                        }
                    }
                }
            }
        }
    }

    .numbers {
        .title {
            position: relative;
            color: $black;
            text-align: center;
            font-family: $primaryFont;
            font-size: 36px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
            display: inherit;
            max-width: fit-content;
            width: 100%;
            margin: 0 auto 60px;

            &::after {
                position: absolute;
                top: 12px;
                content: "";
                display: block;
                width: 100%;
                height: 30px;
                background-color: $fluo;
                z-index: -1;
            }
        }

        img {
            width: 100%;
        }
    }

    .slogan {
        width: 100%;
        max-width: 1920px;

        p {
            color: $primaryColor;
            text-align: center;
            font-family: $primaryFont;
            font-size: 2.083vw;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    &.en {
        .recycle {
            .underline {
                &:first-child::after {
                    left: 6px;

                    @media screen and (max-width: 1024px) {
                        left: 2px;
                        max-width: 230px;
                    }
                }

                &:last-child::after {
                    left: 8px;

                    @media screen and (max-width: 1024px) {
                        left: 3px;
                        max-width: 290px;
                    }
                }
            }
        }

        .how_it_works {
            .container {
                &.right .content .underline_five:after {
                    content: "";
                    position: absolute;
                    bottom: -4px;
                    left: 0;
                    display: block;
                    background: url(../svg/underline_6_en.svg) no-repeat center;
                    background-size: cover;
                    max-width: 440px;
                    width: 100%;
                    height: 7px;
                }
            }
        }
    }
}

/* Page Cookies */
.misc_cookiesinfo {
    .title {
        margin-bottom: 2.35rem;
    }

    .big_title_charte {
        font-family: $primaryFont;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        margin-bottom: 0.95rem;
    }

    .content_inside {
        margin-bottom: 2.1rem;
        font-size: 13px;

        ul {
            list-style-type: '- ';
            padding-left: 1rem;

            li {
                line-height: 1.5;

                a {
                    display: inline-block;
                    margin-top: 0.25rem;
                    margin-bottom: 0.25rem;

                    &:first-of-type {
                        margin-top: 1rem;
                    }
                }
            }
        }
    }
}

// PAGE LAMARQUE
body.la_marque,
body.the_brand {
    width: 100%;
    min-width: 1007px;

    .logo_marque {
        width: 53%;
        max-width: 1000px;
        margin: 0 auto;

        img {
            width: 100%;
            height: auto;
            vertical-align: top;
        }

        h1 {
            font-family: $primaryFont;
            font-style: normal;
            font-weight: 400;
            font-size: 1.250vw;
            line-height: 1.3;
            text-align: center;
            padding-top: 4%;
        }
    }

    .manifeste {
        font-weight: 400;
        font-size: 4.167vw;
        line-height: 1;
        max-width: 1344px;
        width: 100%;
        text-align: center;
        margin: 0 auto;
        padding-top: 49px;

        p {
            font-style: normal;
            font-family: $secondaryFont;

            .italic {
                font-style: italic;
            }

            .bold {
                font-weight: 500;
                font-family: $primaryFont;
            }

            .sep_1,
            .sep_2,
            .sep_3 {
                display: inline-block;
                width: 2em;
                height: .8em;
                position: relative;

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 55%;
                    width: 100%;
                    height: 3px;
                    background-color: $black;
                }
            }

            .sep_2 {
                width: .9em;

                &:after {
                    content: '';
                }
            }

            .sep_3 {
                width: 1.3em;
                height: .6em;

                &:after {
                    content: '';
                }
            }
        }
    }

    .home-module {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        width: 98%;
        max-width: 1014px;
        margin: 0 auto;
        padding-top: 4.3%;

        a.home-module-cta {
            text-decoration: none;
            color: $black;

            h2 {
                position: relative;
                font-weight: 500;

                &:after {
                    content: "";
                    position: absolute;
                    display: block;
                    top: 100%;
                    left: 0;
                    right: 0;
                    height: 1px;
                    background-color: $black;
                    transition: left .2s ease-in-out;
                }
            }

            &:hover h2:after {
                left: 100%;
            }
        }

        .menu {
            font-family: $primaryFont;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            text-transform: uppercase;
        }
    }

    .button_up {
        position: fixed;
        width: 24px;
        height: 24px;
        top: 92vh;
        right: 34px;
        opacity: 1;
        background-color: $black;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;

        &.show {
            opacity: 1;
            z-index: 3;
        }

        .btn_up {
            position: relative;
            cursor: pointer;
            width: 24px;
            height: 24px;
            background-color: $white;
            mask-image: url(../svg/spritesheet.svg);
            mask-repeat: no-repeat;
            mask-position: 18.5% 39.5%;
            mask-size: 500%;
            transform: rotate(270deg);
        }
    }

    .bloc_1,
    .bloc_2,
    .bloc_3,
    .bloc_5 {
        .creative {
            display: flex;
            justify-content: space-between;
            width: 98%;
            max-width: 1800px;
            margin: 0 auto;

            .creatif {
                img {
                    width: 100%;
                    height: auto;
                }
            }

            .creatif_1 {
                span.num::after {
                    content: none !important;
                }

                .num {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1.250vw;
                }

                h2 {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 4.167vw;
                    line-height: 1.2;
                    text-transform: uppercase;
                }

                p {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    text-align: justify;
                    padding-top: 32px;
                }
            }
        }

        .creatif_2 {
            width: 98%;
            max-width: 1306px;
            margin: 0 auto;
            padding-top: 7%;

            p {
                font-family: $secondaryFont;
                font-style: normal;
                font-weight: 400;
                font-size: 2.604vw;
                line-height: 1.2;
                text-align: center;
                text-transform: uppercase;
                margin-left: -9px;

                span {
                    text-transform: lowercase;
                    font-style: italic;
                }
            }
        }
    }

    .bloc_1 {
        .creative {
            position: relative;
            align-items: flex-start;
            padding-top: 4.4%;

            .creatif {
                margin-left: 7%;
                width: 50%;
                max-width: 840px;

                span {
                    position: absolute;
                    display: block;
                    width: 45%;
                    max-width: 323px;
                    top: 90%;
                    left: 0;

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }

            .creatif_1 {
                padding-top: 2%;
                width: 38%;
            }
        }
    }

    .bloc_2 {
        .creative {
            align-items: flex-end;
            padding-top: 4.2%;

            .creatif {
                position: relative;
                width: 49%;
                max-width: 815px;

                span {
                    position: absolute;
                    display: block;
                    width: 45%;
                    max-width: 323px;
                    top: 90%;
                    left: 45%;

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }

        .creatif_1 {
            width: 47%;
            padding-bottom: 6%;

            p {
                width: 80%;
            }
        }

        .creatif_2 {
            max-width: 1370px;
            padding-top: 8%;

            p {
                font-family: $secondaryFont;
                font-style: normal;
                font-weight: 400;
                font-size: 2.604vw;
                line-height: 1;
                text-align: center;
                text-transform: uppercase;
            }
        }
    }

    .bloc_3 {
        .creative {
            padding-top: 4.3%;

            .creatif {
                width: 75%;
                max-width: 1256px;

                @media screen and(max-width: 1300px) {
                    width: 70%;
                }
            }

            .creatif_1 {
                padding-top: 6.1%;
                width: 28%;
                margin-left: -3%;

                @media screen and(max-width: 1300px) {
                    width: 30%;
                }

                .duo {
                    width: 99%;
                    line-height: 16px;

                    span {
                        font-style: normal;
                        font-weight: 600;
                        text-decoration: none;
                        font-size: 14px;
                        line-height: 14px;
                    }
                }
            }
        }

        .creatif_2 {
            max-width: 1800px;
            width: 100%;
            padding-top: 4.5% !important;

            h3 {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 29px;
                text-align: center;
                text-transform: uppercase;
            }

            p {
                font-family: $primaryFont;
                font-size: 5.208vw;
                line-height: .8;
                font-weight: 500;
                text-transform: capitalize;
                padding-top: 71px;

                .bold_1 {
                    text-transform: initial;
                    font-style: normal;
                }

                .ita {
                    font-family: $secondaryFont;
                    font-style: italic;
                    text-transform: uppercase;
                    font-weight: 400;
                }

                .normal {
                    font-style: normal;
                    font-weight: 400;
                    text-transform: uppercase;
                    font-family: $secondaryFont;
                }

                .bord_1,
                .bord_2,
                .bord_3,
                .bord_4 {
                    display: inline-block;
                    width: 1.8em;
                    height: .8em;
                    position: relative;
                    margin: 0 .2em;

                    &::after {
                        content: ' ';
                        display: block;
                        width: 100%;
                        height: 3px;
                        background-color: $black;
                        position: absolute;
                        top: 55%;
                    }
                }

                .bord_2 {
                    width: 1.4em;
                    height: .7em;
                }

                .bord_3 {
                    width: .7em;
                }

                .bord_4 {
                    width: .8em;
                }
            }
        }
    }

    .bloc_4 {
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;

        .marque_wrapper {
            padding-top: 86px;
            margin-bottom: 38px;

            img {
                width: 100%;
            }
        }

        .marque {
            display: flex;
            justify-content: space-between;
            padding: 0 2.8%;
            box-sizing: border-box;

            .wrapper_header {
                .num {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 29px;
                }

                h2 {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 80px;
                    line-height: 96px;
                    text-transform: uppercase;
                }
            }

            .wrapper_texte {
                max-width: 748px;
                width: 100%;
                padding-top: 161px;
                margin-left: 14.6%;
                box-sizing: border-box;

                p {
                    font-family: $primaryFont;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    text-align: justify;
                    margin-left: -6px;
                    padding-bottom: 40px;
                }

                a {
                    text-decoration: none;

                    span {
                        position: relative;
                        font-family: $secondaryFont;
                        font-style: italic;
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 29px;
                        color: $black;

                        &:after {
                            content: "";
                            position: absolute;
                            display: block;
                            top: 86%;
                            left: 0;
                            right: 0;
                            height: 1px;
                            background-color: $black;
                            transition: left .2s ease-in-out;
                        }
                    }

                    &:hover span:after {
                        left: 100%;
                    }
                }
            }
        }
    }

    .bloc_5 {
        .creative {
            padding-top: 4.4%;

            .creatif {
                width: 45%;

                img {
                    width: 100%;
                }
            }

            .creatif_1 {
                padding-top: 19.6%;
                padding-left: 4%;
                width: 40.01%;

                p {
                    padding-bottom: 42px;
                }

                a {
                    text-decoration: none !important;

                    &:hover span:after {
                        left: 100%;
                    }
                }

                span {
                    position: relative;
                    font-style: italic;
                    font-family: $secondaryFont;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 29px;
                    text-underline-offset: 6px;
                    color: $black;

                    &:after {
                        content: "";
                        position: absolute;
                        display: block;
                        top: 84%;
                        left: 0;
                        right: 0;
                        height: 1px;
                        background-color: $black;
                        transition: left .2s ease-in-out;
                    }
                }
            }
        }
    }

    .collection {
        width: 202px;
        margin: 80px auto 0;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }
    }
}

body.la_marque .bloc_3 .creative .creatif_1 h2 {
    white-space: nowrap;
}

//  PAGE PLAN SITE
body.footer_sitemap {
    .page_satellite {
        width: 98%;
        max-width: 1800px;
        margin: -8px auto;
        box-sizing: border-box;

        .satellite_title {

            .title_plan_site {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 500;
                font-size: 50px;
                line-height: 60px;
                text-transform: uppercase;
                letter-spacing: -0.9px;
            }
        }

        .satellite_content {
            display: flex;
            flex-wrap: wrap;
            margin-top: 35px;
            gap: 6rem;

            .sitemap_section {
                max-width: 237px;
                width: 100%;

                .categ_plan_site {
                    font-weight: 500;
                    font-size: 24px;
                    text-transform: uppercase;
                }

                .site_map_ul {
                    margin-top: 16px;

                    a {
                        color: $black;
                        text-decoration: none;

                        span {
                            font-family: $primaryFont;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 16px;
                        }
                    }
                }
            }

            #menu_univers,
            #menu_boutiques {
                a {
                    color: $black;
                    text-decoration: none;

                    span {
                        font-family: $primaryFont;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 24px;
                        text-transform: uppercase;
                    }
                }

                >a+div {
                    .sub_cat_name {
                        font-family: $primaryFont;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 24px;
                    }

                    div {
                        margin-top: 16px;

                        .sub_categories {
                            margin-top: 0px;

                            &:first-of-type {
                                margin-top: 10px;
                            }

                            a {
                                color: $black;
                                text-decoration: none;

                                span {
                                    font-family: $primaryFont;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: 16px;
                                    text-transform: capitalize;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}