/**
 * VARS
 */
$pre-header-height:         40px; // Pre-header height
$header-height:             89px; // Header height

.cache,
[v-cloak] {
    display: none;
}

body {
    padding-top: $header-height;

    &.bandeauDelivery {
        padding-top: calc(#{$pre-header-height} + #{$header-height});
    }

    &.homepage,
    &.lookBook {
        padding-top: 0;

        &.bandeauDelivery {
            padding-top: $pre-header-height;
        }
    }
}

/**
 * Display none on .dynasearchwrapper
 */
[v-cloak] {
    display: none;
}

/**
 * Ariane
 */
.main_ariane {
    width: 98%;
    max-width: 1800px;
    margin: 0 auto;
    font-size: 10px;

    .breadcrumbs {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 42px;
    }

    .breadcrumb {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #000;

        a {
            color: #000;
            text-decoration: none;
            text-transform: lowercase;

            &::first-letter {
                text-transform: uppercase;
            }
        }

        &.current {
            color: #989898;
        }

        &:after {
            content: '/';
            margin: 0 .6rem 0 .5rem;
        }

        &:last-child:after {
            display: none;
        }
    }
}

/**
 * Prices
 */
.price_tag,
.pricetag {
    .no_cents {
        display: none;
    }
}

/**
 * Account banner
 */
.account_banner {
    position: relative;
    width: 100%;
    max-width: 1800px;
    padding-bottom: calc(200 / 1800 * 100%);
    margin: 10px auto 0;
    background: transparent url(../img/bandeau_connexion.jpg) no-repeat center;
    background-size: 100% auto;

    .title {
        position: absolute;
        top: 49%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 50px;
        font-weight: 500;
        color: #fff;
        line-height: 1;
        text-transform: uppercase;
        letter-spacing: initial;
        white-space: nowrap;
        margin: 0;
    }
}

/**
 * Account main wrapper
 */
.wrapper_moncompte_main {
    width: 98%;
    max-width: 1280px;
    margin: 0 auto;

    img.img_error {
        display: none !important;
    }
}

/**
CROSS BUTTON FOR CLOSING ITEMS
 */
.closeBtn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    cursor: pointer;

    &:before,
    &:after {
        position: absolute;
        left: 7px;
        content: ' ';
        height: 16px;
        width: 1px;
        background-color: $black;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
}

/**
 * Global account styles
 */
.wrapper_moncompte {
    width: 98%;
    max-width: 1920px;
    margin: 0 auto;
}

/**
 * Trace for Good lightbox
 */

#traceBox {
    &.lightbox {
        top: 0;
        right: 0;
        left: initial;
        transform: none;
        width: 600px;
        height: 100%;
        

        .box_title {
            display: none;
        }

        iframe {
            width: 100%;
            height: 100%;
        }

        .close_pop {
            position: absolute;
            right: 34px;
            top: 80px;
            font-size: 0;
            width: 10px;
            height: 10px;
            background-image: url('../svg/spritesheet.svg');
            @include bgIconCoord(5, 6, 5);
            cursor: pointer;
        }
    }
}