// tunnel menu
.tunnel_track_pusher {
    .tunnel_track_wrapper {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        vertical-align: baseline;
    }
}

.tunnel_track {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 98%;
    max-width: 1280px;
    height: 40px;
    margin: 0.4rem auto 3rem;
    border-bottom: 1px solid $tertiary;

    span {
        text-transform: uppercase;
        font-weight: 00;
        font-size: 13px;
        color: #000;
    }

    .tunnel_step {
        position: relative;
        display: block;
        flex: 1;
        height: 100%;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.04rem;
        text-decoration: none;
        text-align: center;
        white-space: nowrap;

        &.on:after {
            background-color: $black;
            border: 1px solid $black;
        }

        &.passed:after {
            background-color: #000;
            width: 16px;
            height: 16px;
            background: transparent url(../svg/checked.svg) no-repeat center;
        }

        &.on:before,
        &.passed:before {
            content: "";
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 1px;
            background: black;
        }

        &:after {
            content: "";
            display: block;
            z-index: 3;
            width: 15px;
            height: 15px;
            border: 1px solid $tertiary;
            border-radius: 50%;
            position: absolute;
            bottom: -8px;
            left: 50%;
            transform: translateX(-50%);
            background: $white;
        }
    }
}

//Order registered
body.checkout.bankThanks,
body.checkout.bankThanks.mpos {
    .tunnel_track {
        .tunnel_step {
            &.on:after {
                background-color: #000;
                width: 16px;
                height: 16px;
                background: transparent url(../svg/checked.svg) no-repeat center;
            }
        }
    }
}

//Order registered END

.wrapper_mes_adresses {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1440px;
    margin: -1.1rem auto;
    flex-wrap: wrap;

    .left_side {
        padding-top: 18px;

        .w-address-input {
            width: 92%;
        }
    }
}

// tunnel header menu
.step_2.in_tunnel,
.body_login.in_tunnel,
.step_2_0.in_tunnel,
.checkout.in_tunnel {

    .wrapper_menu,
    .headerRightPart,
    .mention_retour,
    .header_mention {
        display: none;
    }

    .head_top {
        display: flex;
        justify-content: center;
        padding-top: 44px;
        padding-bottom: 25px;
        max-width: 1800px;
        margin: 0 auto;
        position: relative;
        height: 15px;
        box-sizing: border-box;
        transition-duration: 0.3s;
    }

    .tunnel_track_pusher {
        padding-top: 42px;
    }

    #outsite_ue_notice {
        margin: 2rem;
        text-align: center;
    }
}

#outsite_ue_notice {
    max-width: 1417px;
    width: 96%;
    margin: 0 auto 2rem;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    text-decoration: underline;
}

// tunnel left side
.wrapper_panier {
    display: flex;
    justify-content: space-between;
    width: 98%;
    max-width: 1440px;
    margin: -1.1rem auto 0;
    flex-wrap: wrap;

    .left_side {
        width: calc(100% - 450px);
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: fit-content;

        @media only screen and (max-width: 1187px) {
            width: 65%;
        }

        .cart_main_title.basket {
            order: -1;

            font-weight: 500;
            font-size: 50px;
            text-transform: uppercase;

            .nb_products {
                font-weight: 500;
                font-size: 16px;
                padding-left: 5px;
            }
        }

        .cartAssociations {

            position: relative;
            margin-right: 7.5%;
            order: 2;

            .title_gondole {
                color: $black;
                font-family: $primaryFont;
                font-size: 24px;
                font-weight: 500;
                line-height: normal;
                text-transform: uppercase;
                margin-bottom: 20px;
            }

            .cartAssocSwiper {
                width: 93%;
                margin: auto;
            }

            .swiper-cartassoc-prev,
            .swiper-cartassoc-next {
                width: 18px;
                height: 18px;
                background-image: url(../svg/spritesheet.svg);
                @include bgIconCoord(14, 6, 6);
                cursor: pointer;
            }

            .swiper-cartassoc-prev {
                position: absolute;
                left: 0;
                top: 50%;
                transform: rotate(180deg) translateY(-50%);
                z-index: 10;
            }

            .swiper-cartassoc-next {
                position: absolute;
                right: 0;
                top: 50%;
                z-index: 10;
                transform: translateY(-50%);
            }

            .swiper-cartassoc-prev.swiper-button-disabled,
            .swiper-cartassoc-next.swiper-button-disabled {
                opacity: .2;
            }

            .price_n_eclat_rect_wrapper {
                display: flex;
                align-items: center;
                margin-top: 6px;

                .item_price {
                    margin-top: 0;
                }

                .eclat_rect {
                    margin-left: 10px;
                }
            }

            .productColorFieldset {

                input[type=radio]+label {
                    width: 13px;
                    height: 13px;
                    border: 0.5px solid $tertiary;
                }

                input[type=radio]:checked+label {
                    box-shadow: none;
                    border-color: $black;
                }

                .prod_listes .choices_list {
                    position: fixed;
                    margin-top: -20px;
                }

            }

            .rollover_left {
                max-height: 47px;

                .productSizeFieldset {
                    min-height: 47px;
                }
            }

            .form_submit.bloc_add_color {
                width: 30px;
                background: transparent;

                .button.w-submit-button {
                    position: relative;
                    background: transparent;
                    border: none;

                    span {
                        display: none;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        top: 6px;
                        right: 0;
                        width: 14px;
                        height: 18px;
                        opacity: 1;
                        background-image: url(../svg/bag_icon.svg);
                        background-repeat: no-repeat;
                        background-size: 100% auto;
                        transition: all 0.2s ease-out;
                    }

                    &:hover {
                        &:after {
                            opacity: .4;
                        }
                    }
                }
            }
        }

        .push_container.pushBasketWrapper {
            order: 3;
            width: 92%;
            padding-bottom: 24px;
        }

        .cart_main_table.basket {
            order: 1;

            .dropdown_cart,
            .head_product_line {
                display: none;
            }

            padding: 31px 0;

            .cart_product_line {
                &:last-child {
                    border-bottom: 0;
                    margin-bottom: 0;
                }
            }
        }

        // livraison-choix
        .elem_tunnel {
            width: 100%;
            max-width: calc(100% - 400px);
        }

        .wrapper_form_adresse {
            width: 100%;

            .w-group-label {
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 29px;
            }
        }
    }
}

// tunnel cart product
.step_1 {
    .cart_main_table {
        &.basket {
            margin-right: 8.5%;

            .cart_product_line {
                display: flex;
                padding-bottom: 19px;
                margin-bottom: 11px;
                border-bottom: 1px solid $tertiary;

                &:nth-of-type(2) {
                    padding-bottom: 0;
                }

                .dragOverlay {
                    display: none;
                }

                &:last-child {
                    border-bottom: 0;
                }

                .cart_product_pic {
                    width: 120px;
                    height: 160px;
                    padding-top: 10px;

                    img {
                        width: 100%;
                        max-width: 120px;
                        height: auto;
                    }
                }

                .cart_product_desc {
                    position: relative;
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    padding: 17px;

                    a {
                        text-decoration: none;
                        font-weight: 500;
                        font-size: 13px;
                        color: #000;
                    }

                    .deignation {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding-bottom: 5px;

                        .cart_product_title {
                            max-width: 245px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: normal;
                        }

                        .cart_product_color,
                        .cart_product_size {
                            font-weight: 400;
                            font-size: 13px;
                            text-transform: none;
                            line-height: 16px;
                        }

                        .cart_product_size {
                            padding-top: 45px;
                        }

                        .cart_product_color {
                            padding-bottom: 13px;

                            label:after {
                                content: ":";
                            }
                        }
                    }

                    .modifications {
                        display: flex;
                        align-items: center;

                        .cart_product_sizecol {
                            display: none;
                        }

                        form.qte_selector {
                            display: flex;
                            flex-wrap: nowrap;
                        }

                        .product_quantity_change {
                            padding-right: 78px;
                            padding-top: 7px;

                            @media only screen and (max-width: 1287px) {
                                padding-right: 8.063vw;
                            }

                            label {
                                display: none;
                            }

                            .change_qte.moreQte {
                                width: 22px;
                            }

                            .selected_qte {
                                width: 14px;
                            }

                            button {
                                background-color: transparent;
                                cursor: pointer;
                                border-width: 1px;
                            }

                            input {
                                background: 0 0;
                                border: none !important;
                                text-align: center;
                                outline: 0;
                                box-shadow: none;
                                padding: 0 0.5rem;
                            }
                        }

                        .cart_product_modify {
                            text-transform: none;

                            a {
                                text-decoration: underline;
                            }

                            .product_mod {
                                padding-right: 46px;
                            }

                            .product_towish {
                                padding-right: 25px;

                                &:before {
                                    content: "";
                                    width: 20px;
                                    height: 20px;
                                    position: absolute;
                                    margin-left: -29px;
                                    background-image: url(../svg/spritesheet.svg);
                                    background-repeat: no-repeat;
                                    background-size: 600% auto;
                                    background-position: -18px 0px;
                                }

                                &.existToWishlistButton {
                                    &::before {
                                        background-position: 80% 60%;
                                    }
                                }
                            }

                            .product_del {
                                &::before {
                                    content: "";
                                    width: 20px;
                                    height: 20px;
                                    position: absolute;
                                    background-image: url(../svg/spritesheet.svg);
                                    background-repeat: no-repeat;
                                    background-size: 600% auto;
                                    background-position: -80px 0px;
                                }

                                span {
                                    display: none;
                                }
                            }
                        }
                    }

                    @media only screen and (max-width: 1370px) {

                        .modifications .cart_product_modify {
                            .product_del {
                                position: absolute;
                                top: 0;
                                right: 0;
                                display: block;

                                &::before {
                                    position: initial;
                                    display: block;
                                }
                            }

                            .product_towish {
                                position: absolute;
                                right: 0;
                                bottom: 1.75rem;
                                padding-right: 0;
                            }

                            .product_mod {
                                position: absolute;
                                bottom: 0;
                                right: 0;
                                padding-right: 0;

                                span {
                                    padding-right: 0;
                                }
                            }
                        }
                    }

                    @media only screen and (max-width: 1200px) {
                        justify-content: space-between;

                        .modifications .cart_product_modify {
                            .product_towish {
                                font-size: 0;

                                &::before {
                                    position: initial;
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .tunnel_sticky {

        display: flex;
        flex-direction: column;

        .pushBasketWrapper {
            order: 8;
        }
    }
}

// tunnel wishlist
.cart_main_title.wishlist {
    order: 4;
    margin-top: 18px;
    padding-bottom: 12px;
    font-weight: 500;
    font-size: 24px;
    text-transform: uppercase;

    .nb_products {
        font-weight: 500;
        font-size: 16px;
        padding-left: 10px;
    }
}

.cart_main_table.wishlist {
    order: 6;
    display: flex;
    flex-wrap: wrap;
    margin-right: 4%;
    justify-content: space-between;

    .head_product_line {
        display: none;
    }

    .wishlist_persis_info {
        width: 100%;
        margin-bottom: 20px;
        font-family: $primaryFont;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        a {
            text-decoration-line: underline;
            color: #000;
        }
    }

    .cart_product_line {
        width: 45.77%;
        margin-bottom: 2.5rem;
        display: flex;
        position: relative;

        @media screen and(max-width: 1450px) {
            width: 70%;
        }

        &:nth-child(2n + 1) {
            margin-right: 0;
        }

        &:nth-child(2n + 2) {
            margin-right: 42px;

            @media screen and(max-width: 1450px) {
                margin-right: 0;
            }
        }

        .cart_product_pic {
            width: 120px;
            height: 160px;

            img {
                max-width: 120px;
                height: auto;
            }
        }

        .cart_product_desc {
            position: relative;
            display: flex;
            flex-direction: column;
            row-gap: 14px;
            justify-content: space-between;
            width: calc(100% - 100px);
            height: fit-content;
            height: 111px;
            margin: 8px 10px;
            padding: 6px 6px;
            box-sizing: border-box;

            .cart_product_modify {
                position: absolute;
                top: 119px;
                display: flex;
                width: 100%;

                .wishToBasket {
                    width: 226px;
                    color: #000;
                    font-weight: 400;
                    font-size: 13px;

                    &:before {
                        content: "";
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        bottom: -2px;
                        left: -2px;
                        cursor: pointer;
                        background-image: url(../svg/spritesheet.svg);
                        background-repeat: no-repeat;
                        background-size: 600% auto;
                        background-position: -60px 0px;
                    }

                    span {
                        margin-left: 26px;
                    }
                }

                .wishToProduct {
                    color: #000;
                    font-weight: 400;
                    font-size: 13px;
                }
            }

            .remove_wish {
                position: absolute;
                top: -7.78rem;
                right: 0;
                cursor: pointer;
                width: 0.875rem;
                height: 0.875rem;
                background-image: url(../svg/spritesheet.svg);
                background-repeat: no-repeat;
                background-size: 600% auto;
                background-position: -56px 0px;

                span {
                    display: none;
                }
            }

            a.product_mod {
                text-decoration: none;
                color: #000;
                font-family: $primaryFont;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
            }

            .cart_product_color {
                order: 4;
                position: relative;
                top: -31px;
                font-weight: 400;
                font-size: 13px;
                margin-top: 0.4rem;

                label:after {
                    content: ":";
                }
            }

            .cart_product_size {
                order: 3;
                position: relative;
                top: -18px;
                font-weight: 400;
                font-size: 13px;
            }

            .wrapper_cart_product_price {
                order: 2;
                position: relative;
                display: flex;
                top: -12px;
            }
        }
    }
}

p.tunnel_wish_nostock {
    font-size: 13px;
}

.step_1,
.checkout {
    #main_cart_wrapper {
        padding-top: 15px;
    }
}

/*
 * Tunnel livraison-choix
 */

.step_2.in_tunnel {
    .wrapper_panier {
        .left_side {
            width: 62.8%;
            max-width: 903px;
            margin: 9px 7px;
            display: flex;
            flex-direction: column;
        }

        .right_side {
            .form_submit.full {
                margin-top: 0px;
            }
        }
    }

    .delivery .w-has-tel .w-tel-input {
        .w-input-element:not([type="radio"]):not([type="checkbox"]) {
            padding-left: 5.5em;
        }

        .w-input-label {
            padding-left: 1.1em;
        }
    }
}

.step_2.in_tunnel {
    .bloc_livraison {
        position: relative;
        display: block;
        border: 1px solid #b1b1b1;
        margin-bottom: 64px;
        padding: 44px 23px;
        box-sizing: border-box;

        &.active {
            input.choix_livraison {
                &::after {
                    content: "";
                    display: block;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background-color: #000;
                    box-shadow: inset 0 0 0 2px $white;
                    border: 1px solid #000;
                }
            }
        }

        .delivery_form_title {
            font-weight: 500;
            font-size: 16px;
            padding-bottom: 10px;
            text-align: center;
            text-transform: uppercase;
        }

        .choix_delivery {
            position: relative;
            display: flex;
            flex-direction: column;
            padding-top: 3px;

            .choix_livraison {
                // petit cercle input
                position: absolute;
                top: 2rem;
                left: -35px;
                cursor: pointer;
            }

            .transporteur_info {
                // contenu, lignes
                order: 2;

                position: relative;
                display: flex;
                flex-direction: column;

                .title_type_livraison {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: fit-content;
                    height: 19px;
                    padding: 0 2em;
                    top: -102px;
                    background-color: $white;
                    font-size: 16px;
                    font-weight: 500;
                    text-transform: uppercase;
                    order: 1;

                    .delivery_picto {
                        font-size: 0;
                    }

                    @media screen and (max-width: 1024px) {
                        justify-content: center;
                        text-align: center;
                        white-space: nowrap;
                    }
                }

                .transporteur_delay {
                    order: 3;
                    font-weight: 400;
                    font-size: 13px;
                    padding-top: 11px;
                }

                .show_postal {
                    cursor: pointer;
                    order: 2;
                }
            }

            .infos_livr_domicile {
                order: 1;
            }

            .right_delivery {
                order: 3;
                text-align: right;
            }

            .civilite {
                font-weight: 500;
                font-size: 13px;
                text-transform: uppercase;
                padding-top: 6px;
            }

            .adresse {
                font-weight: 400;
                font-size: 13px;
                padding-top: 3px;
                padding-bottom: 8px;
            }

            .show_postal {
                text-decoration: underline;
                padding-top: 3px;
                font-weight: 400;
                font-size: 13px;

                &:hover {
                    text-decoration: none;
                }
            }

            .price_tag {
                display: flex;
                justify-content: flex-end;
            }

            &[data-id-transporteur="1"],
            &[data-id-transporteur="39"],
            &[data-id-transporteur="42"] {
                .title_type_livraison:after {
                    display: block;
                    content: "";
                    width: 113px;
                    height: 24px;
                    position: absolute;
                    top: 29px;
                    cursor: pointer;
                    background: transparent url(../svg/colissimo.svg) no-repeat center;
                }
            }

            &[data-id-transporteur="13"],
            &[data-id-transporteur="37"] {
                .title_type_livraison:after {
                    display: block;
                    content: "";
                    width: 113px;
                    height: 24px;
                    position: absolute;
                    top: 29px;
                    cursor: pointer;
                    background: transparent url(../svg/chronopost.svg) no-repeat center;
                }
            }

            &[data-id-transporteur="36"] {
                .title_type_livraison:after {
                    display: block;
                    content: "";
                    width: 113px;
                    height: 24px;
                    position: absolute;
                    top: 29px;
                    cursor: pointer;
                    background: transparent url(../svg/fedex_international.svg) no-repeat center;
                    background-size: contain;
                }
            }

            &[data-id-transporteur="26"] {
                .title_type_livraison:after {
                    display: block;
                    content: "";
                    width: 107px;
                    height: 30px;
                    position: absolute;
                    top: 29px;
                    cursor: pointer;
                    background: transparent url(../svg/notshy.svg) no-repeat center;
                    background-size: contain;
                }
            }
        }

        .wrapper_select_adr {
            .w-dropdown {
                border-radius: 0;
            }
        }
    }

    fieldset {
        padding-bottom: 30px;
    }
}

// tunnel right side
.step_1 #cart_total {
    .total_produit {

        margin-top: 26px;

        label {
            text-decoration: none;
        }
    }
}

.wrapper_locate_relais {
    .store_no_result {
        display: none;
        text-align: center;
        font-size: 13px;
        padding: 1em 0 0;
    }

    .displayed_result {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 1rem;

        &.results {
            opacity: 1;
        }
    }

    .map_canvas {
        width: 65%;
        height: 550px;

        .info_popup {
            .button.secondary {
                border: 0;
                text-transform: initial;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .sidebar-right {
        width: 45%;
        height: 550px;
        opacity: 1 !important;
    }

    table.details {
        width: 80%;
        margin: 0 auto;

        tr {
            td {
                padding-top: 1em;
                border-bottom: 1px solid #b1b1b1;
                padding-bottom: 1rem;
            }

            &:first-child td {
                padding-top: 0;
            }
        }
    }

    .distance {
        display: none;
    }

    .kpname,
    .address,
    .relayLink {
        font-size: 13px;
    }

    .kpname {
        font-weight: 500;
        text-transform: uppercase;
    }

    .address {
        margin: 0.5rem 0;
    }

    .btn_container {
        height: auto;
    }

    .relayLink {
        color: black;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.step_2 #cart_total,
.step_2_0 #cart_total {
    .total_produit {
        padding-top: 24px;

        label {
            border-bottom: 1px solid black;

            cursor: pointer;

            &:after {
                content: "+";
                margin-left: 0.5rem;
            }
        }

        .order_gift_form {
            display: none;

            p,
            progress {
                display: none;
            }
        }
    }

    .upselling.price {
        display: none;
    }

    .total_produit.open {
        label {
            &:after {
                content: "-";
                margin-left: 0.5rem;
                text-decoration: none;
            }
        }
    }

    .total_produit_dropdown {
        display: none;
    }

    .used_cp,
    .promo,
    .order_gift_form {
        display: none;
    }

    .used_cp {
        display: inline-flex;
        margin-top: 8px;
    }
}

.checkout #cart_total {
    min-height: 106px;

    .total_produit {
        padding-top: 24px;

        label {
            cursor: pointer;
        }
    }

    .total_produit_dropdown,
    .promo,
    .order_gift_form {
        display: none;
    }

    .total {
        margin-top: 31px;
    }

    .formPanier_container {
        padding-top: 2px;
        padding-bottom: 3px;

        form .form_submit.full {
            width: 100% !important;
        }

        .shipment_cgv_link {
            display: inline;
        }
    }

    .bill_line.total_produit.toggle label {
        text-decoration: underline;

        &::after {
            content: " +";
        }
    }

    .bill_line.total_produit.toggle.open label::after {
        content: " -";
    }
}

.wrapper_panier .right_side {
    width: 35%;
    max-width: 450px;
    overflow: hidden;

    .main_table {
        padding: 38px;
        border: 1px solid $tertiary;
        position: relative;
        height: fit-content;
        display: flex;
        flex-direction: column;
        height: fit-content;

        .w-form .w-submit-button span {
            text-transform: lowercase;
        }

        .total_produit {
            order: 1;

            display: flex;
            font-weight: 400;
            font-size: 13px;
            justify-content: space-between;
        }

        .total_produit_dropdown {
            order: 2;

            display: block;
            padding: 34px 2px 0;

            .cart_product_line {
                display: flex;
                padding-bottom: 8px;

                .cart_product_desc {
                    padding: 14px;

                    .deignation {
                        .product_mod {
                            text-decoration: none;
                            font-weight: 500;
                            font-size: 13px;
                            color: #000;
                        }

                        .wrapper_cart_product_price {
                            padding-top: 8px;
                            padding-bottom: 8px;
                            font-weight: 500;
                            font-size: 16px;
                        }

                        .cart_product_size,
                        .cart_product_color {
                            padding-top: 4px;
                            font-weight: 400;
                            font-size: 13px;
                        }
                    }

                    .product_quantity_change,
                    .cart_product_sizecol,
                    .product_mod span {
                        display: none;
                    }

                    .product_del {
                        span {
                            display: none;
                        }
                    }
                }
            }
        }

        .total,
        .promo,
        .discount,
        .used_cp {
            display: flex;
            justify-content: space-between;
            font-weight: 400;
            font-size: 13px;
        }

        .promo {
            order: 3;
            display: flex !important;
            padding-top: 8px;
        }

        .discount {
            order: 4;

            padding-top: 10px;

            &.hide_shippingfees {
                display: none;
            }
        }

        .order_gift_form {
            order: 7;

            padding-top: 26px;
        }

        .formPanier_container {
            order: 8;

            padding-top: 27px;
            padding-bottom: 39px;
            width: 100%;

            .w-input-container.checkCgv {
                padding-bottom: 50px;

                .shipment_cgv_link {
                    &:before {
                        width: 25px;
                        height: 13px;

                        @media screen and (max-width: 1024px) {
                            width: 30px;
                        }
                    }

                    span {
                        padding-top: 42px;
                        padding-right: 75px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;

                        @media screen and (max-width: 1024px) {
                            padding-right: 0;
                        }

                        a {
                            color: #000;
                        }
                    }

                    @media screen and (max-width: 1024px) {
                        display: flex;
                        align-items: baseline;
                    }
                }

                &:after {
                    display: none;
                    position: relative;
                    top: 50px;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 12px;
                    content: "Veuillez cocher la case pour continuer";
                    color: #ff0000;
                }
            }

            #err_cgv_compulsory {
                padding: 0 0 10px;
                font-weight: 400;
                font-size: 10px;
                line-height: 21px;
                color: #ff0000;
            }
        }

        .recap_cart_title {
            font-weight: 500;
            font-size: 40px;

            @media screen and (max-width: 1024px) {
                font-size: 24px;
            }

            span {
                display: block;
                padding-top: 8px;
            }
        }

        .used_cp {
            order: 5;
            margin-top: 2px;
            margin-right: 13px;
            margin-bottom: 5px;

            //Gestion affichage du code promo
            @media screen and (max-width: 1200px) {
                margin-top: 10px;

                .code_promo {
                    display: flex;

                    a {
                        position: relative;

                        span {
                            position: absolute;
                            top: -8px;
                        }
                    }
                }
            }

            a {
                text-decoration: none;
                color: #000;
                font-size: 23px;
                padding-left: 10px;

                span {
                    position: absolute;
                    margin-top: 2px;
                }
            }

            label {
                margin-top: auto;
            }
        }

        .total {
            order: 6;
            border-top: 1px solid $tertiary;
            margin-top: 26px;
            padding-top: 15px;
            font-weight: 500;
        }

        .coupon {
            order: 7;

            border: 1px solid $tertiary;
            margin-top: 26px;

            button.w-submit-button {
                background: $white;
                color: #000;
                border: none;
            }

            input {
                border: none;
                outline-width: 0;
            }
        }
    }

    .bloc_question {
        padding: 7px 40px 25px 40px;

        .title_question {
            text-transform: uppercase;
            padding-bottom: 8px;
            font-weight: 500;
            font-size: 13px;
            padding-top: 25px;
        }

        .intitule_question span {
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
        }

        .texte_question {
            display: none;
            margin: 10px 0 18px;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            padding: 0 15px;

            div {
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                padding: 0 15px;
            }

            .questab {
                width: 100%;

                td {
                    border: 1px solid $tertiary;
                    padding: 4px;
                }
            }

            b {
                font-weight: 500;
            }

            a {
                color: $black !important;
            }
        }

        article {
            padding-bottom: 6px;
            cursor: pointer;
        }

        .questions_frequentes {
            font-size: 13px;
            line-height: 9px;
            padding-top: 25px;

            .faq_main_title {
                font-weight: 500;
                text-transform: uppercase;
            }

            ul {
                padding-top: 10px;
            }

            #other_question_1 {
                padding-top: 5px;
            }
        }
    }
}

.upselling.price {
    order: 9;
    margin: .8rem auto 5px;

    p {
        font-weight: 400;
        font-size: 12px;
        padding-bottom: 2px;
    }

    progress {
        -webkit-appearance: none;
        width: 100%;
        height: 3px;
    }

    ::-webkit-progress-bar {
        background-color: $tertiary;
    }

    ::-webkit-progress-value {
        background-color: #000;
    }
}

.checkout.in_tunnel {
    .bank_left_side {
        width: 65%;
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: 1446px) {
            width: 65%;
        }

        p.txt_recommencer_paiement {
            margin-bottom: 30px;
        }

        #bloc_erreur_order:has(.errorBank) {
            border: 1px solid $darkGrey;
            margin: 2px 8.6vmin 3.6vmin 7px;
            padding: 20px 23px;
            text-align: center;
        }
        
        .displayNone {
            display: none;
        }

        .errorBank {
            font-size: 13px;
            line-height: 16px;
        }

        .wrapper_bloc_banque {
            &.credit_card {
                position: relative;
                min-height: 5.45rem;
                margin: 10px 8.6vmin 3.6vmin 7px;
                padding: 2.5px 21px 15px 21px;
                border: 1px solid $tertiary;

                &:not(.actif) {
                    #hipay-3-form {
                        display: none;
                    }
                }

                .cards-wrapper {
                    margin: 0.5rem 0 1.5rem;
                }

                .bank_title {
                    text-align: center;

                    span {
                        position: relative;
                        top: -13px;
                        text-transform: uppercase;
                        background-color: $white;
                        padding: 0 30px;
                    }
                }

                .total_transaction {
                    padding-top: 8px;
                    padding-bottom: 5px;

                    .pricetag {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                    }
                }

                .radio {
                    position: absolute;
                    top: 50%;
                    left: -8px;
                    width: 14px;
                    height: 14px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $white;
                    border: 1px solid $darkGrey;
                    border-radius: 50%;
                    cursor: pointer;

                    &::after {
                        content: "";
                        display: block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: transparent;
                        transition: background-color .3s ease-out;
                    }
                }

                &.actif {
                    border: 1px solid $darkGrey;

                    .radio {

                        &::after {
                            background-color: #000;
                        }
                    }

                    .bank_subtitle {
                        padding-top: 2%;
                    }

                    .bank_subtitle,
                    .total_transaction,
                    .cards-wrapper {
                        position: relative;
                        top: -14px;
                    }
                }

                .cards-wrapper img {
                    width: 40px;
                    height: auto;
                }

                .form-submit input#payment-submit {
                    width: 20px;
                    height: 10px;
                }
            }

            &.sofort,
            &.paypal {
                position: relative;
                min-height: 5.45rem;
                margin: 10px 8.6vmin 3.6vmin 7px;
                padding: 2.5px 21px 15px 21px;
                border: 1px solid $tertiary;

                .radio {
                    position: absolute;
                    top: 40%;
                    left: -8px;
                    width: 14px;
                    height: 14px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $white;
                    border: 1px solid $darkGrey;
                    border-radius: 50%;
                    cursor: pointer;

                    &::after {
                        content: "";
                        display: block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: transparent;
                        transition: background-color .3s ease-out;
                    }
                }

                &.actif {
                    border: 1px solid $darkGrey;

                    .radio {

                        &::after {
                            background-color: #000;
                        }
                    }
                }

                .bloc_bank_contents {
                    display: block;
                    text-align: center;

                    .bank_title span {
                        position: relative;
                        padding: 0 15px;
                        top: -14px;
                        background-color: $white;
                    }

                    .logo_wrapper input {
                        position: relative;
                        top: -50px;
                        left: 24px;
                        background-color: $white;
                        padding: 0 25px 0 10px;
                    }

                    p.bank_subtitle {
                        text-decoration: underline;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 16px;
                        padding-top: 5px;
                        cursor: pointer;

                        &.loadEnd {
                            padding-top: 0;
                        }

                        strong {
                            font-weight: 400;
                            cursor: pointer;
                        }

                        &.loader_paypal {
                            background: $white url(../svg/three_dots_black.svg) no-repeat center;
                            background-size: auto 62%;
                            font-size: 0;
                            cursor: default;
                            margin-top: 5px;
                        }
                    }
                }
            }

            &.bancontact {
                position: relative;
                min-height: 5.45rem;
                margin: 10px 8.6vmin 3.6vmin 7px;
                padding: 2.5px 21px 15px 21px;
                border: 1px solid $tertiary;

                .radio {
                    position: absolute;
                    top: 40%;
                    left: -8px;
                    width: 14px;
                    height: 14px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $white;
                    border: 1px solid $darkGrey;
                    border-radius: 50%;
                    cursor: pointer;

                    &::after {
                        content: "";
                        display: block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: transparent;
                        transition: background-color .3s ease-out;
                    }
                }

                &.actif {
                    border: 1px solid $darkGrey;

                    .radio {

                        &::after {
                            background-color: #000;
                        }
                    }
                }

                .bloc_bank_contents {
                    display: block;
                    text-align: center;

                    .title_wrapper {
                        position: relative;

                        &::after {
                            content: "";
                            display: inline-block;
                            background-image: url(../img/banque/logo/bancontact.svg);
                            background-repeat: no-repeat;
                            background-size: contain;
                            width: 88px;
                            height: 40px;
                            position: absolute;
                            top: 12px;
                            left: 50%;
                            transform: translateX(-50%);
                            cursor: pointer;
                        }
                    }

                    .bank_title span {
                        position: relative;
                        padding: 0 15px;
                        top: -14px;
                        background-color: $white;
                    }

                    .logo_wrapper input {
                        position: relative;
                        top: -50px;
                        left: 24px;
                        background-color: $white;
                        padding: 0 25px 0 10px;
                    }

                    p.bank_subtitle {
                        text-decoration: underline;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 16px;
                        padding-top: 45px;
                        cursor: pointer;

                        &.loadEnd {
                            padding-top: 0;
                        }

                        strong {
                            font-weight: 400;
                        }

                        span {
                            em {
                                display: none;
                            }
                        }
                    }

                    #inputBANCONTACT {
                        display: none;
                    }

                }
            }

            &.sofort .bloc_bank_contents p.bank_subtitle {
                padding-top: 5px;
            }

            &.giropay {
                position: relative;
                min-height: 5.45rem;
                margin: 10px 8.6vmin 3.6vmin 7px;
                padding: 25px 21px 25px 21px;
                border: 1px solid $tertiary;

                .radio {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -8px;
                    width: 14px;
                    height: 14px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $white;
                    border: 1px solid $darkGrey;
                    border-radius: 50%;
                    cursor: pointer;

                    &::after {
                        content: "";
                        display: block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: transparent;
                        transition: background-color .3s ease-out;
                    }
                }

                &.actif {
                    border: 1px solid $darkGrey;

                    .radio {

                        &::after {
                            background-color: #000;
                        }
                    }
                }

                .bloc_bank_contents {
                    display: block;
                    width: 100%;
                    text-align: center;
                    position: relative;

                    .bank_title {
                        position: absolute;
                        padding: 0 15px;
                        top: -37px;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: $white;

                        &::after {
                            content: "";
                            background: url('../img/banque/giropay-logo.png') 0 0 no-repeat;
                            background-size: contain;
                            width: 75px;
                            height: 56px;
                            display: block;
                            position: relative;
                            left: 15px;
                        }
                    }


                    p.bank_subtitle {
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 16px;
                        padding-top: 32px;
                    }

                    #hipay_issuer_bank_id {
                        height: 30px;
                        border: 1px solid #b1b1b1;
                        padding: 0 1.1em;
                        box-sizing: border-box;
                        margin-top: 12px;
                    }

                    #btn_cmd_valid {
                        height: 30px;
                        padding: 0 15px;
                        position: relative;
                        top: 1px;

                    }
                }
            }

            &.alma {
                position: relative;
                min-height: 5.45rem;
                margin: 10px 8.6vmin 3.6vmin 7px;
                padding: 2.5px 21px 15px 21px;
                border: 1px solid $tertiary;

                #alma-payment-2,
                #alma-payment-3 {
                    display: none;
                }

                &.actif {
                    border-color: $black;

                    .radio::after {
                        background-color: $black;
                    }

                    #alma-payment-2,
                    #alma-payment-3 {
                        display: block;
                    }

                    .bank_subtitle {
                        display: none;
                    }
                }

                .radio {
                    position: absolute;
                    top: 40%;
                    left: -8px;
                    width: 14px;
                    height: 14px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $white;
                    border: 1px solid $darkGrey;
                    border-radius: 50%;
                    cursor: pointer;

                    &::after {
                        content: "";
                        display: block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: transparent;
                        transition: background-color .3s ease-out;
                    }
                }

                .bloc_bank_contents {
                    display: block;
                    text-align: center;

                    .title_wrapper {
                        position: relative;

                        &::after {
                            content: "";
                            display: inline-block;
                            background-image: url(../img/alma.svg);
                            background-repeat: no-repeat;
                            background-size: contain;
                            width: 60px;
                            height: 30px;
                            position: absolute;
                            top: 20px;
                            left: 50%;
                            transform: translateX(-50%);
                            cursor: pointer;
                        }
                    }

                    .bank_title>span {
                        position: relative;
                        top: -14px;
                        display: flex;
                        width: fit-content;
                        margin: 0 auto;
                        padding: 0 15px;
                        background-color: #fff;

                        p {
                            margin-left: 4px;
                        }
                    }

                    p.bank_subtitle {
                        text-decoration: underline;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 16px;
                        padding-top: 45px;
                        cursor: pointer;

                        strong {
                            font-weight: 400;
                        }
                    }
                }
            }

            &.bloc_sending_link,
            &.qr_code {
                position: relative;
                display: block;
                padding: 2rem;
                margin-bottom: 3.5rem;
                border: 1px solid $tertiary;
                box-sizing: border-box;

                .title_wrapper {
                    position: absolute;
                    top: -10px;
                    left: 50%;
                    transform: translateX(-50%);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: fit-content;
                    height: 19px;
                    font-weight: 500;
                    text-transform: uppercase;
                    padding: 0 2em;
                    order: 1;
                    background-color: $white;

                    @media screen and (max-width: 1024px) {
                        justify-content: center;
                        text-align: center;
                        white-space: nowrap;
                    }
                }

                .title_wrapper_fold {

                    .mode_email,
                    .mode_sms {
                        .top {
                            .mode_title {
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 16px;
                                text-transform: uppercase;
                                padding-bottom: 8px;
                            }
                        }
                    }
                }
            }

            &.bloc_sending_link {
                .title_wrapper_fold {
                    .mode_email {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        gap: 1rem;
                        padding-bottom: 1rem;
                        border-bottom: 0.1px solid $tertiary;

                        .info_email {
                            width: 70%;
                            margin: 0;

                            span {
                                &.top {
                                    label {
                                        margin-top: 0.5rem;
                                    }
                                }
                            }
                        }

                        .loader_container {
                            width: 30%;
                            height: 48px;
                        }
                    }

                    .mode_sms {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        gap: 1rem;
                        padding-top: 1rem;

                        .info_sms {
                            width: 70%;
                            margin: 0;

                            span {
                                &.top {
                                    label {
                                        margin-top: 0.5rem;
                                        border: 1px solid $tertiary;
                                        padding: 0.3rem 0;

                                        .intl-tel-input {
                                            padding: 0.5rem 0;

                                            #client_tel {
                                                border: none;
                                                margin-left: 0.5rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .loader_container {
                            width: 30%;
                            height: 48px;
                        }
                    }
                }
            }

            &.qr_code {
                .title_wrapper_fold {
                    width: fit-content;
                    margin: 0 auto;
                }
            }
        }

        #bloc_payment_cancel {
            .cancelPayment {
                padding: 1rem 0;
            }
        }

        #paymentLinkSent,
        #paymentLinkSentQrCode {
            p {
                margin-bottom: 1rem;
            }

            .cancel_validation_payment {
                button {
                    padding: 1rem 0 !important;
                }
            }
        }
    }

    .right_side {
        .wrapper_payment {
            .payment_addr_wrapper {
                position: relative;
                display: flex;
                flex-direction: column;

                .title {
                    order: 2;

                    font-weight: 500;
                    font-size: 13px;
                    text-transform: uppercase;
                }

                .address {
                    order: 3;

                    font-weight: 400;
                    font-size: 13px;

                    .name {
                        padding-top: 7px;
                    }

                    .nmbr {
                        padding-top: 2px;
                    }

                    .phone {
                        padding-top: 2px;
                    }

                    .address_mod {
                        span {
                            position: relative;
                            top: -33px;
                            text-decoration: underline;
                            text-decoration-color: #000;
                        }
                    }
                }

                .address_mod {
                    order: 1;
                    text-decoration: none;

                    span {
                        position: relative;
                        padding-left: 280px;
                        top: 12px;
                        font-weight: 400;
                        font-size: 13px;
                        color: #000;
                        text-decoration: underline;
                        text-decoration-color: #000;
                    }
                }

                &.billing {
                    padding-top: 12px;

                    .address_mod {
                        a span {
                            color: #000;
                            text-decoration-color: #000;
                        }
                    }
                }
            }

            .addr_verif_message span {
                font-size: 0.75rem;
                color: $red;
            }
        }

        .main_table {
            .upselling {
                display: none;
            }
        }
    }

    .bank_right_side {
        .top {
            padding-top: 20px;

            h3 {
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                text-transform: uppercase;
                padding-bottom: 8px;
            }

            p {
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
            }
        }

        p.bankDeliveryReminder {
            font-size: 0.635rem;
            color: $red;
            margin: 1.5rem 0 1rem;
        }
    }
}

/* APPLE PAY */
#hipay_applepay_bloc {
    position: relative;
    min-height: 5.45rem;
    margin: 10px 8.6vmin 3.6vmin 7px;
    padding: 2.5px 21px 15px 21px;
    border: 1px solid $tertiary;

    &.actif {
        border: 1px solid black;
        transition: border 0.2s ease-out;

        .radio {
            &:after {
                background-color: #000;
                border: 1px solid #000;
            }
        }
    }

    #hipay-apple-pay-button {
        position: relative;
        height: 1.5rem;
        max-width: 20rem;
        margin: 1rem auto;
        padding: 0;
        overflow: hidden;
        transform: translateY(30px);

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 5.2rem;
            height: 6.95rem;
            z-index: 2;
            background-color: $white;
        }

        &:after {
            left: inherit;
            right: 0;
            width: 5.3rem;
        }

        iframe {
            position: absolute;
            top: -5px;
            left: 0;
            right: 0;
            margin: auto -0.2rem;
            width: 102%;
            height: 2rem;
        }
    }

    >.radio {
        position: absolute;
        top: 50%;
        left: -8px;
        width: 14px;
        height: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $white;
        border: 1px solid $darkGrey;
        border-radius: 50%;
        cursor: pointer;
        transform: translateY(-50%);

        &::after {
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: transparent;
            transition: background-color .3s ease-out;
        }
    }

    .title_wrapper_fold {
        margin-top: -1.2rem;

        .bank_title {
            margin: 0 auto;
            width: 150px;
            text-align: center;
            background: #fff;
            transform: translateY(7px);
        }

        .total_transaction {
            display: none;
        }
    }
}

form#hipay-3-form {
    padding: 0 0 1rem 0;

    .form_line.saveAlias {
        display: flex;
        align-items: center;
        height: 2em;

        label {
            margin-left: 0.5rem;
        }
    }
}

.thanksWrapper .thanksRecap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 98%;
    max-width: 1440px;
    margin: -1.1rem auto;

    .thanksLeft {
        width: 60%;

        @media only screen and (max-width: 1340px) {
            padding-right: 1rem !important;
        }

        .thanksLeftWrapper {
            img {
                max-width: 100%;
            }
        }
    }

    .thanksRight {
        width: 40%;
        text-align: left;

        .thanksRightWrapper {
            border: 1px solid $tertiary;
            margin: 38px 7px 32px;
            padding: 40px;
            font-weight: 400;
            font-size: 13px;

            @media screen and (max-width: 1024px) {
                margin: unset;
            }

            p {
                margin-bottom: 0.19rem;

                &:nth-of-type(3) {
                    @media only screen and (max-width: 769px) {
                        word-break: break-word;
                    }
                }

                &.txt_thanks_transaction {
                    text-transform: none;
                    padding-top: 15px;
                }

                label:after {
                    content: ": ";
                    margin-left: 2px;
                }
            }
        }

        .thanksLeftToRight {
            padding-left: 8px;
            padding-top: 18px;

            p.thanksTrust {
                text-transform: uppercase;
                font-weight: 500;
                font-size: 50px;
                line-height: 60px;
                max-width: 480px;

                @media only screen and (max-width: 769px) {
                    text-align: center;
                }

                strong {
                    font-weight: 500;
                }
            }

            h1 {
                text-transform: uppercase;
                font-weight: 500;
                font-size: 16px;
                padding-top: 10px;
                letter-spacing: 0.01px;
                margin-bottom: 0.75rem;

                @media only screen and (max-width: 769px) {
                    justify-content: center;
                }

                &:before {
                    display: none;
                }
            }

            p.seeOrderEvo {
                font-weight: 400;
                font-size: 13px;
                margin-bottom: 3px;

                @media only screen and (max-width: 769px) {
                    text-align: center;
                }

                a {
                    text-decoration: none;
                    color: #000;
                }
            }

            p.emailConfirm {
                font-weight: 400;
                font-size: 13px;
                margin-bottom: 25px;

                @media only screen and (max-width: 769px) {
                    text-align: center;
                }

                @media screen and (max-width: 1024px) {
                    margin-bottom: 28px;
                }

                @media screen and (max-width: 1024px) and (orientation: landscape) {
                    margin-bottom: 40px;
                }
            }

            p.createPwdTxt {
                font-weight: 400;
                font-size: 13px;
                padding-top: 15px;
                padding-bottom: 10px;
            }

            button.bankThanksBtn {
                margin-top: 1.875rem;
                max-width: 231px;
                width: 100%;
                height: 48px;
            }

            a.bankThanksBtn {
                padding: 16px 32px 14px;
                gap: 10px;

                @media only screen and (max-width: 647px) {
                    padding: 0;
                }
            }

            #clientForm {
                .w-form-line {
                    .w-input.w-nonempty {
                        max-width: 435px;

                        .w-input-element {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.step_1 {
    .breadcrumb.current {
        display: none;
    }

    .breadcrumb:nth-child(2n) {
        a {
            color: $tertiary;
        }

        &::after {
            display: none;
        }
    }
}

.cart_box {
    .form_submit {
        display: none;
        margin-top: 25px;
    }
}

.step_2_0 .wrapper_panier .wrapper_mes_adresses {
    padding-top: 14px;

    .edit_adresse {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .left_side {
            width: 65%;

            #adresseForm {
                .delivery {
                    .w-form-line {
                        &.w-has-tel {
                            align-items: flex-start;

                            .w-input-container {
                                +.w-input-container {
                                    position: relative;

                                    label {
                                        margin-bottom: 4px;

                                        +span {
                                            font-weight: 400;
                                            font-size: 11px;
                                            color: #868686;
                                        }
                                    }

                                    .w-tel-input {
                                        #telephone {
                                            padding-left: 4.5rem;
                                        }

                                        .w-input-label {
                                            left: 4.5rem;
                                        }
                                    }
                                }

                                .errormsgadd {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                .choix_type_adresse {
                    padding-top: 17px;
                }

                .w-form-line {
                    margin-bottom: 23px;

                    &.w-has-tel {
                        .w-input-container {
                            >span {
                                font-weight: 400;
                                font-size: 12px;
                                color: #868686;
                            }
                        }

                        .errormsgadd {
                            display: none;
                        }
                    }
                }

                .elem_global,
                .accountCreate {
                    width: 92%;
                }
            }
        }

        .right_side {
            padding-top: 20px;

            .total_produit {
                padding-top: 25px;
            }
        }
    }
}

// Customer account
body {
    &.customer {
        #adresseForm {
            .w-form-line.w-has-tel {
                .w-input-container {
                    .w-input {
                        margin-bottom: 6px;

                        +span {
                            color: #868686;
                            font-size: 12px;
                            font-weight: 400;
                        }
                    }

                    .errormsgadd {
                        display: none;
                    }
                }
            }
        }
    }
}

// Payment tunnel
body {
    &.cart {
        &.step_2 {
            #adresseForm {
                .w-form-line {
                    &.w-has-tel {
                        align-items: flex-start;

                        .w-input-container {
                            +.w-input-container {
                                .w-input.w-tel-input {
                                    +span {
                                        margin-top: 6px;
                                        font-size: 11px;
                                        font-weight: 400;
                                        color: #868686;
                                    }
                                }
                            }

                            .errormsgadd {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}


.body_login.in_tunnel .tunnel_track_pusher {
    padding-top: 0;

    nav {
        display: none !important;
    }
}

.wrapper_cart_product_price,
#show_top_cart .wrapper_price,
#show_top_wish .wrapper_price {
    font-weight: 500;
    font-size: 16px;
    padding-top: 8px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin: 0;

    .pricetag {
        margin-right: 8px;
        order: 1;

        &+.pricetag {
            font-size: 14px;
            color: $tertiary;
            position: relative;
            display: inline-block;
            width: auto;
            line-height: 1;
            font-size: 14px;
            padding-top: 2px;
            order: 2;

            &:before {
                content: "";
                position: absolute;
                height: 1px;
                width: 100%;
                display: block;
                background-color: $tertiary;
                top: 9px;
            }
        }
    }
}

// Ecart entre prix du produit et la taille dans
// panier suite au hover dessus
.cart_detail_box {
    .cart_product {
        .wrap_top_info {
            .wrapper_price {
                margin-bottom: 8px !important;
            }
        }
    }
}

.main_content {
    .wrapper_panier {
        .left_side {
            .cart_main_table {
                &:nth-child(4) {
                    padding-bottom: 16px;
                }
            }
        }
    }

    .url_panier_error {
        max-width: 500px;
        min-height: 100px;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &.lightbox {
            height: fit-content;
        }

        .error_list {
        
            li {
                line-height: 1;
                font-size: 16px;
                padding-top: 0.25rem;
                padding-bottom: 0.25rem;
            }
        }
        #btn_cmd_valid {
            padding: 0 15px;
            position: relative;
            top: 1px;
            width: 100%;
            max-width: 190px;
            margin: 15px auto 0;
            line-height: 42px;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

#adresseForm {
    .w-form-line {
        &.w-submit {
            height: 46px;
        }
    }
}

//recettage x107
body.cart.step_2.in_tunnel {
    .wrapper_panier .right_side .main_table .used_cp {
        margin-right: 0;
    }

    #cart_total .promo {
        display: inline-flex;
    }

    /* HOT FIX SW-11774; masquer DHL / configuration pas faite au niveau back */
    .choix_delivery_postal.bloc_livraison.transporteur_DHL {
        display: none;
    }

    /* Fix for https://wshopcloudcommerce.atlassian.net/browse/WP-26435 */
    .choix_delivery_relais.transporteur_Chronopost {
        display: none !important;
    }
}

//checkout
body.checkout.in_tunnel {
    .wrapper_panier .right_side .main_table .used_cp {
        margin-top: 8px;
        margin-right: 0;
    }

    #cart_total .promo {
        display: inline-flex;
    }
}

//recettage x107 END
// Self service payment terminal and tablet
body.mpos {
    .main_wrapper.special .thanksWrapper {
        .thanksRecap {
            width: 100%;
            flex-direction: row-reverse;
            justify-content: space-between;

            @media screen and (max-width: 1159px) {
                display: flex;
                flex-direction: column-reverse !important;
                align-items: center !important;
            }

            .bank_thanks_pay_by_link {
                width: 100%;
                margin-top: 40px;
                display: flex;
                text-align: center;
                justify-content: center;
                align-items: center;
                margin-bottom: 50px;

                &-title {
                    font-weight: 500;
                    font-size: 50px;
                    line-height: 60px;
                }

                @media screen and (max-width: 1159px) {
                    order: 3;
                }
            }

            .thanksLeftHostedByLink {
                width: 47%;

                .seeOrderEvo {
                    font-family: $primaryFont;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 18px;
                    color: $black;
                    margin-bottom: 33px;

                    strong {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 17px;
                    }
                }

                a.bankThanksBtn {
                    display: flex;
                    max-width: 388px;
                    height: 48px;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                }

                @media screen and (max-width: 1159px) {
                    order: 1;
                }
            }

            .thanksLeft {
                width: 47%;

                @media screen and (max-width: 1159px) {
                    order: 2;
                    padding-bottom: 40px;
                }
            }

            .thanksRight {
                display: none;
            }
        }
    }

    .wrapper_tunnel {

        #outsite_ue_notice {
            text-align: center;
            margin: 2rem;
        }

        #main_cart_wrapper.wrapper_basket_content {
            .payment_wrapper.wrapper_panier {
                display: flex;
                width: 100%;
                max-width: 1440px;
                margin: -1.1rem auto 0;

                #bank_div.bank_left_side {
                    max-width: 936px;
                    width: 63%;
                }

                #tunnel_right_col.bank_bloc_right {
                    width: 40%;
                    max-width: 450px;
                }
            }
        }
    }
}

// Payment by Link
body.checkout.in_tunnel {
    &.en #bank_div .paypal .bank_subtitle strong {
        display: none;
    }

    .sofort #inputSOFORT {
        display: inline-block;
        width: 3.3rem;
        height: 100%;
        opacity: 0;

        &.loadEnd {
            display: inline-block;
            opacity: 1;
        }
    }

    .paypal #inputPAYPAL {
        width: 60px;
        height: 100%;
        opacity: 0;
        display: none;

        &.loadEnd {
            display: inline-block;
            opacity: 1;
        }
    }

    .bank_left_side:has(.bloc_paid_by_link_hipay) {
        .wrapper_bloc_banque.bloc_paid_by_link_hipay {
            position: relative;
            display: block;
            border: 1px solid #b1b1b1;
            margin-bottom: 64px;
            padding: 44px 23px;
            box-sizing: border-box;
            width: 93%;

            .radio {
                display: block;
                border: 1px solid #ccc;
                border-radius: 50%;
                background-color: $white;
                position: absolute;
                left: -9px;
                top: 50%;
                width: 14px;
                height: 14px;
                transform: translate(0, -50%);
                transition: all 0.2s ease-out;

                &:hover {
                    border: 1px solid grey;
                }

                &:after {
                    content: "";
                    display: block;
                    border-radius: 50%;
                    width: 13px;
                    height: 13px;
                    background-color: transparent;
                    box-shadow: inset 0 0 0 2px $white;
                    transition: all 0.2s ease-out;
                }
            }

            &.actif {
                border: 1px solid black;
                -webkit-transition: border 0.2s ease-out;
                -moz-transition: border 0.2s ease-out;
                -o-transition: border 0.2s ease-out;
                transition: border 0.2s ease-out;

                .radio {
                    &:after {
                        background-color: #000;
                        border: 1px solid #000;
                    }
                }
            }

            .title_wrapper {
                h2.bank_title {
                    position: absolute;
                    top: -11px;
                    left: 50%;
                    transform: translateX(-50%);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: -webkit-fit-content;
                    width: -moz-fit-content;
                    width: fit-content;
                    height: 19px;
                    text-transform: uppercase;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    padding: 0 2em;
                    background-color: $white;
                }

                p.bank_subtitle_unfold {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    margin-bottom: 15px;
                }

                form {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: 100%;

                    .email-form-line {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: calc(100% - 24px);
                        max-width: 697px;
                        margin-right: 24px;

                        .w-input-container {
                            border-radius: 0;
                            border-color: grey;

                            .w-input.w-email-input {
                                input.w-input-element {
                                    outline: none;
                                    padding-top: 14px;

                                    &:focus {
                                        outline: 0 none;
                                        box-shadow: none;
                                    }
                                }

                                p.w-input-label {
                                    font-size: 13px;
                                    transform: translateY(-18px);
                                    padding-left: 0.1rem;
                                }
                            }
                        }
                    }

                    input.bank_btn_paid_by_link_hipay {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $primaryColor;
                        width: 100%;
                        max-width: 200px;
                        height: 48px;
                        font-family: $primaryFont;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 16px;
                        color: $white;
                        text-align: center;
                        padding: 0;
                        text-transform: uppercase;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

body.checkout.bankThanks.in_tunnel.boutique {
    .thanksWrapper .thanksRecap .thanksRight .thanksLeftToRight {
        p.emailConfirm {
            margin-bottom: 50px;
        }
    }
}

// Payment by Link END

// mpos cart total btn in tunnel
body.cart.step_2_0.in_tunnel {
    .wrapper_panier .wrapper_mes_adresses .right_side .formPanier_container .form_submit.full {
        width: 100%;
    }
}

/* Bloc ce n'est pas vous */
.right_side {
    .deconnection_tunnel {
        padding: 0 1rem;
        background: $white;
        border: 1px solid $tertiary;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: $primaryFont;
        font-weight: 400;
        font-size: 13px;
        text-align: center;
        margin: 5px 0 25px;
        color: $black;

        p {
            text-align: left;
            padding: 8px 0;
            line-height: 14px;
        }

        #btn-disconnect {
            padding: 8px 0;
            margin-left: 0.25rem;
            text-align: right;
            cursor: pointer;
            background-color: transparent;
            border: none;

            span {
                color: $black;
                white-space: nowrap;
                font-size: 13px;
                font-family: $primaryFont;
                font-weight: 400;
            }
        }
    }
}

body.step_2 {
    .right_side {
        .deconnection_tunnel {
            margin-top: 9px;
        }
    }
}

body.checkout {
    .right_side {
        .deconnection_tunnel {
            margin-top: 10px;
        }
    }
}

/***ALMA WIDGET***/
#alma-widget {
    margin-top: 24px;

    .alma-payment-plans-container {
        width: 100%;

        svg {
            display: none;
        }

        .alma-payment-plans-eligibility-line {
            align-items: center;

            &::before {
                content: "";
                display: block;
                width: 50px;
                height: 24px;
                background: url("../img/alma.svg");
                background-size: contain;
                margin-right: 18px;
            }
        }
    }
}

.cart.step_2_0 {
    .elem_global.bill {
        .w-form-line.w-has-tel {
            .w-input.w-tel-input .w-input-label {
                left: 4.5rem;
            }

            .w-input-element:not([type=radio]):not([type=checkbox]) {
                padding-left: 4.5rem;
            }
        }
    }
}